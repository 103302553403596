import { MQ_BREAKPOINTS, breakpoints } from '../../../../constants/breakpoints';
import styled from 'styled-components';

export const StyledFiltersWrapper = styled.div`
    margin-top: 10px;
    margin-bottom: 5px;
    .select-wrapper {
        flex-direction: row;
        align-items: center;
        text-transform: uppercase;
        label {
            font-size: 14px;
            line-height: 20px;
            padding: 0;
            margin-right: 6px;
        }
        .select__control {
            background: var(--white);
            color: var(--gray);
        }
        > div {
            display: flex;
            align-items: center;
        }
    }
`;
export const StyledTopFilters = styled.div`
    display: flex;
    padding: 10px 20px;
    background: var(--purple);
    color: var(--white);
    justify-content: space-between;
    @media screen and (min-width: ${MQ_BREAKPOINTS.tablet}) and (max-width: ${breakpoints.laptop - 1}px) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.desktop}) and (max-width: ${MQ_BREAKPOINTS.desktopL}) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.ultraWide}) {
        height: 65px;
    }

    .select-wrapper {
        margin-right: 20px;
        width: fit-content;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            margin-right: 0;
        }
    }
`;
export const StyledFiltersInner = styled.div`
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    align-items: center;
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        align-items: left;
        gap: 1rem;
    }

    .select-container {
        > div {
            width: 120px;
        }
        div {
            font-size: 14px;
        }

        div:last-child {
            border-bottom: none;
        }
    }
`;
export const StyledStatusFilter = styled.div`
    display: flex;
    align-items: center;
    text-transform: uppercase;
    &.first-active {
        p {
            &:nth-of-type(1) {
                border-bottom: 1px solid var(--border-color);
            }
        }
    }
    label {
        margin-right: 6px;
        font-size: 14px;
    }
    p {
        font-size: 14px;
        border-bottom: 1px solid transparent;
        padding: 11px 8px;
        white-space: nowrap;
        &.active {
            border-bottom: 1px solid var(--border-color);
        }
    }
`;
export const StyledDisplayIcons = styled.div`
    display: flex;
    align-items: center;
    svg {
        margin-left: 15px;
        font-size: 22px;
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        justify-content: flex-end;
        align-items: flex-end;
    }
`;
export const StyledBottomFilters = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .search-wrapper {
        input {
            width: 100%;
        }
        width: 150px;
    }
`;
export const StyledShowSelect = styled.div`
    display: flex;
    align-items: center;
    .select-wrapper {
        width: auto;
        label {
            color: var(--gray);
        }
    }
    p {
        text-transform: uppercase;
        font-size: 14px;
        line-height: 20px;
        margin-left: 6px;
        color: var(--gray);
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            display: none;
        }
    }
`;
