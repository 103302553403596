import { create } from 'zustand';
import { ObjectKeys } from '../types/objectKeys';

interface InvoicesState {
    invoiceData: ObjectKeys;
    eInvoiceData: ObjectKeys;
    advanceInvoiceData: ObjectKeys;
    eAdvanceInvoiceData: ObjectKeys;
    desiredCardView: string;
}

const useInvoicesStore = create<InvoicesState>(() => ({
    invoiceData: {},
    eInvoiceData: {},
    advanceInvoiceData: {},
    eAdvanceInvoiceData: {},
    desiredCardView: 'list',
}));

export default useInvoicesStore;
