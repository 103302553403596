import { FunctionComponent, useState, useRef } from 'react';
import { transformDateFormat } from '../../functions/formatDateTime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faCheckSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import colors from '../../global/colors';
import { formatNumber } from '../../functions/format';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import useProfileStore from '../../store/profile';
import { ObjectKeys } from '../../types/objectKeys';
import Dropdown from '../dropdown';
import useDropdown from './useDropdown';
import getStatus from './getStatus';
import getNumberLabel from './getNumberLabel';
import InvoiceActionsWithoutDropdown from './invoiceActionsWithoutDropdown';
import useOutsideClick from '../../hooks/useOutsideClick';
import PaymentDropdown from './paymentDropdown';
import PreviewInvoiceModalPlain from '../../pages/pausal/common/previewInvoiceModalPlain';
import PreviewInvoiceModalArtistic from '../../pages/pausal/common/previewInvoiceModalArtistic';
import PreviewInvoiceModalBusiness from '../../pages/pausal/common/previewInvoiceModalBusiness';
import PreviewInvoiceModalInvoicePro from '../../pages/pausal/common/previewInvoiceModalInvoicePro';
import PreviewInvoiceModalPlain2 from '../../pages/pausal/common/previewInvoiceModalPlain2';
import PreviewInvoiceModalPlainSanta from '../..//pages/pausal/common/previewInvoiceModalPlainSanta';
import PreviewInvoiceModalPlainPenguin from '../..//pages/pausal/common/previewInvoiceModalPlainPenguin';
import useUserStore from '../../store/user';
import useAgencyStore from '../../store/agency';
import Modal from '../modal';
import { generateQrCode } from '../../communication/qr';
import { useNavigate } from 'react-router-dom';
import useResponsive from '../../hooks/responsive/useResponsive';
import { StyledInvoiceCard, CardContent, InfoWrapper, ActionWrapper, Action, DropdownContainer } from './style';

export enum InvoiceCardComponentType {
    Invoice = 'invoice',
    EInvoice = 'eInvoice',
    Proforma = 'proforma',
    AdvanceInvoice = 'advanceInvoice',
    EAdvanceInvoice = 'eAdvanceInvoice',
}
export interface PausalInvoiceCardProps {
    type: string;
    className?: string;
    handleChange?: Function;
    handleDownload?: Function;
    handleCopy?: Function;
    handleCancel?: Function;
    handleDelete?: Function;
    handleSend?: Function;
    handleReminder?: Function;
    handlePayment?: Function;
    handleEInvoice?: Function;
    handleInvoice?: Function;
    handleAdvance?: Function;
    handleClick: Function;
    handleRefresh?: Function;
    cardDisplay: string;
    cardData: ObjectKeys;
    eInvoice?: boolean;
    statusFilter?: ObjectKeys;
    handleUpdatePayment?: Function;
    handleDeletePayment?: Function;
    // handleRemovePayments?: Function;
    dropdownDirection?: 'up' | 'down';
    paymentInfo?: Function;
    handle8millionLimit?: Function;
}

const InvoiceCard: FunctionComponent<PausalInvoiceCardProps> = ({
    type,
    className,
    handleChange,
    handleCancel,
    handleCopy,
    handleDelete,
    handleDownload,
    handleEInvoice,
    handlePayment,
    handleSend,
    handleReminder,
    handleClick,
    handleInvoice,
    handleAdvance,
    cardDisplay,
    cardData,
    statusFilter,
    eInvoice,
    handleRefresh,
    handleUpdatePayment,
    handleDeletePayment,
    // handleRemovePayments,
    dropdownDirection = 'down',
    paymentInfo,
    handle8millionLimit,
}) => {
    const navigate = useNavigate();
    const role = localStorage.getItem('role');
    const { currentLang, currentLangName } = useLanguageStore();
    const t = useTranslations(currentLang);

    const { profile } = useProfileStore();
    const { userInfo } = useUserStore();
    const {
        isMobileS,
        isMobileM,
        isMobileL,
        isTablet,
        isLaptop,
        isLaptopL,
        isDesktop,
        isDesktopL,
        isUltraWide,
        isLaptopAndDown,
    } = useResponsive();

    const preferredTemplate = userInfo?.preferred_template?.data.slug;

    const isAccountActive = profile?.active === 1 ? true : false;
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [showPaymentDropdown, setShowPaymentDropdown] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const { agency } = useAgencyStore();
    const [qrCode, setQrCode] = useState('');
    const isAdvance = cardData?.is_prepaid ? true : false;

    const ref = useRef<HTMLDivElement>(null);

    const { handleClickOutside } = useOutsideClick(ref, setDropdownVisible);

    //QR code
    const handleGenerateQRCode = (
        agencyData: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
    ): void => {
        try {
            generateQrCode(
                agencyData,
                clientData,
                amount,
                invoiceNumber,
                bankAccount,
                currency,
                convert,
                `00${invoiceNumber?.replace('/', '-')}`,
                '221',
            )
                .then((response: ObjectKeys | any) => {
                    if (response.i) setQrCode(response.i);
                })
                .catch((error: any) => {
                    setQrCode('');
                    if (error) {
                        console.error(error);
                    }
                });
        } catch (e: any) {
            setQrCode('');
        }
    };

    const handleDownloadInvoice = (): void => {
        if (handleDownload) {
            handleDownload();
        }
    };

    const handleRefreshInvoice = (): void => {
        if (handleRefresh) {
            handleRefresh();
        }
    };

    const handleCopyInvoice = (): void => {
        if (handleCopy) {
            handleCopy();
        }
    };

    const handleDeleteInvoice = (): void => {
        if (handleDelete) {
            handleDelete();
        }
    };

    // const handleRemovePaymentsInvoice = (): void => {
    //     if (handleRemovePayments) {
    //         handleRemovePayments();
    //     }
    // };

    const handlePaymentInvoice = (): void => {
        if (handlePayment) {
            handlePayment();
        }
    };

    const handleSendInvoice = (): void => {
        if (handleSend) {
            handleSend();
        }
    };

    const handlePaymentInfo = (): void => {
        if (paymentInfo) {
            setShowPaymentDropdown(!showPaymentDropdown);
            paymentInfo();
        }
    };

    const paidDropdown = useDropdown(
        cardData,
        type,
        'paid',
        undefined,
        undefined,
        handleCopyInvoice,
        handleDeleteInvoice,
        handleSendInvoice,
        undefined,
        undefined,
        handleDownloadInvoice,
        handleEInvoice,
        undefined,
        undefined,
        eInvoice ? handleRefresh : undefined,
        eInvoice,
        undefined,
        handlePaymentInfo,
    );

    const sentDropdown = useDropdown(
        cardData,
        type,
        'sent',
        handleChange,
        handleCancel,
        handleCopyInvoice,
        handleDeleteInvoice,
        handleSendInvoice,
        handleReminder,
        handlePaymentInvoice,
        handleDownloadInvoice,
        handleEInvoice,
        undefined,
        undefined,
        eInvoice ? handleRefreshInvoice : undefined,
        eInvoice,
        undefined,
        undefined,
    );

    const draftDropdown = useDropdown(
        cardData,
        type,
        'draft',
        handleChange,
        undefined,
        handleCopyInvoice,
        handleDeleteInvoice,
        handleSendInvoice,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        eInvoice ? handleRefreshInvoice : undefined,
        eInvoice,
        undefined,
        undefined,
    );

    const partiallyDropdown = useDropdown(
        cardData,
        type,
        'partially',
        undefined,
        undefined,
        handleCopyInvoice,
        handleDeleteInvoice,
        handleSendInvoice,
        undefined,
        handlePaymentInvoice,
        handleDownloadInvoice,
        handleEInvoice,
        undefined,
        undefined,
        eInvoice ? handleRefreshInvoice : undefined,
        eInvoice,
        // handleRemovePaymentsInvoice,
        undefined,
        handlePaymentInfo,
    );

    const cancelledDropdown = useDropdown(
        cardData,
        type,
        'cancelled',
        handleChange,
        handleCancel,
        handleCopyInvoice,
        handleDeleteInvoice,
        handleSendInvoice,
        undefined,
        handlePaymentInvoice,
        handleDownloadInvoice,
        handleEInvoice,
        undefined,
        undefined,
        eInvoice ? handleRefreshInvoice : undefined,
        eInvoice,
        undefined,
        undefined,
    );

    const proformaDropdown = useDropdown(
        cardData,
        type,
        'proforma',
        handleChange,
        undefined,
        handleCopyInvoice,
        handleDeleteInvoice,
        handleSendInvoice,
        undefined,
        undefined,
        handleDownloadInvoice,
        undefined,
        handleInvoice,
        handleAdvance,
        eInvoice ? handleRefreshInvoice : undefined,
        eInvoice,
        undefined,
        undefined,
    );

    const closedDropdown = useDropdown(
        cardData,
        type,
        'closed',
        undefined,
        undefined,
        handleCopyInvoice,
        handleDeleteInvoice,
        handleSendInvoice,
        undefined,
        undefined,
        handleDownloadInvoice,
        handleEInvoice,
        undefined,
        undefined,
        eInvoice ? handleRefreshInvoice : undefined,
        eInvoice,
        undefined,
        undefined,
    );

    const adminDropdown = useDropdown(
        cardData,
        type,
        'admin',
        handleChange,
        undefined,
        undefined,
        handleDeleteInvoice,
        undefined,
        undefined,
        undefined,
        handleDownloadInvoice,
        undefined,
        undefined,
        undefined,
        eInvoice ? handleRefreshInvoice : undefined,
        eInvoice,
        // handleRemovePaymentsInvoice,
        undefined,
        handlePaymentInfo,
    );

    const shortenName = (name: string): string => {
        let maxLength = 20; // za svaki slucaj
        switch (true) {
            case isMobileS:
                maxLength = 25;
                break;
            case isMobileM:
                maxLength = 35;
                break;
            case isMobileL:
                maxLength = 45;
                break;
            case isTablet:
            case isLaptop:
                maxLength = 38;
                break;
            case isLaptopL:
                maxLength = 40;
                break;
            case isDesktop:
                maxLength = 54;
                break;
            case isDesktopL:
                maxLength = 90;
                break;
            case isUltraWide:
                maxLength = 120;
                break;
            default:
                maxLength = 25;
        }
        return name && name.length > maxLength ? name.slice(0, maxLength) + '...' : name;
    };

    const handleCardClick = (): void => {
        if (role !== 'admin') {
            if (!isAccountActive) {
                if (cardData.status === 'open') return;
                handleDownload && handleDownload(cardData.id);
            } else if (cardData.status === 'open' && cardData.object !== 'Quotation') {
                if (cardData.is_prepaid) {
                    navigate(`/advance-invoices/edit/${cardData.id}`);
                } else {
                    navigate(`/invoices/edit/${cardData.id}`);
                }
            } else {
                setPreviewModal(true);
                if (cardData.type !== 'foreign' && !cardData.is_prepaid) {
                    const invoiceNumber = cardData.invoice_number
                        ? `${cardData.prefix ? cardData.prefix : ''}${cardData.invoice_number}`
                        : cardData.quotation_number
                        ? cardData.quotation_number
                        : '';
                    handleGenerateQRCode(
                        agency.full_name,
                        cardData?.client?.data?.company_name,
                        cardData?.total_for_payment_in_rsd
                            ? cardData?.total_for_payment_in_rsd
                            : cardData?.value_in_rsd,
                        invoiceNumber,
                        cardData?.bank_account,
                        cardData?.currency,
                        true,
                    );
                }
            }
        }
    };

    return (
        <StyledInvoiceCard key={cardData.id} className={`card ${className ?? ''} card-display-${cardDisplay}`.trim()}>
            {previewModal && (
                <Modal
                    width="700px"
                    className="preview-modal"
                    modalVisible={true}
                    hasCloseButton={true}
                    closeModal={() => {
                        setPreviewModal(false);
                        setQrCode('');
                    }}
                >
                    {/* //TODO: setQrCode(''); ON CLOSE */}

                    {preferredTemplate === 'plain' && (
                        <PreviewInvoiceModalPlain
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {/* {preferredTemplate === 'Artistic' && (
                        <PreviewInvoiceModalArtistic
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )} */}
                    {preferredTemplate === 'business' && (
                        <PreviewInvoiceModalBusiness
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'invoicePro' && (
                        <PreviewInvoiceModalInvoicePro
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'plain_2' && (
                        <PreviewInvoiceModalPlain2
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'santa' && (
                        <PreviewInvoiceModalPlainSanta
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )}
                    {preferredTemplate === 'penguin' && (
                        <PreviewInvoiceModalPlainPenguin
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )}
                </Modal>
            )}
            <CardContent className="card-content cursor-pointer">
                <InfoWrapper
                    onClick={() => {
                        handleCardClick();
                    }}
                    className={`info-wrapper ${cardDisplay === 'grid' && 'grid-view'}`.trim()}
                >
                    <div className="name-col">
                        <div className="label">{t('pages.invoiceCard.infoWrapper.name').text}</div>
                        <div className="name">
                            {cardData?.status === 'paid,partially' ||
                            cardData?.status === 'paid' ||
                            cardData?.status === 'partialy' ? (
                                <FontAwesomeIcon
                                    icon={cardData.status === 'paid' ? faCheckSquare : faMinusSquare}
                                    style={{
                                        color: cardData.status === 'paid' ? colors.green : colors.danger,
                                        marginRight: '8px',
                                        fontSize: '18px',
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                            {cardData?.client?.data?.company_name}
                        </div>
                    </div>
                    {cardData.object !== 'Quotation' ? (
                        <div className="number-col">
                            <div className="label">{t('pages.invoiceCard.infoWrapper.prefix').text}</div>
                            <div className="prefix">{cardData?.prefix}</div>
                        </div>
                    ) : null}
                    <div className="number-col">
                        <div className="label">{getNumberLabel(currentLangName, type, t)}</div>
                        <div className="number">
                            {(type === 'proforma' && cardData?.quotation_number) ||
                                (cardData?.invoice_number
                                    ? cardData?.invoice_number
                                    : getStatus(cardData?.status?.toLowerCase(), t))}
                        </div>
                    </div>
                    <div className="date-col">
                        <div className="label">{t('pages.invoiceCard.infoWrapper.date').text}</div>
                        <div className="date">
                            {transformDateFormat(
                                type === 'proforma' ? cardData?.quotation_date : cardData?.invoice_date,
                            )}
                        </div>
                    </div>
                    <div className="amount-col">
                        <div className="label">{t('pages.invoiceCard.infoWrapper.amount').text}</div>
                        <div className="amount">
                            {formatNumber(cardData?.value, 2, 3, '.', ',') + ' ' + cardData?.currency}
                        </div>
                    </div>
                    <div className="statusAndAction">
                        {type !== 'proforma' && (
                            <>
                                <div className="status-test">
                                    <div className="status-col">
                                        <div className="label">{t('pages.invoiceCard.infoWrapper.status').text}</div>
                                        <div className={`status ${cardData?.status}`}>
                                            <span>{getStatus(cardData?.status?.toLowerCase(), t)}</span>
                                        </div>
                                    </div>
                                    <>
                                        {cardData.is_einvoice === 1 ? (
                                            <div className="status-col">
                                                {isAdvance ? (
                                                    <div className="label">
                                                        {t('pages.eInvoices.status.title_eAdvance').text}
                                                    </div>
                                                ) : (
                                                    <div className="label">
                                                        {t('pages.eInvoices.status.title').text}
                                                    </div>
                                                )}

                                                <div
                                                    className={`estatus ${cardData?.eInvoice?.data?.status?.toLowerCase()}`}
                                                >
                                                    <span>
                                                        {getStatus(
                                                            cardData?.eInvoice?.data?.status?.toLowerCase(),
                                                            t,
                                                            cardData?.eInvoice,
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        ) : null}
                                    </>
                                </div>
                            </>
                        )}
                        {isAccountActive ? (
                            <ActionWrapper className="mainActionWrapper" onClick={(e) => e.stopPropagation()}>
                                <div className="label"></div>
                                <div className="action">
                                    {isLaptopAndDown ? (
                                        <Action>
                                            <FontAwesomeIcon
                                                icon={faEllipsisVertical}
                                                style={{ color: colors.gray }}
                                                className="cursor-pointer dots"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleClick(cardData?.id);
                                                    setDropdownVisible(!dropdownVisible);
                                                }}
                                            />
                                            {dropdownVisible && (
                                                <DropdownContainer
                                                    ref={ref}
                                                    className={dropdownDirection}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleClickOutside;
                                                        setDropdownVisible(false);
                                                    }}
                                                >
                                                    <Dropdown
                                                        arrowRight="4px"
                                                        caretDown={dropdownDirection === 'up'}
                                                        itemList={
                                                            role !== 'admin'
                                                                ? cardData?.status === 'paid'
                                                                    ? paidDropdown
                                                                    : cardData?.status === 'sent'
                                                                    ? sentDropdown
                                                                    : cardData?.status === 'open' && type !== 'proforma'
                                                                    ? draftDropdown
                                                                    : cardData?.status === 'partially'
                                                                    ? partiallyDropdown
                                                                    : cardData?.status === 'cancelled'
                                                                    ? cancelledDropdown
                                                                    : cardData?.status === 'open' && type === 'proforma'
                                                                    ? proformaDropdown
                                                                    : cardData?.status === 'closed'
                                                                    ? closedDropdown
                                                                    : []
                                                                : adminDropdown
                                                        }
                                                    />
                                                </DropdownContainer>
                                            )}
                                        </Action>
                                    ) : (
                                        <InvoiceActionsWithoutDropdown
                                            cardData={cardData}
                                            handleDownload={handleDownload}
                                            handleInvoice={handleInvoice}
                                            handleAdvance={handleAdvance}
                                            handleCopy={handleCopy}
                                            handlePayment={handlePayment}
                                            handleCancel={handleCancel}
                                            handleChange={handleChange}
                                            handleDelete={handleDelete}
                                            handleSend={handleSend}
                                            handleReminder={handleReminder}
                                            handleEInvoice={handleEInvoice}
                                            handleRefresh={handleRefresh}
                                            type={type}
                                            t={t}
                                            eInvoice={eInvoice}
                                            paymentInfo={handlePaymentInfo}
                                        />
                                    )}
                                </div>
                            </ActionWrapper>
                        ) : (
                            <></>
                        )}
                        {role === 'admin' && !isAccountActive ? (
                            <ActionWrapper onClick={(e) => e.stopPropagation()}>
                                <div className="label"></div>
                                <div className="action">
                                    {isLaptopAndDown ? (
                                        <Action>
                                            <FontAwesomeIcon
                                                icon={faEllipsisVertical}
                                                style={{ color: colors.gray }}
                                                className="cursor-pointer dots"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleClick(cardData?.id);
                                                    setDropdownVisible(!dropdownVisible);
                                                }}
                                            />
                                            {dropdownVisible && (
                                                <DropdownContainer
                                                    ref={ref}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleClickOutside;
                                                    }}
                                                    className={dropdownDirection}
                                                >
                                                    <Dropdown
                                                        arrowRight="4px"
                                                        caretDown={dropdownDirection === 'up'}
                                                        itemList={
                                                            role !== 'admin'
                                                                ? cardData?.status === 'paid'
                                                                    ? paidDropdown
                                                                    : cardData?.status === 'sent'
                                                                    ? sentDropdown
                                                                    : cardData?.status === 'open' && type !== 'proforma'
                                                                    ? draftDropdown
                                                                    : cardData?.status === 'partially'
                                                                    ? partiallyDropdown
                                                                    : cardData?.status === 'cancelled'
                                                                    ? cancelledDropdown
                                                                    : cardData?.status === 'open' && type === 'proforma'
                                                                    ? proformaDropdown
                                                                    : cardData?.status === 'closed'
                                                                    ? closedDropdown
                                                                    : []
                                                                : adminDropdown
                                                        }
                                                    />
                                                </DropdownContainer>
                                            )}
                                        </Action>
                                    ) : (
                                        <InvoiceActionsWithoutDropdown
                                            cardData={cardData}
                                            handleDownload={handleDownload}
                                            handleInvoice={handleInvoice}
                                            handleAdvance={handleAdvance}
                                            handleCopy={handleCopy}
                                            handlePayment={handlePayment}
                                            handleCancel={handleCancel}
                                            handleChange={handleChange}
                                            handleDelete={handleDelete}
                                            handleSend={handleSend}
                                            handleEInvoice={handleEInvoice}
                                            type={type}
                                            t={t}
                                            eInvoice={eInvoice}
                                        />
                                    )}
                                </div>
                            </ActionWrapper>
                        ) : (
                            <></>
                        )}
                    </div>
                </InfoWrapper>
            </CardContent>
            {showPaymentDropdown &&
            !cardData?.cancelled &&
            cardData?.payments?.data &&
            cardData?.payments?.data.length > 0 ? (
                <PaymentDropdown
                    cardData={cardData}
                    t={t}
                    handlePayment={handlePayment}
                    type={type}
                    handleUpdatePayment={handleUpdatePayment}
                    handleDeletePayment={handleDeletePayment}
                    edit8millionLimit={handle8millionLimit}
                    cardDisplay={cardDisplay}
                />
            ) : null}
        </StyledInvoiceCard>
    );
};

export default InvoiceCard;
