import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ObjectKeys } from '../../../../types/objectKeys';
import Pagination from '../../../../components/pagination';
import communication from '../../../../communication';
import Button, { ButtonVariant } from '../../../../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import FiltersStatistics from './filters';
import Loader from '../../../../components/loader';
import NoItems from '../../../../components/noItems';
import exportToExcel from '../../../../functions/downloadXlsx';

interface Props {
    t: Function;
}

const Users: FunctionComponent<Props> = ({ t }) => {
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<ObjectKeys>();
    const [testUsers, setTestUsers] = useState<ObjectKeys>();
    const [allUsers, setAllUsers] = useState<ObjectKeys>();
    const [usersNo, setUsersNo] = useState(0);
    const [years, setYears] = useState<number[]>([]);
    const [banks, setBanks] = useState<ObjectKeys[]>([]);
    const currentYear = new Date().getFullYear();

    const [shouldExport, setShouldExport] = useState(false);

    const [paramsFilter, setParamsFilter] = useState({
        page: 1,
        limit: '10',
        start_date: `${currentYear}-01-01 00:00:00`,
        end_date: `${currentYear}-01-31 23:59:59`,
        bank_id: '',
    });

    const getbanks = async (): Promise<void> => {
        setLoading(true);
        const res = await communication.getBanks();
        setBanks(res.data.data);
        setLoading(false);
    };

    const getData = async (): Promise<void> => {
        setLoading(true);
        const res = await communication.getUsersByBank(paramsFilter.bank_id, paramsFilter);
        setResponse(res.data);
        setTestUsers(res.data.data);
        setUsersNo(res.data.meta.pagination.total);
        setLoading(false);
    };

    const downloadExcel = async (): Promise<void> => {
        if (paramsFilter.bank_id !== '') {
            setLoading(true);
            const { limit, ...paramsWithoutLimit } = paramsFilter;
            const allUsersRes = await communication.getUsersByBank(paramsFilter.bank_id, paramsWithoutLimit);
            setShouldExport(true);
            setAllUsers(allUsersRes.data.data);

            setLoading(false);
        }
    };

    const handlePageClick = (e: any): void => {
        setParamsFilter({ ...paramsFilter, page: e.selected + 1 });
    };

    useEffect(() => {
        if (paramsFilter.bank_id !== '') {
            getData();
        }
    }, [paramsFilter]);

    useEffect(() => {
        if (shouldExport && allUsers?.length > 0) {
            exportToExcel('download', 'bank-users', 'bank-users.xlsx');
            setShouldExport(false);
        }
    }, [shouldExport, allUsers]);

    useEffect(() => {
        if (banks.length > 0) {
            setParamsFilter({
                ...paramsFilter,
                bank_id: banks[0]?.id,
            });
        }
    }, [banks]);

    useEffect(() => {
        getbanks();
        const yearsArray = Array.from({ length: currentYear - 2000 + 1 }, (_, i) => currentYear - i);
        setYears(yearsArray);
    }, []);

    return (
        <>
            {loading && <Loader />}

            {banks && (
                <>
                    <Container>
                        {banks && (
                            <FiltersStatistics
                                banks={banks}
                                years={years}
                                setParams={setParamsFilter}
                                params={paramsFilter}
                            />
                        )}
                        {banks && (
                            <Table>
                                <tbody>
                                    <Row>
                                        <Heading>{t('pages.admin.statistics.usersBank.bank').text}</Heading>
                                        <Heading>{t('pages.admin.statistics.usersBank.usersNo').text}</Heading>
                                    </Row>
                                    <Row>
                                        <Data>
                                            {paramsFilter.bank_id === ''
                                                ? banks[0]?.name
                                                : banks.find((x) => x.id === paramsFilter.bank_id)?.name}
                                        </Data>
                                        <Data>{usersNo}</Data>
                                    </Row>
                                </tbody>
                            </Table>
                        )}
                        {testUsers && testUsers.length > 0 ? (
                            <>
                                <Table>
                                    <tbody>
                                        <Row>
                                            <Heading>{t('pages.admin.statistics.users.name').text}</Heading>
                                            <Heading>{t('pages.admin.statistics.users.last').text}</Heading>
                                            <Heading>{t('pages.admin.statistics.users.agencyIdNumber').text}</Heading>
                                            <Heading>
                                                {t('pages.admin.statistics.users.bankAccountNumber').text}
                                            </Heading>
                                        </Row>

                                        {testUsers?.map((user: any, i: number) => (
                                            <Row key={i}>
                                                <Data>{user.first_name}</Data>
                                                <Data>{user.last_name}</Data>
                                                <Data>{user.identification_number}</Data>
                                                <Data>{user.account_number}</Data>
                                            </Row>
                                        ))}
                                    </tbody>
                                </Table>
                                <Pagination
                                    pageCount={response?.meta.pagination?.total_pages}
                                    onPageChange={(e: object) => handlePageClick(e)}
                                    nextLabel={`${
                                        response?.meta.pagination?.current_page ===
                                        response?.meta.pagination?.total_pages
                                            ? ''
                                            : '>'
                                    }`}
                                    previousLabel={`${response?.meta.pagination?.current_page === 1 ? '' : '<'}`}
                                    breakLabel="..."
                                    initialPage={response?.meta.pagination?.current_page - 1}
                                />
                            </>
                        ) : (
                            <NoItems text={t('pages.admin.statistics.noUsers').text} />
                        )}
                    </Container>
                    <ButtonContainer>
                        <Button
                            variant={ButtonVariant.solid}
                            color={'var(--purple)'}
                            icon
                            onClick={async () => {
                                downloadExcel();
                            }}
                        >
                            <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>
                            {t('pages.admin.statistics.save').text}
                        </Button>
                    </ButtonContainer>

                    <Table style={{ display: 'none' }} id="download">
                        <tbody>
                            <Row>
                                <Heading>{t('pages.admin.statistics.usersBank.bank').text}</Heading>
                                <Heading>{t('pages.admin.statistics.usersBank.usersNo').text}</Heading>
                            </Row>
                            <Row>
                                <Data>
                                    {paramsFilter.bank_id === ''
                                        ? banks[0]?.name
                                        : banks.find((x) => x.id === paramsFilter.bank_id)?.name}
                                </Data>
                                <Data>{usersNo}</Data>
                            </Row>
                        </tbody>
                        <tbody>
                            <Row>
                                <Heading>{t('pages.admin.statistics.users.name').text}</Heading>
                                <Heading>{t('pages.admin.statistics.users.last').text}</Heading>
                                <Heading>{t('pages.admin.statistics.users.agencyIdNumber').text}</Heading>
                                <Heading>{t('pages.admin.statistics.users.bankAccountNumber').text}</Heading>
                            </Row>

                            {allUsers?.length > 0 &&
                                allUsers?.map((user: any, i: number) => (
                                    <Row key={i}>
                                        <Data>{user.first_name}</Data>
                                        <Data>{user.last_name}</Data>
                                        <Data>{user.identification_number}</Data>
                                        <Data>{user.account_number}</Data>
                                    </Row>
                                ))}
                        </tbody>
                    </Table>
                </>
            )}
        </>
    );
};

const Container = styled.div`
    position: relative;
`;
export const Table = styled.table`
    :nth-of-type(1) {
        margin-top: 20px;
    }
    width: 100%;
    background-color: var(--white);
    border-collapse: collapse;
`;
export const Row = styled.tr`
    font-weight: 700;
`;
export const Heading = styled.th`
    background-color: var(--purple);
    color: var(--white);
    border: 1px solid rgba(0, 0, 0, 0.1);

    &.data {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px;
        font-size: 15px;
        text-align: center;
        vertical-align: middle;
    }
`;
export const Data = styled.td`
    border: 1px solid rgba(0, 0, 0, 0.1);

    padding: 10px;
    font-size: 15px;
    text-align: center;
    vertical-align: middle;
    font-weight: 400;
`;
export const ButtonContainer = styled.div`
    button {
        margin-top: 20px;
        float: right;
        font-size: 12px;
        padding: 8px 20px;
        vertical-align: top;
        border-radius: 100px;
    }
`;
export default Users;
