import ReactDOM from 'react-dom/client';
import App from './App';
import initFetch from './functions/init';
import reportWebVitals from './reportWebVitals';
import { useState, useEffect } from 'react';
import Loader from './components/loader';
import PaymentLoader from './components/loaderPayment';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

function RootComponent(): JSX.Element {
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [hasPaymentURL, setHasPaymentURL] = useState(false);

    useEffect(() => {
        initFetch().then(() => {
            setDataLoaded(true);
        });
        const { pathname } = window.location;
        if (
            pathname.includes('payment-success') ||
            pathname.includes('payment-error') ||
            pathname.includes('payment-cancel')
        ) {
            setHasPaymentURL(true);
        }
    }, []);

    return <>{isDataLoaded ? <App /> : hasPaymentURL ? <PaymentLoader /> : <Loader />}</>;
}

root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.error));
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
