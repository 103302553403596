import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import StepOne from './1step';
import StepTwo from './2step';
import StepThree from './3step';
import { ObjectKeys } from '../../types/objectKeys';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import communication from '../../communication';
import { useNavigate, useParams } from 'react-router-dom';
import { MQ_BREAKPOINTS, breakpoints } from '../../constants/breakpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RusImg from '../../assets/rus.png';
import SadImg from '../../assets/sad.png';
import SrbImg from '../../assets/srb.png';
import Logo from '../../assets/logo.png';
import { faFile, faFileLines, faPaste } from '@fortawesome/free-solid-svg-icons';
import Button, { ButtonVariant } from '../../components/button';
import { useDetectClickOutside } from 'react-detect-click-outside';
import Dropdown from '../../components/dropdown';
import SelectLang from '../../components/selectLangLogin';
import useResponsive from '../../hooks/responsive/useResponsive';

const Services: FunctionComponent = () => {
    const [step, setStep] = useState(1);
    const [cart, setCart] = useState<ObjectKeys>({});
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const paramStep = useParams().step;
    const params = useParams();
    const paramsCartId = useParams().cartId;
    const { isMobile, isMobileS } = useResponsive();

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const closeDropdown = (): void => {
        setDropdownVisible(false);
    };

    const userDropdown = [
        {
            icon: faPaste,
            label: t('navbar.main.privacy').text,
            link: '/Privacy.pdf',
            newTab: true,
        },
        {
            icon: faFile,
            label: t('navbar.main.paymentTerms').text,
            link: '/Uslovi_plaćanja.pdf',
            newTab: true,
        },
        {
            icon: faFile,
            label: t('navbar.main.terms').text,
            link: '/USLOVI_KORIŠĆENJA_Clever.pdf',
            newTab: true,
        },
    ];

    const userDropdownItemsList = userDropdown.map((item) => {
        return {
            ...item,
            handler: () => closeDropdown(),
        };
    });

    const ref = useDetectClickOutside({ onTriggered: closeDropdown });

    useEffect(() => {
        setStep(paramStep ? parseInt(paramStep) : 1);
    }, [paramStep]);

    useEffect(() => {
        if (paramsCartId) {
            getCart(paramsCartId);
        }
    }, [paramsCartId, params]);

    const getCart = (id: string): void => {
        communication
            .getCartById(id)
            .then((res: ObjectKeys) => {
                if (res.data.data.length < 1) {
                    setStep(1);
                    navigate('/guest/1');
                } else {
                    const cartData = res.data.data;
                    const items = cartData.items.data.map((item: any) => ({
                        created_at: item.service.data.created_at,
                        description_en: item.service.data.description_en,
                        description_rs: item.service.data.description_rs,
                        description_ru: item.service.data.description_ru,
                        id: item.service.data.id,
                        name_en: item.service.data.name_en,
                        name_rs: item.service.data.name_rs,
                        name_ru: item.service.data.name_ru,
                        price: item.price,
                        quantity: item.quantity,
                        type: 'service',
                        updated_at: item.service.data.updated_at,
                    }));
                    setCart({
                        status: cartData.status,
                        total_price: cartData.total_price,
                        type: 'service',
                        email: cartData.detail.data.email,
                        pib: cartData.detail.data.pib,
                        first_name: cartData.detail.data.first_name,
                        last_name: cartData.detail.data.last_name,
                        language: cartData.detail.data.language,
                        note: cartData.note,
                        items: items,
                    });
                }
            })
            .catch((err: ObjectKeys) => {
                console.error('err', err);
            });
    };

    const lang = localStorage.language;
    const flagUrl = lang === 'English' ? SadImg : lang === 'Русский' ? RusImg : SrbImg;

    return (
        <ParentContainer>
            <div className="mainHeaderDiv">
                <div className="logoDiv">
                    <img
                        className="appLogo"
                        src={Logo}
                        alt="pausal-logo"
                        onClick={() => {
                            navigate('/login');
                        }}
                    />
                </div>
                <div className="mainHeaderOptionsDiv" ref={ref}>
                    <Button
                        variant={ButtonVariant.solid}
                        color="var(--purple)"
                        size={isMobile ? (isMobileS ? 115 : 140) : 150}
                        onClick={() => {
                            navigate('/register');
                        }}
                        className="registerButton"
                    >
                        {t('navbar.pausal.register').text}
                    </Button>
                    <div className="userOptionsDiv">
                        <UserPlace
                            onClick={() => {
                                setDropdownVisible(!dropdownVisible);
                            }}
                        >
                            <FontAwesomeIcon icon={faFileLines} className="user-icon" />
                        </UserPlace>
                        {dropdownVisible && (
                            <DropdownContainer>
                                <Dropdown arrowRight="4px" itemList={userDropdownItemsList} />
                            </DropdownContainer>
                        )}
                    </div>
                    <div className="lang-selector">
                        <img src={flagUrl} alt="photo" width={'16px'} height={'11px'} key={localStorage.language} />
                        <SelectLang color={'black'} />
                    </div>
                </div>
            </div>
            <Container>
                <Header>
                    <div>
                        <h1>{t('navbar.pausal.services').text}</h1>
                    </div>
                </Header>
                <Content>
                    {step === 1 && (
                        <StepOne
                            nextStep={() => setStep(2)}
                            finallCart={(data: ObjectKeys) => setCart(data)}
                            mainCart={cart}
                        />
                    )}
                    {step === 2 && (
                        <StepTwo
                            cart={cart}
                            nextStep={() => setStep(3)}
                            updatedCart={(data: ObjectKeys) => setCart(data)}
                            previousStep={() => setStep(1)}
                        />
                    )}
                    {step === 3 && <StepThree cart={cart} previousStep={() => setStep(2)} />}
                </Content>
            </Container>
        </ParentContainer>
    );
};

const ParentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;

    .mainHeaderDiv {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;

        .logoDiv {
            display: flex;

            .appLogo {
                cursor: pointer;
                height: 2.188rem;
                width: 7.313rem;
            }
        }

        @media screen and (max-width: ${breakpoints.tablet - 1}px) {
            flex-direction: column;
        }
    }

    .mainHeaderOptionsDiv {
        display: flex;
        align-items: center;
        gap: 20px;

        .userOptionsDiv {
            position: relative;

            .user-icon {
                height: 30px;
                width: 15px;
            }
        }
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
        > div {
            width: 100%;
            justify-content: space-between;
            button {
                margin-left: 0;
            }
        }
    }
    .lang-selector {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: 20px;

        .select-lang {
            width: auto;
        }
    }

    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        padding: 20px 0;

        .mainHeaderDiv {
            gap: 10px;
        }

        .mainHeaderOptionsDiv {
            justify-content: end;
        }

        .lang-selector {
            width: 120px;
        }
    }
    @media only screen and (max-width: ${breakpoints.mobileL - 1}px) {
        .mainHeaderOptionsDiv {
            justify-content: space-between;
        }
    }

    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileS}) {
        .registerButton {
            font-size: 11px;
        }
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileXS}) {
        .lang-selector {
            width: 90px;
        }
    }
`;

export const UserPlace = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--purple);
    cursor: pointer;
    .user-icon {
        height: 0.8em;
    }
`;

export const DropdownContainer = styled.div`
    top: calc(100% + 1.2rem);
    height: 312px;
    width: auto;
    right: -14px;
    position: absolute;
    z-index: 9999;
    @media only screen and (max-width: ${breakpoints.tablet - 1}px) {
        width: auto;
        right: -12px;
    }
    @media only screen and (max-width: ${breakpoints.mobileXS}px) {
        right: -65px;
    }
`;

const Container = styled.div`
    padding: 40px;
    background: var(--light-gray);
    max-width: 1400px;
    margin: auto;
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        padding: 10px;
    }

    @media screen and (min-width: ${breakpoints.laptop}px) and (max-width: ${breakpoints.desktop - 1}px) {
        min-height: 111.1vh;
    }
`;
const Content = styled.div`
    text-align: center;
    margin-top: 20px;
    padding: 20px;
    p {
        font-weight: 500;
    }
`;
const Header = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--border-color);
    justify-content: space-between;
    padding-bottom: 20px;
    gap: 40px;
    > div {
        display: flex;
        align-item: center;
        button {
            margin-left: 35px;
            height: 35px;
            font-size: 12px;
            line-height: initial;
        }
    }
`;
export default Services;
