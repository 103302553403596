import React, { useState } from 'react';
import styled from 'styled-components';
import useAllUsersStore from '../../../../store/admin/allUsers';
import { formatTwoDecimals } from '../../../../functions/format';
import useServicesStore from '../../../../store/services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import Checkbox from '../../../../components/checkbox';
import communication from '../../../../communication';
import Modal from '../../../../components/modal';
import ConfirmModal from '../../../../components/confirmModal';
import Loader from '../../../../components/loader';
import { ObjectKeys } from '../../../../types/objectKeys';
import { transformDateTimeFormat } from '../../../../functions/formatDateTime';

interface CartProps {
    cart: {
        active_until: string;
        created_at: string;
        id: string;
        items: { data: any[] };
        status: string;
        total_price: number;
        updated_at: string;
        user_id: string;
        note: string;
        read: boolean;
        invoice_pdf_url: string;
        current_package_name: string | null;
        detail?: ObjectKeys;
    };
    fetchCarts: Function;
}

const CartCard: React.FC<CartProps> = ({ cart, fetchCarts }) => {
    const { allUsers } = useAllUsersStore();
    const [expanded, setExpanded] = useState(false);
    const { servicesData } = useServicesStore();

    const [loader, setLoader] = useState<boolean>(false);

    const [resendModal, setResendModal] = useState(false);

    const [clickedCartId, setClickedCartId] = useState('');

    const toggleExpanded = (): void => {
        setExpanded(!expanded);
    };

    const today = new Date();

    const returnUserData = (cartItem: ObjectKeys): string => {
        const current_package = cartItem.current_package_name ? cartItem.current_package_name : null;
        const id = cartItem.user_id;
        if (!id && cart.detail && cart.detail.data) {
            const data = cart.detail.data;
            const firstLastName = `${data.first_name} ${data.last_name}`;
            const email = data.email + ' - NoReg';
            const pib = data.pib;
            return `${firstLastName} - ${pib} - ${email}`;
        } else {
            const user = allUsers.find((useR: any) => useR.id === id);
            if (current_package) {
                return user ? `${user.email} - ${current_package}` : 'N/A';
            } else {
                return user ? `${user.email} - unknown package` : 'N/A';
            }
        }
    };

    const returnServiceData = (id: string): string => {
        const service = servicesData.find((serviceS: any) => serviceS.id === id);
        return service ? `${service.name_rs}` : 'N/A';
    };

    const handleDownloadClick = (url: string): void => {
        window.open(url, '_blank');
    };

    const handleResendFiscalBill = (id: string): void => {
        setClickedCartId(id);
        setResendModal(true);
    };

    const generateFiscalBill = (): void => {
        setLoader(true);
        communication
            .resendFiscalBill(clickedCartId)
            .then(() => {
                fetchCarts();
                setLoader(false);
            })
            .catch((error: any) => {
                console.error(error);
                fetchCarts();
                setLoader(false);
            });
    };

    const clickUpHandler = (id: string): void => {
        setLoader(true);
        communication
            .updateClickUp(id)
            .then(() => {
                fetchCarts();
                setLoader(false);
            })
            .catch((error: any) => {
                console.error(error);
                fetchCarts();
                setLoader(false);
            });
    };

    const transformDateFormat = (dateString: string): string => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-based, so we add 1
        const year = date.getFullYear();

        // Ensure leading zeros for single-digit day and month
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;

        // Return the transformed date in the desired format
        return `${formattedDay}.${formattedMonth}.${year}.`;
    };

    const compareDates = (dateStr1: string, dateStr2: string): boolean => {
        // Parse the date strings into Date objects
        const date1 = new Date(dateStr1);
        const date2 = new Date(dateStr2);

        // Convert dates to ISO strings (UTC timezone) for accurate comparison
        const isoDate1 = date1.toISOString();
        const isoDate2 = date2.toISOString();

        // Compare the ISO date strings
        return isoDate1 === isoDate2;
    };

    return (
        <>
            {loader && <Loader />}
            {resendModal && (
                <Modal
                    width="500px"
                    className="package-modal"
                    modalVisible={true}
                    closeModal={() => {
                        setResendModal(false);
                    }}
                >
                    <ConfirmModal
                        action={() => {
                            setResendModal(false);
                            generateFiscalBill();
                        }}
                        close={() => {
                            setResendModal(false);
                        }}
                        active={resendModal}
                        message={'Da li ste sigurni da zelite da izdate fiskalni racun?'}
                    />
                </Modal>
            )}
            <TableRow onClick={toggleExpanded}>
                <TableCell>{returnUserData(cart)}</TableCell>
                <TableCell>{formatTwoDecimals(cart.total_price.toString())}</TableCell>
                <TableCell>{transformDateFormat(cart.created_at)}</TableCell>
                <TableCell>
                    <p className={cart.status === 'completed' ? 'success' : 'error'}>{cart.status}</p>
                </TableCell>
                <TableCell>
                    {cart.read ? <p>{transformDateTimeFormat(cart.updated_at)}</p> : <p>nije procitano</p>}
                </TableCell>
                {cart.invoice_pdf_url ? (
                    <TableCell
                        onClick={(event) => {
                            event.stopPropagation();
                            handleDownloadClick(cart.invoice_pdf_url);
                        }}
                    >
                        <FontAwesomeIcon icon={faFileInvoice} className="billDownload" />
                    </TableCell>
                ) : (
                    <>
                        {compareDates(today.toISOString(), cart.created_at) ? (
                            <TableCell
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleResendFiscalBill(cart.id);
                                }}
                            >
                                <p className="billDownload">Ponovi slanje</p>
                            </TableCell>
                        ) : (
                            <TableCell>-</TableCell>
                        )}
                    </>
                )}
            </TableRow>
            {expanded && (
                <ExpandedRow>
                    <ExpandedCell colSpan={6}>
                        <ItemTable>
                            <thead>
                                <tr>
                                    <TableHeader>Usluga</TableHeader>
                                    <TableHeader>Cena (RSD)</TableHeader>
                                    <TableHeader>Kolicina</TableHeader>
                                    <TableHeader>Clickup (pročitano)</TableHeader>
                                    {/* Add more table headers as needed */}
                                </tr>
                            </thead>
                            <tbody>
                                {cart.items.data.map((item: any) => {
                                    const originalPrice = formatTwoDecimals(item.price.toString());
                                    const discountedPrice = formatTwoDecimals(
                                        (item.price - (item.price * item.packet_service_discount) / 100).toString(),
                                    );

                                    return (
                                        <tr key={item.id}>
                                            {item.service && item.service.data && item.service.data.id ? (
                                                <TableCell>{returnServiceData(item.service.data.id)}</TableCell>
                                            ) : (
                                                <TableCell>undefined</TableCell>
                                            )}
                                            <TableCell>
                                                {originalPrice !== discountedPrice && (
                                                    <p className="crossed">{originalPrice}</p>
                                                )}
                                                <p>{discountedPrice}</p>
                                            </TableCell>
                                            <TableCell>{item.quantity}</TableCell>
                                            <TableCell>
                                                {!item.read ? (
                                                    <CheckBox>
                                                        <Checkbox
                                                            defaultChecked={item.read}
                                                            onChange={() => {
                                                                clickUpHandler(item.id);
                                                            }}
                                                            className="clickup-checkbox"
                                                        />
                                                    </CheckBox>
                                                ) : (
                                                    <p>{transformDateTimeFormat(item.updated_at)}</p>
                                                )}
                                            </TableCell>
                                            {/* Add more table cells for additional item details */}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </ItemTable>
                        <div className="note">
                            <p>Napomena korisnika: {cart.note}</p>
                        </div>
                    </ExpandedCell>
                </ExpandedRow>
            )}
        </>
    );
};
const CheckBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

const TableRow = styled.tr`
    &:nth-child(even) {
        background-color: #f9f9f9;
    }
    cursor: pointer;
`;

const TableCell = styled.td`
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
    word-wrap: break-word;
    .icon {
        font-size: 20px;
        color: var(--purple);
    }
    .billDownload {
        cursor: pointer;
        color: var(--purple);
    }
    .crossed {
        text-decoration: line-through;
    }
    .error {
        color: red;
    }
    .success {
        color: #53be69;
    }
`;
const ExpandedCell = styled.td`
    padding: 10px;
    border: 1px solid #ddd;
    .note {
        margin-top: 10px;
    }
`;

const ItemTable = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const TableHeader = styled.th`
    padding: 10px;
    border: 1px solid #ddd;
    color: white;
`;

const ExpandedRow = styled.tr`
    background-color: var(--purple);
    color: white;
`;

export default CartCard;
