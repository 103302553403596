import { FunctionComponent, useEffect, useState } from 'react';
import Loader from '../../../../components/loader';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonVariant } from '../../../../components/button';
import colors from '../../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faPrint, faSquare, faCheckSquare, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import SelectComponent from '../../../../components/select';
import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';
import NoItems from '../../../../components/noItems';
import moment from 'moment';
import communication from '../../../../communication';
import TransferOrderPreviewModal from '../../../../components/transferOrderPreviewModal';
import Modal from '../../../../components/modal';
// import useAgencyStore from '../../../store/agency';
import { ObjectKeys } from '../../../../types/objectKeys';
import TransferOrderTemplate from '../../../../components/transferOrderTemplate';
import { getAccessToken } from '../../../../functions/auth';
import { generateQrCode } from '../../../../communication/qr';
import ManageOrdersModalAdmin, { ChangeAmountTypesAdmin } from './manageOrdersModalAdmin';
import { range } from 'lodash';
import SearchCustomComponent from '../../../../components/customSelect';
import useUserStore from '../../../../store/user';
import useAllUsersStore from '../../../../store/admin/allUsers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../../../components/sideModal';
import SendEmailModal from './sendEmailModal';
import ToggleSwitch from '../../../../components/input/switch';
import TransferOrderSkeleton from '../../../../components/transferOrderSkeleton';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';
import { useBackOfficePasswordProtect } from '../../../../hooks/backOfficePasswordProtect';

export type SelectOption = {
    value: string;
    label: string;
};
const EcotaxAdmin: FunctionComponent = () => {
    const { userBOid } = useUserStore();
    const { allUsers } = useAllUsersStore();
    const navigate = useNavigate();
    const paramsYear = useParams().year;
    const [allUsersOptions, setAllUsersOptions] = useState<Array<{ value: string; label: string }>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [previewModal, setPreviewModal] = useState<boolean>(false);
    const [selectedTransfer, setSelectedTransfer] = useState<ObjectKeys>({});
    const [yearsOptions, setYearsOptions] = useState<Array<{ value: any; label: any }>>([]);

    const [ecotaxTemplates, setEcotaxTemplates] = useState<ObjectKeys>([]);
    const [ecotaxQuarterlyTemplates, setEcotaxQuarterlyTemplates] = useState<ObjectKeys>([]);

    const [annualShow, setAnnualShow] = useState<boolean>(false);
    const [paidCheck, setPaidCheck] = useState<boolean>(false);

    const [isInfoModalActive, setIsInfoModalActive] = useState<boolean>(false);
    const [manageOrdersModalActive, setIsManageOrdersModalActive] = useState<boolean>(false);
    const [selectedUserAgencyId, setSelectedUserAgencyId] = useState<string>('');
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [qrCode, setQrCode] = useState<string>('');
    const [isQrCode, setIsQrCode] = useState<boolean>(false);
    // const agency = useAgencyStore((props) => props.agency);
    const [selectedYear, setSelectedYear] = useState<string>(paramsYear ? paramsYear : moment().year().toString());
    const [selectUsersOptions, setSelectUsersOptions] = useState<SelectOption[]>([]);
    const [users, setUsers] = useState<ObjectKeys[]>([]);
    const [selectedUser, setSelectedUser] = useState<ObjectKeys>();
    const [employed, setEmployed] = useState<boolean>(false);
    const [is_authorised, setIsAuthorised] = useState<boolean>(false);
    const [sendModal, setSendModal] = useState(false);

    useBackOfficePasswordProtect();

    const [renderKey, setRenderKey] = useState<number>(0);

    const getCurrentMonthIndex = (): number => {
        const currentDate = moment();
        const currentDayOfMonth = currentDate.date();
        if (currentDayOfMonth <= 15) {
            return currentDate.subtract(1, 'month').month();
        } else {
            return currentDate.month();
        }
    };

    const currentMonthIndex = getCurrentMonthIndex();

    const [currentMonth, setCurrentMonth] = useState<string>(`${currentMonthIndex + 1}`);

    const [currentEcoTaxOrder, setCurrentEcoTaxOrder] = useState<ObjectKeys>({
        id: '',
        recipient: '',
        orderer: '',
        total_amount: 0,
        payment_slip_heading: '',
        recipient_account: '',
        currency: '',
        model_number_recipient: '',
        call_number_recipient: '',
        payment_code: '',
        status: '',
        paid_amount: 0,
        paid_date: '',
        quarter: false,
    });

    const [quarterOptions, setQuarterOptions] = useState<Array<{ value: number; label: string }>>([
        { value: 0, label: '1. Kvartal' },
        { value: 1, label: '2. Kvartal' },
        { value: 2, label: '3. Kvartal' },
        { value: 3, label: '4. Kvartal' },
    ]);

    const currentQuarterIndex = moment().quarter();
    const [quarter, setQuarter] = useState<number>(0);

    useEffect(() => {
        const userData = allUsers?.map((user) => {
            return { value: user.id, label: user.email + ' - ' + user.pib };
        });
        if (userBOid === '' || userBOid === 'All users') useUserStore.setState({ userBOid: userData[0]?.value });
        setAllUsersOptions([...userData]);
    }, [allUsers]);

    const token = getAccessToken();

    const getAllUsers = async (): Promise<void> => {
        setIsLoading(true);
        const response = await communication.getAllUsers();
        setUsers(response?.data);

        // Reset the select users options before setting new ones
        setSelectUsersOptions(
            response?.data.map((user: { email: any; pib: any; agency_id: any }) => ({
                label: `${user.email} - ${user.pib}`,
                value: user.agency_id,
            })),
        );

        const chosenUser = response.data.find((user: ObjectKeys) => user.id === userBOid);
        setSelectedUser(chosenUser);
        setSelectedUserAgencyId(chosenUser?.agency_id.toString());

        await getAllTransferTemplates();
        setIsLoading(false);
    };

    const getAllTransferTemplates = async (): Promise<void> => {
        setIsLoading(true);
        if (userBOid) {
            communication.getAllAdminEcotaxTransfers(userBOid, selectedYear).then((res: ObjectKeys) => {
                const transferTemplatesData = res.data.data;
                const sortedTransferTemplates = transferTemplatesData.sort((a: ObjectKeys, b: ObjectKeys) => {
                    return a.orderTemplates - b.orderTemplates;
                });

                setEcotaxTemplates(sortedTransferTemplates);
                if (res.data.data.length > 0) {
                    const parentData = res.data.data[0];
                    const quarterlyData = parentData.quarterly_eko_taxes.data;

                    const ecotaxQuarterly = quarterlyData.map((item: any) => {
                        return {
                            ...parentData,
                            id: item.id,
                            paid_amount: item.paid_amount,
                            paid_date: item.paid_date,
                            quarter: item.quarter,
                            status: item.status,
                            total_amount: item.total_amount,
                        };
                    });

                    setEcotaxQuarterlyTemplates(ecotaxQuarterly);
                } else {
                    setEcotaxQuarterlyTemplates([]);
                }
            });
        }
        setIsLoading(false);
    };

    const getAgency = async (): Promise<void> => {
        setIsLoading(true);
        if (selectedUserAgencyId) {
            const res = await communication.getAgencyById(selectedUserAgencyId);
            setEmployed(res?.data?.data?.employment_type !== 'flat_rate_agency' ? true : false);
            setIsAuthorised(res?.data?.data?.user?.data?.authorised);

            const thisYear = parseInt(moment().format('YYYY'));
            const firstYear = parseInt(moment(res.data.data?.date_of_registration).format('YYYY'));
            const yearsArray = range(firstYear, thisYear + 1)
                .reverse()
                .map((y) => {
                    return { label: y, value: y };
                });
            setYearsOptions(yearsArray);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getAllUsers();
        setIsInfoModalActive(true);
        setQuarter(currentQuarterIndex - 1);
    }, []);

    useEffect(() => {
        let isCancelled = false;
        const effectFunction = async (): Promise<void> => {
            if (!isCancelled) {
                navigate(`/admin/ecotax/${selectedYear}`);
                await getAllTransferTemplates();
            }
        };
        effectFunction();
        return () => {
            isCancelled = true;
        };
    }, [selectedYear, currentMonth]);

    useEffect(() => {
        let isCancelled = false;
        const effectFunction = async (): Promise<void> => {
            if (!isCancelled) {
                await getAgency();
                await getAllTransferTemplates();
                setSelectedYear(moment().year().toString());
                setRenderKey(renderKey + 2);
            }
        };
        effectFunction();
        return () => {
            isCancelled = true;
        };
    }, [selectedUserAgencyId]);

    useEffect(() => {
        if (paramsYear) {
            setSelectedYear(paramsYear);
        }
    }, [paramsYear]);

    const downloadEcotax = async (): Promise<void> => {
        try {
            const qrCodeR = await getQrCode(currentEcoTaxOrder);
            communication.downloadSingleEcotax(
                currentEcoTaxOrder?.id,
                annualShow ? 'year' : 'quarter',
                qrCodeR ? qrCodeR : '',
            );
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const getQrCode = async (transfer: ObjectKeys): Promise<string | undefined> => {
        try {
            const res: any = await generateQrCode(
                transfer?.recipient,
                transfer?.orderer,
                transfer.total_amount,
                transfer.payment_slip_heading,
                transfer.recipient_account,
                transfer.currency,
                true,
                `${transfer.model_number_recipient === '97' ? transfer.model_number_recipient : '00'}${
                    transfer.call_number_recipient
                }`,
                transfer.payment_code,
            );
            setQrCode(res?.i);
            setIsQrCode(true);

            return res?.i;
        } catch (error) {
            setQrCode('');
            console.error('An error occurred:', error);
            return undefined;
        }
    };

    const handleEservice = async (e: boolean): Promise<void> => {
        setIsLoading(true);
        const res = await communication.updateUser({ authorised: e }, selectedUser?.id);
        if (res.status === 200) {
            setIsAuthorised(res.data.data.authorised);
            setIsLoading(false);
            toast.success('Uspešno ste promenili ovlašćenje za korisnika');
            getAllUsers();
        } else {
            toast.error('Došlo je do greške prilikom promene ovlašćenja za korisnika');
        }
    };

    useEffect(() => {
        if (!annualShow && ecotaxQuarterlyTemplates.length > 0) {
            const item = ecotaxQuarterlyTemplates[quarter];
            // setPaymentDate(item.paid_date && item.status === 'paid' ? new Date(item.paid_date) : null);
            setCurrentEcoTaxOrder({
                id: item.id,
                recipient: item.recipient,
                orderer: item.orderer,
                total_amount: item.total_amount,
                payment_slip_heading: item.quarterly_eko_taxes.data[quarter].purpose_of_payment,
                recipient_account: item.recipient_account,
                currency: item.currency,
                model_number_recipient: item.model_number_recipient,
                call_number_recipient: item.call_number_recipient,
                payment_code: item.payment_code,
                status: item.status,
                paid_amount: item.paid_amount,
                paid_date: item.paid_date,
                quarter: true,
            });
            // setChecked(item.status === 'paid');
            // setPaidCheck(item.status === 'paid');
            // setShowChekInput(true);
        } else if (ecotaxTemplates.length > 0) {
            const item = ecotaxTemplates[0];
            // setPaymentDate(item.paid_date && item.status === 'paid' ? new Date(item.paid_date) : null);
            setCurrentEcoTaxOrder({
                id: item.id,
                recipient: item.recipient,
                orderer: item.orderer,
                total_amount: item.total_amount,
                payment_slip_heading: item.purpose_of_payment,
                recipient_account: item.recipient_account,
                currency: item.currency,
                model_number_recipient: item.model_number_recipient,
                call_number_recipient: item.call_number_recipient,
                payment_code: item.payment_code,
                status: item.status,
                paid_amount: item.paid_amount,
                paid_date: item.paid_date,
                quarter: false,
            });
            // setChecked(item.status === 'paid');
            // setPaidCheck(item.status === 'paid');
            // setShowChekInput(true);
        }
    }, [annualShow, quarter, ecotaxQuarterlyTemplates]);

    return (
        <PageWrapper>
            {sendModal && (
                <Sidebar close={() => setSendModal(false)}>
                    <SendEmailModal
                        close={() => setSendModal(false)}
                        t={t}
                        success={() => {
                            toast.success('E-mail je uspešno poslat.');
                        }}
                        loading={(e: boolean) => setIsLoading(e)}
                        clientEmail={selectedUser?.email}
                        errorMessage={(mess: string) => {
                            toast.error(mess);
                        }}
                    />
                </Sidebar>
            )}

            {isLoading && <Loader />}

            <ToastContainer />

            {previewModal && (
                <Modal
                    modalVisible={previewModal}
                    closeModal={() => {
                        setPreviewModal(false);
                        setIsQrCode(false);
                    }}
                    size={'800px'}
                    className={'preview-modal '}
                >
                    <TransferOrderPreviewModal
                        image={qrCode}
                        onClose={() => {
                            setPreviewModal(false);
                        }}
                        transfer={selectedTransfer}
                        isQrCode={isQrCode}
                        downloadTax={downloadEcotax}
                    />
                </Modal>
            )}

            {manageOrdersModalActive && selectedUser && (
                <Modal
                    className={'manage-orders-modal '}
                    modalVisible={manageOrdersModalActive}
                    closeModal={() => setIsManageOrdersModalActive(false)}
                >
                    <ManageOrdersModalAdmin
                        closeModal={() => setIsManageOrdersModalActive(false)}
                        yearsOptions={yearsOptions}
                        year={selectedYear}
                        transferOrders={ecotaxTemplates}
                        setIsLoader={setIsLoading}
                        setOpenInfoModal={setIsInfoModalActive}
                        refresh={() => {
                            setIsManageOrdersModalActive(false);
                            getAllTransferTemplates();
                        }}
                        isEdit={ecotaxTemplates.length > 0}
                        user_id={selectedUser?.id}
                        selectedUser={selectedUser}
                    />
                </Modal>
            )}

            <Modal
                className={'info-modal '}
                modalVisible={isInfoModalActive}
                closeModal={() => setIsInfoModalActive(false)}
            >
                <div className={'info-modal-content'}>
                    <h2>{t('pages.transfers.changing_user_title').text}</h2>
                    <p>
                        {t('pages.transfers.changing_user_name').text} {selectedUser?.first_name}
                    </p>
                    <p>
                        {t('pages.transfers.changing_user_last_name').text} {selectedUser?.last_name}
                    </p>
                    <p>
                        {t('pages.transfers.changing_user_email').text} {selectedUser?.email}
                    </p>
                    <p>
                        {t('pages.transfers.changing_user_pib').text} {selectedUser?.pib}
                    </p>
                </div>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    size={'100%'}
                    onClick={() => {
                        setIsInfoModalActive(false);
                    }}
                >
                    {t(['buttons.ok']).text}
                </Button>
            </Modal>

            <SelectUser>
                <label>{t('pages.transfers.title').text}</label>
                {selectUsersOptions?.length > 0 && (
                    <SearchCustomComponent
                        value={allUsersOptions.find((v) => v.value === userBOid)}
                        allUsersOptions={selectUsersOptions}
                        handleSelectedValue={(datas: { value: string; label: string }) => {
                            const userId = allUsers.find((user: ObjectKeys) => user.agency_id === datas.value)?.id;
                            useUserStore.setState({ userBOid: userId });
                            setSelectedUserAgencyId(datas.value);
                            users.map((user: ObjectKeys) => {
                                user?.agency_id === datas.value && setSelectedUser(user);
                            });

                            setIsInfoModalActive(true);
                        }}
                        placeholder={t('pages.admin.users.chooseUser').text}
                        className="user-selector"
                    />
                )}
            </SelectUser>

            <CheckBoxContainer>
                <p>OVLAŠĆENJE ZA UPOTREBU E-SERVISA</p>
                <div
                    onClick={() => {
                        handleEservice(!is_authorised);
                    }}
                    className="checkbox"
                >
                    <FontAwesomeIcon icon={is_authorised ? faCheckSquare : faSquare} className="check-icon" />
                </div>
            </CheckBoxContainer>

            <Header>
                <h1>Eko taksa</h1>
                <div className="header-right">
                    <div className="header-options">
                        <div className="header-options-buttons">
                            <Button
                                className={'button'}
                                variant={ButtonVariant.outlined}
                                color={colors.purple}
                                onClick={() => {
                                    setSendModal(true);
                                }}
                                icon={true}
                                height={35}
                            >
                                <FontAwesomeIcon icon={faPaperPlane} />
                                {'Pošalji mejl'}
                            </Button>
                            {ecotaxTemplates.length > 0 ? (
                                <Button
                                    className={'button'}
                                    variant={ButtonVariant.outlined}
                                    color={colors.purple}
                                    onClick={() => downloadEcotax()}
                                    icon={true}
                                    height={35}
                                >
                                    <FontAwesomeIcon icon={faPrint} />
                                    {t('pages.transfers.print_transfers').text}
                                </Button>
                            ) : null}
                            <Button
                                className={'button'}
                                variant={ButtonVariant.outlined}
                                color={colors.purple}
                                onClick={() => setIsManageOrdersModalActive(true)}
                                icon={true}
                                height={35}
                            >
                                <FontAwesomeIcon icon={faPencil} />
                                {t('pages.transfers.manage_transfers').text}
                            </Button>
                        </div>
                    </div>
                </div>
                <Filters>
                    <label>{`Prikaz naloga za eko taksu za godinu ${selectedYear}`}</label>
                    <div className="search-filter">
                        {!annualShow ? (
                            <SelectComponent
                                optionList={quarterOptions}
                                defaultSelectedOption={quarterOptions[quarter]}
                                className={'select-month'}
                                width={'150px'}
                                handleSelectedValue={(value: number) => {
                                    setQuarter(value);
                                }}
                            />
                        ) : (
                            <div className="period">
                                <p>
                                    {t('pages.transfers.payment_lists.january').text} -{' '}
                                    {t('pages.transfers.payment_lists.december').text}
                                </p>
                            </div>
                        )}
                        <SelectComponent
                            optionList={yearsOptions}
                            defaultSelectedOption={yearsOptions.find((option) => option.value === selectedYear)}
                            className={'select-year'}
                            placeholder={selectedYear}
                            handleSelectedValue={(value: string) => {
                                navigate(`/admin/ecotax/${selectedYear}`);
                                setSelectedYear(value);
                            }}
                            key={selectedYear}
                            width={'80px'}
                        />
                    </div>
                </Filters>
            </Header>

            <ToggleRow>
                <ToggleSwitch
                    isChecked={annualShow}
                    onChange={() => {
                        setAnnualShow(!annualShow);
                    }}
                    isDisabled={false}
                    offLabel={t('pages.eco_tax_transfer.annual').text}
                    onLabel={t('pages.eco_tax_transfer.quarterly').text}
                />
            </ToggleRow>

            <TransferOrderTemplateContainer>
                {isLoading ? (
                    <SkeletonContainer>
                        <TransferOrderSkeleton />
                    </SkeletonContainer>
                ) : (
                    <>
                        {ecotaxTemplates.length !== 0 && currentEcoTaxOrder.id ? (
                            <TransferOrderTemplate
                                key={currentEcoTaxOrder.id}
                                template={currentEcoTaxOrder}
                                onClick={() => {
                                    getQrCode(currentEcoTaxOrder);
                                    setSelectedTransfer(currentEcoTaxOrder);
                                    setPreviewModal(true);
                                }}
                                isPaid={currentEcoTaxOrder.status === 'paid'}
                            />
                        ) : (
                            <NoItems text={`Nema naloga za eko taksu u ${selectedYear}. godini`} />
                        )}
                    </>
                )}
            </TransferOrderTemplateContainer>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    min-height: 100vh;
    display: inline-block;
    vertical-align: top;
    width: -webkit-fill-available;
    width: -moz-available;
    .button-add {
        margin-top: 20px;
    }
    .preview-modal {
        .modal-wrapper {
            width: 800px;
            padding: 15px;
            overflow: auto;
            max-height: 100vh;
        }
    }
    .manage-orders-modal {
        .modal-wrapper {
            max-height: 90%;
            overflow: auto;
        }
    }
    .info-modal-content {
        display: block;
        padding: 35px 50px 30px;
        text-align: center;
        min-height: 130px;
        h2 {
            font-size: 20px;
            color: #4c595f;
            font-weight: 400;
            margin-bottom: 30px;
            line-height: 1.5;
        }
        p {
            font-size: 16px;
            margin-top: 5px;
            line-height: 30px;
            white-space: pre-line;
        }
    }
`;

const SelectUser = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    label {
        display: inline-block;
        color: #92a5ac;
        font-size: 16px;
        position: relative;
        top: 2px;
        margin-right: 10px;
    }
    .select-wrapper {
        width: max-content;
        min-width: 257px;
        .select__menu {
            width: 100%;
        }
    }
`;

const TransferOrderTemplateContainer = styled.div`
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 auto 25px;
    width: 100%;
    margin-top: 10px;
`;

const TaxSolutionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 100px 0px;
    @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
        padding: 0;
    }
    .tax-solution {
        margin: 10px;
    }
`;

const SkeletonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .skeleton {
        margin: 10px;
    }
    width: 100%;
`;

const CheckBoxContainer = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 auto 25px;
    width: 100%;
    p {
        margin-right: 10px;
        color: var(--gray);
    }
    .checkbox {
        cursor: pointer;
        .check-icon {
            color: var(--purple);
            border-radius: 5px;
            font-size: 18px;
        }
    }
`;

const Header = styled.div`
    padding-bottom: 10px;
    position: relative;
    display: inline-block;
    width: 100%;
    @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
    }
    h1 {
        font-size: 36px;
        display: inline-block;
    }
    button {
        font-size: 12px;
    }
    .header-right {
        float: right;
        margin: 0 0 0 35px;
    }
    .header-options-button {
        display: inline-block;
    }
    .buttons {
        color: var(--purple);
        display: inline-block;
        &:first-child {
            margin-right: 10px;
        }
    }
    .input-container {
        margin-bottom: 0;
    }
    .find {
        background-color: var(--white);
        padding: 20px 0px 20px 30px;
        border: none;
    }
    .find-icon {
        position: relative;
        align-self: flex-start;
        justify-self: center;
        bottom: 27px;
        left: 9px;
    }
    .header-options {
        display: flex;
    }
    .header-options-buttons {
        display: flex;
        button {
            margin-left: 20px;
        }
    }
`;

const Filters = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100%;
    margin-top: 20px;
    padding: 20px 20px;
    background-color: var(--purple);
    color: var(--white);
    vertical-align: middle;
    @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
        height: auto;
    }
    .select {
        width: 100%;
        display: inline-block;
        background-color: var(--white);
    }
    .select-wrapper {
        display: inline-block;
    }
    .filter {
        display: flex;
        float: right;
        align-items: center;
        margin-right: 10px;
        max-width: 200px;
        @media (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            display: flex;
            margin-top: 20px;
            margin-right: 0;
        }
        @media (max-width: ${MQ_BREAKPOINTS.laptopM}) {
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
            width: 80px;
        }
    }
    .select-month {
        background-color: var(--white);
        color: var(--gray);
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            float: none;
        }
    }

    .search-filter {
        top: 78px;
        display: flex;
        gap: 20px;
        @media (max-width: ${MQ_BREAKPOINTS.laptopM}) {
            margin-right: 0;
        }
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            width: 100%;
            float: left;
            margin-top: 20px;
            .select-year {
                float: left;
            }
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
        }
        .select-wrapper {
            display: inline-block;
        }
    }
    .select-year {
        background-color: var(--white);
        color: var(--gray);
        float: right;
        width: fit-content;
    }
    .period {
        display: flex;
        align-items: center;
        width: 150px;
        gap: 10px;
        p {
            font-size: 14px;
            line-height: 34px;
        }
    }
`;
const ToggleRow = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        color: var(--gray);
        font-size: 15px;
    }
    .left {
        margin-right: 10px;
    }
    .right {
        margin-left: 10px;
    }
`;

export default EcotaxAdmin;
