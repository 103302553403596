import { FunctionComponent, useEffect, useState } from 'react';
import { ObjectKeys } from '../../../../types/objectKeys';
import styled, { keyframes } from 'styled-components';
import Modal from '../../../../components/modal';
import ConfirmModal from '../../../../components/confirmModal';
import useAllUsersStore from '../../../../store/admin/allUsers';
import communication from '../../../../communication';
import SelectComponent from '../../../../components/select';
import Sidebar from '../../../../components/sideModal';
import Button, { ButtonVariant } from '../../../../components/button';
import colors from '../../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Input, { InputComponentType } from '../../../../components/input';
import moment from 'moment';
import SearchCustomComponent from '../../../../components/customSelect';
import useUserStore from '../../../../store/user';
import useCharLengthValidation from '../../../../hooks/useCharLengthValidation';
import { formatTwoDecimals } from '../../../../functions/format';
import 'react-toastify/dist/ReactToastify.css';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';
import Checkbox from '../../../../components/checkbox';

type NewTransactionTypes = {
    amount: string;
    transaction_date: Date | undefined | null | string;
    type: string;
    package: string;
    user_id: string;
};

interface Props {
    t: Function;
    closeModal: Function;
    packages: ObjectKeys[];
    success: Function;
}

const NewTransaction: FunctionComponent<Props> = ({ t, closeModal, packages, success }) => {
    const { userBOid } = useUserStore();
    const { allUsers } = useAllUsersStore();
    const transactionDataInitial = {
        amount: '830',
        transaction_date: new Date().toISOString().split('T')[0],
        type: 'payment',
        package: '1',
        user_id: '',
    };

    const [allUsersOptions, setAllUsersOptions] = useState<Array<{ value: string; label: string; id: string }>>([]);
    const [selectedUserId, setSelectedUserId] = useState(userBOid ? userBOid : '');

    const [selectedUser, setSelectedUser] = useState<ObjectKeys>({});
    const [renderKey, setRenderKey] = useState(0);

    const [addTransactionModal, setAddTransactionModal] = useState(false);

    const [selectedPackageOption, setSelectedPackageOption] = useState<ObjectKeys>({});
    const [cart, setCart] = useState<ObjectKeys>({
        user_id: selectedUserId ? selectedUserId : '',
        status: '',
        total_price: 0,
        type: 'package',
        payment_type: 'transfer',
        note: '',
        is_upgrade: false,
        items: [
            {
                id: '',
                type: 'package',
                price: 0,
                months: 1,
            },
        ],
    });

    const [confirmSendModal, setConfirmSendModal] = useState(false);

    const [selectedUserInfo, setSelectedUserInfo] = useState<ObjectKeys>({});
    const [leftOverAmount, setLeftOverAmount] = useState<number | null>(null);
    const [isUpgrade, setIsUpgrade] = useState(false);
    const [_discount, setDiscount] = useState<number>(0);

    const [negativeAmountError, setNegativeAmountError] = useState(false);
    const [packageError, setPackageError] = useState(false);
    const [disablePackageSelection, setDisablePackageSelection] = useState<boolean>(true);
    const [printFiscal, setPrintFiscal] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState(false);

    const resetCart = (): void => {
        setCart({
            user_id: selectedUserId ? selectedUserId : '',
            status: '',
            total_price: 0,
            type: 'package',
            payment_type: 'transfer',
            note: '',
            is_upgrade: false,
            items: [
                {
                    id: '',
                    type: 'package',
                    price: 0,
                    months: 1,
                },
            ],
        });
    };

    useEffect(() => {
        if (selectedUserInfo && selectedUserInfo.upgrade_package_data) {
            if (!isUpgrade) {
                setLeftOverAmount(null);
            } else {
                setLeftOverAmount(selectedUserInfo.upgrade_package_data.upgrade_left_over_amount);
            }
        }
    }, [isUpgrade, selectedUserInfo, selectedPackageOption, cart.items]);

    useEffect(() => {
        if (selectedPackageOption?.name !== 'Basic') {
            setPackageOptions([
                { value: 6, label: '6' },
                { value: 12, label: '12' },
            ]);
        } else {
            setPackageOptions([
                { value: 1, label: '1' },
                { value: 6, label: '6' },
                { value: 12, label: '12' },
            ]);
        }
    }, [selectedPackageOption]);

    useEffect(() => {
        setRenderKey((prev) => prev + 1);
    }, [cart.items[0].months]);

    const [newTransactionData, setNewTransactionData] = useState<NewTransactionTypes>(transactionDataInitial);

    const [packageOptions, setPackageOptions] = useState([
        { value: 1, label: '1' },
        { value: 6, label: '6' },
        { value: 12, label: '12' },
    ]);

    useEffect(() => {
        if (Object.keys(selectedPackageOption).length !== 0) {
            const item = cart.items[0];
            const discount =
                item.months === 6
                    ? selectedPackageOption.half_year_discount
                    : item.months === 12
                    ? selectedPackageOption.year_discount
                    : 0;
            const period = item.months;
            setCart({
                ...cart,
                total_price:
                    selectedPackageOption.monthly * period - (selectedPackageOption.monthly * period * discount) / 100,
            });
            if (cart && !selectedUserInfo.trial && selectedUserInfo.active) {
                setDiscount((selectedPackageOption.monthly * period * discount) / 100);
            } else {
                setDiscount(0);
            }
        }
    }, [cart.items]);

    useEffect(() => {
        const userData = allUsers?.map((user) => {
            return { value: user.id, label: user.email + '-' + user.pib, id: user.id };
        });
        if (userBOid === '' || userBOid === 'All users') useUserStore.setState({ userBOid: userData[0]?.value });
        const allUsersOption = { value: '', label: 'Svi korisnici', id: '' };
        setAllUsersOptions([allUsersOption, ...userData]);
    }, [allUsers]);

    useEffect(() => {
        setSelectedUserId(userBOid);
    }, [userBOid]);

    const getUserById = (id: string): void => {
        setIsLoading(true);
        communication
            .getUsersById(id)
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setSelectedUserInfo(res.data.data);
                    setIsLoading(false);
                    setDisablePackageSelection(false);
                }
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setIsLoading(false);
                    setSelectedUserInfo({});
                    setLeftOverAmount(null);
                    // toast.error('Korisnik sa unetim ID-em ne postoji');
                }
            });
    };

    useEffect(() => {
        if (!addTransactionModal) {
            setNegativeAmountError(false);
        }
    }, [addTransactionModal]);

    useEffect(() => {
        if (selectedUserId) {
            getUserById(selectedUserId);
            resetCart();
        }
    }, [selectedUserId]);

    const [showError, setShowError] = useState(false);

    const isUserIdValid = useCharLengthValidation(newTransactionData?.user_id ? newTransactionData?.user_id : '', 6);

    const isDateValid =
        newTransactionData.transaction_date === undefined || newTransactionData.transaction_date === null
            ? 'Datum je obavezan'
            : '';

    const resetData = (): void => {
        setNewTransactionData(transactionDataInitial);
        setShowError(false);
        setSelectedUser({});
    };

    useEffect(() => {
        const upgrade = selectedUserInfo.trial || isDowngrade(cart.items[0].id) ? false : true;
        setIsUpgrade(upgrade);
    }, [cart.items]);

    const handleCreateNewTransaction = async (): Promise<void> => {
        const updatedCart = {
            ...cart,
            total_price: isUpgrade ? cart.total_price - (leftOverAmount ?? 0) : cart.total_price,
            items: cart.items.map((prev: ObjectKeys) => ({
                ...prev,
                price: isUpgrade ? cart.total_price - (leftOverAmount ?? 0) : cart.total_price,
            })),
            is_upgrade: isUpgrade,
        };
        if (updatedCart.total_price <= 0 || updatedCart.items[0].price <= 0) {
            setNegativeAmountError(true);
            return;
        }
        if (updatedCart.items[0].id.length === 0) {
            setPackageError(true);
            return;
        }
        setNegativeAmountError(false);
        await communication.createCart(updatedCart).then((res: ObjectKeys) => {
            if (res.status === 201) {
                const formData = {
                    cart_id: res.data.data.id,
                    transaction_date: newTransactionData.transaction_date,
                    print_fiscal: printFiscal,
                };
                communication.createTransactionFromCart(formData).then((response: ObjectKeys) => {
                    if (response.status === 200) {
                        closeModal(false);
                        success();
                        resetData();
                    }
                });
            }
        });
    };

    const isDowngrade = (id: string): boolean => {
        if (!selectedUserInfo.payment_package) return false;
        const userPackageMonthly = selectedUserInfo.payment_package.data.monthly;
        const packageOption = packages.find((item: ObjectKeys) => item.id === id);
        const response = packageOption ? packageOption.monthly <= userPackageMonthly : false;
        return response;
    };

    return (
        <>
            {confirmSendModal ? (
                <Modal modalVisible={confirmSendModal} closeModal={() => setConfirmSendModal(false)}>
                    <ConfirmModal
                        action={async () => {
                            setConfirmSendModal(false);
                            handleCreateNewTransaction();
                            closeModal(false);
                        }}
                        close={() => {
                            setAddTransactionModal(false);
                            setConfirmSendModal(false);
                            closeModal(false);
                        }}
                        active={confirmSendModal}
                        message={'Da li ste sigurni da zelite da izvršite transakciju?'}
                    />
                </Modal>
            ) : (
                <Sidebar
                    close={() => {
                        closeModal(false);
                        resetData();
                    }}
                >
                    <SidebarContent>
                        <div className="header">
                            <h1>Dodaj transakciju</h1>
                            <button
                                className={'close_button'}
                                onClick={() => {
                                    closeModal(false);
                                    resetData();
                                }}
                            />
                        </div>
                        <div className="content">
                            <p className="user-select">{t('pages.admin.users.chooseUser').text}:</p>
                            <div className="choose-user">
                                <SearchCustomComponent
                                    value={allUsersOptions.find((v) => v.value === userBOid)}
                                    allUsersOptions={allUsersOptions}
                                    handleSelectedValue={(data: { value: string; label: string }) => {
                                        useUserStore.setState({ userBOid: data.value });
                                        setSelectedUserId(data.value);
                                        setNewTransactionData((prevState) => ({
                                            ...prevState,
                                            user_id: data.value,
                                        }));
                                        resetCart();
                                        setRenderKey((prev) => prev + 1);
                                    }}
                                    placeholder={t('pages.admin.users.chooseUser').text}
                                    className="user-select-transaction"
                                />
                            </div>
                            {showError && isUserIdValid !== '' && (
                                <span style={{ color: 'red', fontSize: '12px' }}>{isUserIdValid}</span>
                            )}
                            {selectedUser.pib && (
                                <span className={'selected-user'}>
                                    {selectedUser.pib} - {selectedUser.email}{' '}
                                    <FontAwesomeIcon
                                        icon={faClose}
                                        color={colors.gray}
                                        className="delete-selected-user"
                                        onClick={() => setSelectedUser({})}
                                    />
                                </span>
                            )}
                            {selectedUserId ? (
                                <div className="create-transaction-form">
                                    <Packages>
                                        <p className="user-select">ODABERI PAKET:</p>
                                        <div className={`packages ${disablePackageSelection && 'pointerEvents'}`}>
                                            {packages.map((item, index) => (
                                                <Package
                                                    key={index}
                                                    isSelected={item.id === selectedPackageOption.id}
                                                    onClick={() => {
                                                        setSelectedPackageOption(packages[index]);
                                                        setCart({
                                                            ...cart,
                                                            items: cart.items.map((prev: ObjectKeys) => ({
                                                                ...prev,
                                                                id: packages[index].id,
                                                                type: 'package',
                                                                price: packages[index].monthly,
                                                                months: packages[index].name === 'Basic' ? 1 : 6,
                                                            })),
                                                        });
                                                    }}
                                                >
                                                    {item.name}
                                                </Package>
                                            ))}
                                        </div>
                                        {packageError && (
                                            <span style={{ color: 'red', fontSize: '12px' }}>Paket je obavezan</span>
                                        )}
                                    </Packages>
                                    <p className="user-select">ODABERI PERIOD (MESECI):</p>
                                    <SelectComponent
                                        value={cart.items[0].months}
                                        key={renderKey}
                                        optionList={packageOptions}
                                        handleSelectedValue={(value: string) => {
                                            setCart({
                                                ...cart,
                                                items: cart.items.map((item: ObjectKeys) => ({
                                                    ...item,
                                                    months: value,
                                                })),
                                            });
                                        }}
                                    />
                                    <p className="user-select">CENA (RSD):</p>
                                    {cart.total_price ? (
                                        <p className="total-amount">
                                            {formatTwoDecimals((cart.total_price + _discount).toString())}
                                        </p>
                                    ) : (
                                        <p className="total-amount"></p>
                                    )}

                                    <p className="user-select">POPUST (RSD):</p>
                                    {_discount ? (
                                        <p className="total-amount">{formatTwoDecimals(_discount?.toString())}</p>
                                    ) : (
                                        <p className="total-amount"></p>
                                    )}
                                    <p className="user-select">UPLAĆENO (RSD):</p>
                                    {isLoading ? (
                                        <TotalAmountSkeleton />
                                    ) : (
                                        <>
                                            {leftOverAmount !== null && isUpgrade ? (
                                                <p className="total-amount">
                                                    {formatTwoDecimals(leftOverAmount.toString())}
                                                </p>
                                            ) : (
                                                <p className="total-amount">{formatTwoDecimals('0')}</p>
                                            )}
                                        </>
                                    )}
                                    <p className="user-select">UKUPAN IZNOS (RSD):</p>
                                    {isLoading ? (
                                        <TotalAmountSkeleton />
                                    ) : (
                                        <>
                                            {isUpgrade ? (
                                                <p className="total-amount">
                                                    {Math.round(cart.total_price - (leftOverAmount ?? 0)).toString()}
                                                </p>
                                            ) : (
                                                <p className="total-amount">
                                                    {Math.round(cart.total_price.toString())}
                                                </p>
                                            )}
                                        </>
                                    )}

                                    <Input
                                        type={InputComponentType.Date}
                                        label={'Datum transakcije:'}
                                        validation={showError ? isDateValid : ''}
                                        date={
                                            !newTransactionData.transaction_date
                                                ? null
                                                : moment(newTransactionData.transaction_date).toDate()
                                        }
                                        onChange={(value: Date | undefined | null) =>
                                            setNewTransactionData((prevState) => ({
                                                ...prevState,
                                                transaction_date: moment(value).format('YYYY-MM-DD').toString(),
                                            }))
                                        }
                                        maxDate={new Date()}
                                    />
                                    <div className="fiscal-div">
                                        <p className="user-select">Izdaj fiskalni racun:</p>
                                        <Checkbox
                                            defaultChecked={false}
                                            onChange={(e: boolean) => {
                                                setPrintFiscal(e);
                                            }}
                                            className={'limit-checkbox'}
                                        />
                                    </div>

                                    <div className={'button-wrapper'}>
                                        <Button
                                            variant={ButtonVariant.solid}
                                            color={colors.purple}
                                            onClick={() => {
                                                if (cart.total_price - (leftOverAmount ?? 0) <= 0) {
                                                    setNegativeAmountError(true);
                                                    return;
                                                } else if (cart.items[0].id.length === 0) {
                                                    setPackageError(true);
                                                    return;
                                                } else {
                                                    setConfirmSendModal(true);
                                                }
                                            }}
                                        >
                                            Dodaj Transakciju
                                        </Button>
                                    </div>
                                    {negativeAmountError ? <p className="error">Iznos mora biti veći od 0</p> : null}
                                    {printFiscal ? (
                                        <p className="error">
                                            Upozorenje: za kreiranu transakciju BIĆE izdat fiskalni račun.
                                        </p>
                                    ) : null}
                                </div>
                            ) : (
                                <p>Izaberi korisnika</p>
                            )}
                        </div>
                    </SidebarContent>
                </Sidebar>
            )}
        </>
    );
};

const Packages = styled.div`
    p {
        color: var(--gray);
        margin-top: 20px;
    }
    .packages {
        display: flex;
        gap: 5px;
        padding: 10px;
        justify-content: space-evenly;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            align-items: center;
        }
    }

    .pointerEvents {
        pointer-events: none;
    }
`;

const Package = styled.div<{ isSelected: boolean }>`
    width: 100%;
    max-width: 150px;
    display: flex;
    justify-content: center;
    border: 1px solid var(--purple);
    font-size: 20px;
    padding: 20px;
    border-radius: 15px;
    cursor: pointer;
    background-color: ${(props) => (props.isSelected ? 'var(--purple)' : 'white')};
    color: ${(props) => (props.isSelected ? 'white' : 'var(--purple)')};
`;

const SidebarContent = styled.div`
    .button-wrapper {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        button {
            border-radius: 100px;
        }
    }
    .fiscal-div {
        display: flex;
        gap: 10px;
        margin-top: 20px;
        p {
            color: var(--gray);
        }
    }
    .header {
        position: relative;
        margin-bottom: 20px;
        h1 {
            font-size: 24px;
            color: #4c595f;
            font-weight: 400;
            display: inline;
        }
        .close_button {
            position: absolute;
            top: 10px;
            right: 10px;
            display: block;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAfCAYAAACGVs+MAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAs1JREFUeNq0l81LVFEYxu+cJkkTG5hFIOmmFi6iRS6EKIoGYygbK/qQiMQ25UJ3NdBfENmmJCJa9EH0JSXaB1NiJBLVIok2FbQIowIhsJJRxsqeF56By3DeM/c6txd+szjv13Mu9555T+zlmw8Jz/POg13gGzgL+r3/Yz0gC5aDB6A3jp+LYD8DVoNzoAGciLj5KTYv2iFQZfDTZgk+TiGxCBrHWCtr8WVEwCfH47pQoYgYa/Qo/s8i4CT4owQcBVfAkkU0l5zLrGEz6ZkVAUPgIJhXAg+D6yAeonmcOZ2Kf549Bw0X7oC9oKAkdIDb8tIEaF7F2A7FLz32sadnfI5h0A5mlcQ94B5Y5mguvruMtdksewwVF0xJQA7sBHmlwA4KrbH4auhrU3LzrJ3zLxpL4ChIg59KoVbwCNT61mq51qrk/GLN0VKHURLGwTYwrfg3g8dgBclxzWbTFDauva2avQIp8AQkLf4Nvh01KzW+cyMTWhNT5o2WxK1gSvE3O5pPMXfC1cAE+Kze8vF+DXEOSOwW5nqVChB7TxGTAWInGfsuSGETYlcfeSqWs07GelELWAOuBYi7ythIBTSBMdAYILaRsU1RCVgLnoH6EE+rnjnrKhWwnoVWKv7XxGaS85Q1FiWghQdNUvG/4EElPFdikqzRElbARp6ACcU/xhPuB0lzzWYJ1toUVECKZ3udUnAEbAczvrUZro0oOXWsmSonQHZyn2OzzeQfL6P8Xefpe6jk1rB2WhOQ4aBQrRQYBLvBnOO9mfMNLjarZo/2UgFyLxhwjFy3GFMI8AlKzAHmaCPbQPEuEqeaG47JV06/I47J2Wa/efEoKMf3UvYsyBM442h+CXSFbO4fu7t489LG9tMiYJUS0M+Z/m8FFxPJ7XbcNRsM38xS65OLI1iI4Gq2wFp9Ft+w4S5v8jP6QsVRX0w91uxmjzx7HvsnwADMMpd5tpG+eAAAAABJRU5ErkJggg==)
                no-repeat;
            background-size: 16px 16px;
            width: 16px;
            height: 16px;
            text-indent: -9999px;
            border: 0;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .choose-user {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .user-select {
        font-size: 16px;
        color: var(--gray);
    }
    .total-amount {
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 10px;
        border: 1px solid var(--border-color);
        border-radius: 5px;
        padding: 10px;
        height: 40px;
    }
    .content {
        .select-search {
            margin-bottom: 20px;
        }
        .select-wrapper {
            width: 100%;
            label {
                font-size: 15px;
                color: var(--gray);
                padding-left: 0;
            }
            .select__control {
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
        .search-wrapper {
            margin-bottom: 5px;
            margin-top: 5px;
        }

        .options-list {
            z-index: 1;
            top: 9em;
        }
    }
    .selected-user {
        display: block;
        margin-top: 15px;
        margin-bottom: 20px;
        font-size: 15px;
        color: var(--gray);
        .delete-selected-user {
            float: right;
            cursor: pointer;
        }
    }
    .warning {
        color: red;
        font-size: 12px;
        margin-top: 10px;
    }
    .error {
        color: red;
        font-size: 14px;
        margin-top: 10px;
        text-align: center;
    }
`;
const loadingAnimation = keyframes`
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: calc(200px + 100%) 0;
    }
`;

const TotalAmountSkeleton = styled.div`
    width: 100%;
    height: 1.2em;
    background: linear-gradient(90deg, #e6dfff 25%, #cebcff 50%, #b6a0ff 75%);
    background-size: 200% 100%;
    animation: ${loadingAnimation} 1.5s infinite;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 10px;
`;

export default NewTransaction;
