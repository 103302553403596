import { FunctionComponent, useEffect, useState, SetStateAction, Dispatch } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faFloppyDisk, faCircleQuestion, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import colors from '../../../../global/colors';
import communication from '../../../../communication';
import useLanguageStore from '../../../../store/language';
import useCurrencyStore from '../../../../store/currency';
import useTranslations from '../../../../hooks/useTranslation';
import { ObjectKeys } from '../../../../types/objectKeys';
import Input, { InputComponentType } from '../../../../components/input';
import logo from '../../../../assets/pausal-logo-gray.png';
import ServiceModal from '../../common/serviceModal';
import TextArea from '../../../../components/input/textarea';
import { generateQrCode } from '../../../../communication/qr';
import Tooltip from '../../../../components/tooltipMain';
import useUnitsStore from '../../../../store/measurementUnits';
import { getUnitByValue } from '../../common/measurementUnits';

import {
    Container,
    Header,
    AddServiceCurrency,
    AddService,
    AddServiceButton,
    Agency,
    Comments,
    Footer,
    Row,
    Total,
    Wrapper,
    ForeignInputs,
    MemoSelect,
    GroupedMemoSection,
    MemoDisplaySection,
} from './style';
import WebService from '../../common/service/webService';
import MobileService from '../../common/service/mobileService';
import SelectComponent, { SelectOption } from '../../../../components/select';
import { getAllMeasurementUnits } from '../../common/measurementUnits';
import countTotal from '../../common/countTotal';
import Modal from '../../../../components/modal';
import ErrorModal from '../../../../components/errorModal';
import { formatTwoDecimals } from '../../../../functions/format';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../../../components/sideModal';
import Loader from '../../../../components/loader';
import AddArticleModal from '../../common/addArticleModal';
import useAllBanksQuery from '../../../../react-query/useAllBanksQuery';
import { useMemosQuery } from '../../../../react-query/useMemosQuery';
import SelectSearch from '../../../../components/selectSearch';

interface Props {
    invoice: ObjectKeys;
    agency: ObjectKeys;
    formData: ObjectKeys;
    setFormData: Function;
    handleTotal: Function;
    isMobile: boolean;
    newInvoice?: boolean;
    type?: string;
    name?: string;
    setLoading: Dispatch<SetStateAction<boolean>>;
    // qrCode?: string;
    userId?: string;
    handleQrCode: Function;
    handleTotalAdvance?: Function;
}

const InvoiceEdit: FunctionComponent<Props> = ({
    invoice,
    agency,
    formData,
    setFormData,
    handleTotal,
    isMobile,
    type,
    name,
    setLoading,
    userId,
    handleQrCode,
}) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const isAdvance = pathname.includes('advance');
    const isCopy = pathname.includes('copy');
    const { currentLang, currentLangName } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [measurementUnits, setMeasurementUnits] = useState<Array<{ value: string; label: string }>>([]);
    const { units } = useUnitsStore();
    const { currency } = useCurrencyStore();
    const tooltipTradingPlace = t('pages.editInvoice.tradingPlaceTooltip').text;
    const [clickedField, setClickedField] = useState('');
    const [changeHappened, _setChangeHappened] = useState(false);
    const [services, setServices] = useState<ObjectKeys[]>([]);
    const [currencies, setCurrencies] = useState<SelectOption[]>([]);
    const [modal, setModal] = useState(false);
    const [modalMessage, _setModalMessage] = useState('');

    const { data } = useAllBanksQuery();

    const [totalAmount, setTotalAmount] = useState<string>('0');
    const [qr_code, setQR_code] = useState<string>('');

    const [selectedType, setSelectedType] = useState<'service' | 'product'>('service');
    const [isAddServiceModalOpen, setIsAddServiceModalOpen] = useState(false);
    const [isAddArticleModalOpen, setIsAddArticleModalOpen] = useState(false);

    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);

    const [isServiceUpdating, setIsServiceUpdating] = useState<boolean>(false);
    const [updatingServiceId, setUpdatingServiceId] = useState<string | null>(null);
    const [updatingServiceIndex, setUpdatingServiceIndex] = useState<number | null>(null);

    const [totalWithoutRabat, setTotalWithoutRabat] = useState<number | null>(null);
    const [rabat, setRabat] = useState<number | null>(null);

    const [replaceArticle, setReplaceArticle] = useState<string | null>(null);

    const isAdmin = localStorage.getItem('role') === 'admin' ? true : false;
    const has_logo_on_invoice = isAdmin ? true : agency.user.data.has_logo;

    const { memos } = useMemosQuery();
    const [memoForSelect, setMemoForSelect] = useState([]);

    const [params] = useState({
        page: 1,
        limit: '5000',
        search: `user_id:${localStorage.getItem('role') === 'admin' ? userId : agency?.user?.data?.id}`,
        searchFields: 'user_id:=;is_foreign:=;',
        searchJoin: 'and',
    });

    //get services and sort them by created_at date
    const getServices = async (): Promise<ObjectKeys> => {
        const res = await communication.getAllArticles(params);
        const newUnits = res?.data.data
            .map((item: ObjectKeys) => item.default_measurement_unit)
            .filter((unit: any) => !units.includes(unit));

        useUnitsStore.setState({ units: [...units, ...newUnits] });
        const servicesData = res.data.data;
        //order services by created_at date
        const orderedServices = servicesData.sort((a: { created_at: string }, b: { created_at: string }) => {
            const dateA = new Date(a.created_at).getTime();
            const dateB = new Date(b.created_at).getTime();
            return dateB - dateA;
        });

        setServices(orderedServices || []);

        return orderedServices;
    };

    //trigger services refetch
    const triggerServicesRefetch = async (): Promise<ObjectKeys> => {
        const orderedServices = await getServices();
        return orderedServices;
    };

    const calculateTotalAmountWithoutDiscounts = (items: ObjectKeys): void => {
        if (items && items.length > 0) {
            const totalResult = items.reduce(
                (accumulator: ObjectKeys, service: ObjectKeys) => {
                    const { pivot } = service;
                    if (pivot) {
                        const quantity = pivot.quantity ? Number(pivot.quantity.replace(',', '.')) : 0;
                        const pricePerUnit = pivot.price_per_unit ? Number(pivot.price_per_unit.replace(',', '.')) : 0;
                        const discount = pivot.discount ? Number(pivot.discount) : 0;
                        const amountWithoutDiscount = quantity * pricePerUnit;
                        const discountAmount = quantity * pricePerUnit * (discount / 100);

                        accumulator.totalWithoutDiscount += amountWithoutDiscount;
                        accumulator.totalDiscount += discountAmount;
                    }
                    return accumulator;
                },
                { totalWithoutDiscount: 0, totalDiscount: 0 },
            );

            setTotalWithoutRabat(totalResult.totalWithoutDiscount);
            setRabat(totalResult.totalDiscount);
            setFormData({
                ...formData,
                value_without_rabat: totalResult.totalWithoutDiscount,
                value_in_rsd_without_rabat: totalResult.totalWithoutDiscount,
            });
        } else {
            setTotalWithoutRabat(0);
            setRabat(0);
        }
    };

    useEffect(() => {
        calculateTotalAmountWithoutDiscounts(invoice.services);
    }, [invoice.value]);

    useEffect(() => {
        calculateTotalAmountWithoutDiscounts(formData.services);
    }, [formData.services?.length]);

    const convertedPrice = (service: ObjectKeys): string => {
        if (!service.default_price) {
            return '';
        }
        if (service.default_currency === 'RSD') {
            return service.default_price.toString();
        }
        const matchingCurrency = currency.find(
            (cur) => cur.currency.toLowerCase() === service.default_currency?.toLowerCase(),
        );
        if (matchingCurrency) {
            const converted = matchingCurrency.sre * service.default_price;
            const roundedConverted = Math.round(converted * 10000) / 10000;
            return roundedConverted.toFixed(4);
        }
        return '';
    };

    //transform service data for invoice services property
    const transformServiceForInvoice = (service: ObjectKeys): ObjectKeys | null => {
        if (service) {
            return {
                ...service,
                pivot: {
                    index: (formData.services?.length || 0) + 1,
                    measurement_unit: service.default_measurement_unit || '',
                    price_per_unit: convertedPrice(service),
                    quantity: service.default_quantity || '',
                    discount: '0',
                },
            };
        }
        return null;
    };

    //after refetching add last added service to the invoice
    const triggerRefetchAddLastAddedService = async (): Promise<void> => {
        setLoaderVisible(true);

        const orderedServices = await triggerServicesRefetch();

        if (replaceArticle) {
            setFormData((prev: ObjectKeys) => {
                const addedServices = prev.services || [];
                const newAddedService = transformServiceForInvoice(orderedServices[0] || null);

                const updatedServices = addedServices.map((service: ObjectKeys) =>
                    service.id === replaceArticle ? newAddedService : service,
                );

                return {
                    ...prev,
                    services: updatedServices,
                };
            });
            setReplaceArticle(null);
            setLoaderVisible(false);
        } else {
            setFormData((prev: ObjectKeys) => {
                const addedServices = prev.services || [];
                const newAddedService = transformServiceForInvoice(orderedServices[0] || null);
                if (newAddedService) {
                    return {
                        ...prev,
                        services: [...addedServices, newAddedService],
                    };
                } else {
                    return prev;
                }
            });
            setLoaderVisible(false);
        }
    };

    //add selected service to invoice
    const handleAddService = (service_id: string): void => {
        const selectedService = services.find((service) => service.id === service_id);

        if (selectedService) {
            setFormData((prev: ObjectKeys) => {
                const addedServices = prev.services || [];
                const newAddedService = transformServiceForInvoice(selectedService);
                setIsAddServiceModalOpen(false);

                if (newAddedService) {
                    return {
                        ...prev,
                        services: [...addedServices, newAddedService],
                    };
                } else {
                    return prev;
                }
            });
        }
    };

    //replace selected service with new one selected service to invoice
    const handleUpdateService = (index: number, service_id: string): void => {
        const selectedService = services.find((service) => service.id === service_id);

        if (selectedService) {
            setFormData((prev: ObjectKeys) => {
                const addedServices = prev.services || [];
                const newAddedService = transformServiceForInvoice(selectedService);
                setIsAddServiceModalOpen(false);

                addedServices[index] = newAddedService;

                if (newAddedService) {
                    return {
                        ...prev,
                        services: addedServices,
                    };
                } else {
                    return prev;
                }
            });
        }

        setIsServiceUpdating(false);
        setUpdatingServiceIndex(null);
        setUpdatingServiceId(null);
        setIsAddServiceModalOpen(false);
    };

    //after deleting a service remove it from the invoice
    const handleDeleteService = (service_id: string): void => {
        setFormData((prev: ObjectKeys) => {
            const addedServices = prev.services || [];
            return {
                ...prev,
                services: addedServices.filter((service: ObjectKeys) => service.id !== service_id),
            };
        });
    };

    const handleServiceClick = (service_index: number, service_id: string): void => {
        setIsServiceUpdating(true);
        setUpdatingServiceIndex(service_index);
        setUpdatingServiceId(service_id);
        setIsAddServiceModalOpen(true);
        setReplaceArticle(service_id);
    };

    const handleValidation = (inputName: string): void => {
        setClickedField(inputName);
    };

    useEffect(() => {
        const currencyData = currency?.map((curr) => {
            return { value: curr.currency.toUpperCase(), label: curr.currency };
        });

        setCurrencies([...currencyData]);
    }, [currentLang]);

    //set measurement units
    useEffect(() => {
        getServices();
        const unitMeasurementData = getAllMeasurementUnits(t, 'domestic');
        setMeasurementUnits(unitMeasurementData as { value: string; label: string }[]);
    }, []);

    useEffect(() => {
        setFormData(invoice);

        async function getData(): Promise<void> {
            const res = await communication.getAllArticles(params);
            setServices(res?.data?.data);
        }

        getData();
    }, [changeHappened]);

    useEffect(() => {
        if (invoice.services) {
            setTotalAmount(countTotal(invoice.services));
        }
    }, [invoice]);

    useEffect(() => {
        if (!isAdvance && type === 'domestic' && invoice.value_in_rsd) {
            try {
                generateQrCode(
                    agency.name,
                    invoice.client.data.company_name,
                    totalAmount ? totalAmount : invoice.value_in_rsd,
                    invoice.quotation_number || null,
                    invoice.bank_account,
                    invoice.currency,
                    true,
                    invoice?.quotation_number ? `00${invoice?.quotation_number?.replace('/', '-')}` : null,
                    '221',
                )
                    .then((response: ObjectKeys | any) => {
                        if (response.i) {
                            setQR_code(response.i);
                            handleQrCode(response.i);
                        } else {
                            setQR_code('');
                            handleQrCode('');
                        }
                    })
                    .catch((error: any) => {
                        setQR_code('');
                        handleQrCode('');
                        if (error) {
                            console.error(error);
                        }
                    });
            } catch (e: any) {
                setQR_code('');
                handleQrCode('');
                console.error(e);
            }
        }
    }, [invoice.client.data, invoice.bank_account, totalAmount]);

    const handleSaveAgencyData = (): void => {
        const dataToSend = { ...agency, iban: formData.iban, swift: formData.swift };
        setLoading(true);
        setClickedField('');
        communication
            .updateAgency(agency.id, dataToSend)
            .then((res: ObjectKeys) => {
                if (res) {
                    setLoading(false);
                    const agencyRes = res.data.data;
                    setFormData({ ...formData, iban: agencyRes.iban, swift: agencyRes.swift });
                }
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setLoading(false);
                    toast.error(t('pages.tryAgain.message').text);
                }
            });
    };

    const checkBankAccount = (account: string): string => {
        const ibanRegex = /^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/;
        const isIban = ibanRegex.test(account);
        let bankCode = '';
        if (isIban) {
            bankCode = account.slice(4, 7);
        } else {
            bankCode = account.slice(0, 3);
        }
        const bank = data?.find((banka: ObjectKeys) => banka.code === bankCode);
        return bank ? bank.name : 'Bank not found';
    };

    const accountOptions = [
        ...(agency.bankAccounts?.data?.length > 0
            ? agency.bankAccounts.data
                  .filter((account: ObjectKeys) => account.type === 'domestic')
                  .map((account: ObjectKeys) => ({
                      value: account,
                      // eslint-disable-next-line react-hooks/rules-of-hooks
                      label: `${checkBankAccount(account.account_number ? account.account_number : '')} ${
                          account.account_number
                      }`,
                  }))
            : []),
        {
            value: 'add_account',
            label: t('pages.invoices.add_account').text,
        },
    ];

    useEffect(() => {
        setMeasurementUnits(() => {
            const updatedMeasurementUnits: { value: string; label: string }[] = [];
            units.forEach((item) => {
                const label = getUnitByValue(item, false, false);
                if (label !== null) {
                    updatedMeasurementUnits.push({ value: item, label: label });
                }
            });
            return updatedMeasurementUnits;
        });
    }, [units]);

    useEffect(() => {
        if (memos) {
            setMemoForSelect(
                memos.map((memo: ObjectKeys) => {
                    return {
                        value: memo.id,
                        label: memo.heading,
                    };
                }),
            );
        }
    }, [memos]);

    return (
        <>
            {loaderVisible && <Loader />}
            {modal && (
                <Modal modalVisible={true} closeModal={() => setModal(false)}>
                    <ErrorModal t={t} setOpenModal={() => setModal(false)} errorMessage={modalMessage} />
                </Modal>
            )}
            <ToastContainer />
            {formData && invoice && (
                <Wrapper>
                    {isAddServiceModalOpen && (
                        <ServiceModal
                            t={t}
                            services={services}
                            refetchServices={triggerServicesRefetch}
                            setArticleType={setSelectedType}
                            addArticleToInvoice={handleAddService}
                            updateInvoiceArticle={handleUpdateService}
                            deleteInvoiceArticle={handleDeleteService}
                            isUpdating={isServiceUpdating}
                            updatingId={updatingServiceId}
                            updatingIndex={updatingServiceIndex}
                            closeModal={() => setIsAddServiceModalOpen(false)}
                            openAddArticleModal={() => {
                                setIsAddArticleModalOpen(true);
                            }}
                        />
                    )}
                    {isAddArticleModalOpen && (
                        <Sidebar close={() => setIsAddArticleModalOpen(false)}>
                            <AddArticleModal
                                t={t}
                                setEditClientModal={setIsAddArticleModalOpen}
                                success={() => {
                                    setIsAddArticleModalOpen(false);
                                }}
                                type={selectedType}
                                measurementUnits={measurementUnits}
                                triggerServicesRefetch={triggerRefetchAddLastAddedService}
                            />
                        </Sidebar>
                    )}
                    {invoice && agency && (
                        <Container>
                            <Header>
                                <Row className="inv-no">
                                    <h4>
                                        <div>
                                            {name === 'invoice'
                                                ? t('pages.invoices.addInvoiceDropdown.invoice').text
                                                : name === 'proforma'
                                                ? t('pages.proforma.addProformaDropdown.proforma').text
                                                : t('pages.advanceInvoices.addInvoiceDropdown.aInvoice').text}
                                        </div>
                                    </h4>
                                    <h3>
                                        {isCopy
                                            ? t('pages.newInvoice.draft').text
                                            : invoice.quotation_number
                                            ? invoice.quotation_number
                                            : t('pages.newInvoice.draft').text}
                                    </h3>
                                </Row>
                                <Row className="invoice-dates-text">
                                    <div
                                        style={{
                                            position: 'relative',
                                            display: 'inline-block',
                                        }}
                                    >
                                        <label className="trading-place-tooltip">
                                            {t('pages.editInvoice.previewModal.tradingPlace').text}
                                            <Tooltip
                                                text={tooltipTradingPlace}
                                                isHtml={true}
                                                position={'custom'}
                                                customPositioning={{
                                                    bottom: '80%',
                                                    left: '50%',
                                                    transform: 'translateX(-50%)',
                                                }}
                                                label={
                                                    <FontAwesomeIcon
                                                        icon={faCircleQuestion}
                                                        fontSize={'13px'}
                                                        style={{ marginBottom: '0.05rem', marginLeft: '0.5rem' }}
                                                        color={colors.purple}
                                                    />
                                                }
                                            />
                                        </label>
                                        <Input
                                            type={InputComponentType.Text}
                                            value={invoice.trading_place ? invoice.trading_place : ''}
                                            onChange={(value: string) =>
                                                setFormData({ ...formData, trading_place: value })
                                            }
                                            blurHandler={() => handleValidation('trading_place')}
                                        />
                                    </div>
                                    <Input
                                        type={InputComponentType.Date}
                                        label={
                                            name === 'invoice'
                                                ? t('pages.editInvoice.previewModal.invoiceDate').text
                                                : name === 'proforma'
                                                ? t('pages.editProforma.proformaDate').text
                                                : t('pages.editAdvanceInvoice.advanceInvoice').text
                                        }
                                        date={
                                            formData?.invoice_date
                                                ? moment(formData?.invoice_date).toDate()
                                                : formData?.quotation_date
                                                ? moment(formData?.quotation_date).toDate()
                                                : new Date()
                                        }
                                        onChange={(value: string) => {
                                            setFormData({
                                                ...formData,
                                                quotation_date: moment(value).format('YYYY-MM-DD').toString(),
                                            });
                                        }}
                                        className="date-input-class"
                                    />
                                    <Input
                                        type={InputComponentType.Date}
                                        label={t('pages.editInvoice.previewModal.valueDate').text}
                                        date={
                                            formData?.trading_date
                                                ? moment(formData?.trading_date).toDate()
                                                : new Date()
                                        }
                                        onChange={(value: string) => {
                                            setFormData({
                                                ...formData,
                                                trading_date: moment(value).format('YYYY-MM-DD').toString(),
                                            });
                                        }}
                                    />
                                </Row>
                            </Header>
                            {type === 'foreign' && (
                                <ForeignInputs>
                                    <Row>
                                        <div>
                                            <Input
                                                type={InputComponentType.Text}
                                                value={invoice.iban ? invoice.iban : ''}
                                                label={t('pages.agency.profile.iban').text}
                                                onChange={(value: string) => setFormData({ ...formData, iban: value })}
                                                blurHandler={() => handleValidation('foreign-inputs')}
                                            />
                                            <Input
                                                type={InputComponentType.Text}
                                                value={invoice.swift ? invoice.swift : ''}
                                                label={t('pages.agency.profile.swift').text}
                                                onChange={(value: string) => setFormData({ ...formData, swift: value })}
                                                blurHandler={() => handleValidation('foreign-inputs')}
                                            />
                                            <FontAwesomeIcon
                                                onClick={() => handleSaveAgencyData()}
                                                icon={faFloppyDisk}
                                                style={{ fontSize: '30px', paddingLeft: '15px' }}
                                                color={colors.purple}
                                                className="cursor-pointer"
                                            />
                                        </div>
                                        {clickedField === 'foreign-inputs' ? (
                                            <p className="validation-message">
                                                {t('pages.newInvoice.ibanSwiftValidation').text}
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                    </Row>
                                </ForeignInputs>
                            )}
                            {agency && (
                                <Agency>
                                    <div>
                                        <h3>{agency.name}</h3>
                                        <p>{agency.full_name}</p>
                                        <p>{agency.street_address + '\xa0' + agency.street_number}</p>
                                        <p>{agency.city}</p>
                                        <p>{t('pages.editInvoice.previewModal.vatNo').text + '\xa0' + agency.pib}</p>
                                        <p>
                                            {t('pages.editInvoice.previewModal.mb').text +
                                                '\xa0' +
                                                agency.identification_number}
                                        </p>
                                        <p>
                                            {t('pages.editInvoice.previewModal.email').text}{' '}
                                            <a href={`mailto:${agency?.agency_email}`}>{agency?.agency_email}</a>
                                        </p>
                                        {accountOptions.length > 0 && (
                                            <Row className="accounts">
                                                {t('pages.editInvoice.previewModal.bank').text}
                                                <SelectComponent
                                                    optionList={accountOptions}
                                                    defaultSelectedOption={
                                                        invoice.bank_account
                                                            ? {
                                                                  value: invoice.bank_account,
                                                                  // eslint-disable-next-line react-hooks/rules-of-hooks
                                                                  label: `${checkBankAccount(invoice.bank_account)} ${
                                                                      invoice.bank_account
                                                                  }`,
                                                              }
                                                            : accountOptions[0]
                                                    }
                                                    handleSelectedValue={(value: any) => {
                                                        if (value === 'add_account') {
                                                            navigate('/agency/bank-accounts?=domestic');
                                                        } else {
                                                            setFormData({
                                                                ...formData,
                                                                bank_account: value.account_number,
                                                                bank_account_id: value.id,
                                                                bank_id: value.bank
                                                                    ? value.bank.data.id
                                                                    : value.bank_id,
                                                            });
                                                        }
                                                    }}
                                                />
                                            </Row>
                                        )}
                                    </div>
                                    <div>
                                        <h3>{t('pages.editInvoice.previewModal.client').text}</h3>
                                        <p>
                                            {t('pages.editInvoice.previewModal.recipient').text +
                                                '\xa0' +
                                                (invoice?.client?.data?.company_name
                                                    ? invoice?.client?.data?.company_name
                                                    : '')}
                                        </p>
                                        {invoice.client?.data?.pib && (
                                            <p>
                                                {t('pages.editInvoice.previewModal.pib').text +
                                                    '\xa0' +
                                                    invoice.client.data.pib}
                                            </p>
                                        )}

                                        {invoice.client?.data?.jmbg && (
                                            <p>
                                                {t('pages.editInvoice.previewModal.jmbg').text +
                                                    '\xa0' +
                                                    invoice.client.data.jmbg}
                                            </p>
                                        )}
                                        <p>
                                            {invoice?.client?.data?.identification_number ? (
                                                t('pages.editInvoice.previewModal.mb').text +
                                                '\xa0' +
                                                (invoice?.client?.data?.identification_number
                                                    ? invoice?.client?.data?.identification_number
                                                    : '')
                                            ) : (
                                                <></>
                                            )}
                                        </p>
                                        <p>
                                            {type !== 'foreign' && invoice?.client?.data?.public_company_id ? (
                                                t('pages.editInvoice.previewModal.jbkjs').text +
                                                (invoice?.client?.data?.public_company_id
                                                    ? invoice?.client?.data?.public_company_id
                                                    : '')
                                            ) : (
                                                <></>
                                            )}
                                        </p>
                                        <p>
                                            {t('pages.editInvoice.previewModal.city').text +
                                                '\xa0' +
                                                (invoice.client?.data?.city ? invoice.client?.data?.city : '')}
                                        </p>
                                        <p>
                                            {t('pages.editInvoice.previewModal.address').text +
                                                '\xa0' +
                                                (invoice.client?.data?.address ? invoice.client?.data?.address : '')}
                                        </p>
                                        {type === 'foreign' ? (
                                            <p>
                                                {t('pages.editInvoice.country').text +
                                                    ' : ' +
                                                    (invoice?.client?.data?.country
                                                        ? invoice?.client?.data?.country
                                                        : '')}
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </Agency>
                            )}
                            {!isMobile ? (
                                <Row className="services">
                                    <p className="type">{t('pages.editInvoice.previewModal.item').text}</p>
                                    <p className="unit">{t('pages.editInvoice.previewModal.unit').text}</p>
                                    <p className="price">{t('pages.editInvoice.previewModal.amount').text}</p>
                                    <p className="price">{t('pages.editInvoice.previewModal.unitPrice').text}</p>
                                    <p className="price">{t('pages.editInvoice.previewModal.discount').text} %</p>
                                    <p className="total">{t('pages.editInvoice.previewModal.total').text}</p>
                                </Row>
                            ) : (
                                <h3 className="services-title">
                                    {t('pages.editInvoice.addServiceModal.services').text}
                                </h3>
                            )}
                            {invoice?.services && invoice?.services?.length > 0 ? (
                                invoice?.services.map((service: ObjectKeys, index: number) =>
                                    isMobile ? (
                                        <MobileService
                                            key={`${service.id} ${index}`}
                                            t={t}
                                            index={index}
                                            handleTotal={handleTotal}
                                            invoice={invoice}
                                            services={services}
                                            setFormData={setFormData}
                                            units={measurementUnits}
                                            service={service}
                                            handleServiceClick={() => handleServiceClick(index, service.id)}
                                        />
                                    ) : (
                                        <WebService
                                            key={`${service.id} ${index}`}
                                            index={index}
                                            handleTotal={handleTotal}
                                            invoice={invoice}
                                            services={services}
                                            setFormData={setFormData}
                                            units={measurementUnits}
                                            t={t}
                                            service={service}
                                            handleServiceClick={() => handleServiceClick(index, service.id)}
                                        />
                                    ),
                                )
                            ) : (
                                <>
                                    <AddServiceButton onClick={() => setIsAddServiceModalOpen(true)}>
                                        <div className="plus">
                                            <FontAwesomeIcon icon={faPlus} />
                                        </div>
                                        <p>{t('pages.editInvoice.addService').text}</p>
                                    </AddServiceButton>
                                </>
                            )}
                            <AddServiceCurrency>
                                {invoice?.services && invoice?.services?.length > 0 ? (
                                    <AddService onClick={() => setIsAddServiceModalOpen(true)}>
                                        <FontAwesomeIcon icon={faPlus} />
                                        <p>{t('pages.editInvoice.addService').text}</p>
                                    </AddService>
                                ) : null}
                                {type === 'foreign' && currencies && (
                                    <SelectComponent
                                        className="currency-select"
                                        label={t('pages.clients.modal.form.foreign.currency').text}
                                        handleSelectedValue={(value: string) => {
                                            setFormData({ ...invoice, currency: value });
                                        }}
                                        optionList={currencies}
                                        defaultSelectedOption={{ value: invoice.currency, label: invoice.currency }}
                                    />
                                )}
                            </AddServiceCurrency>
                            <Total>
                                {invoice && totalWithoutRabat ? (
                                    <>
                                        <p>Ukupno ({invoice.currency})</p>
                                        <h3>{formatTwoDecimals(totalWithoutRabat.toString())}</h3>
                                    </>
                                ) : null}
                                {invoice && totalWithoutRabat && rabat !== null ? (
                                    <>
                                        <p>Rabat ({invoice.currency})</p>
                                        <h3>{formatTwoDecimals(rabat.toString())}</h3>
                                    </>
                                ) : null}

                                <>
                                    <p>
                                        {t('pages.editInvoice.previewModal.totalPayment').text} ({invoice.currency})
                                    </p>
                                    <h3>{formatTwoDecimals(totalAmount)}</h3>
                                </>
                            </Total>

                            <Row>
                                <Comments>
                                    <p>{t('pages.editInvoice.previewModal.comment').text}</p>
                                    <TextArea
                                        value={formData.comment ? formData.comment : ''}
                                        onChange={(value: string) => setFormData({ ...formData, comment: value })}
                                        placeholder={t('pages.editInvoice.previewModal.textAreaPlaceholder').text}
                                        maxLength={1000}
                                    />
                                </Comments>
                                <Comments>
                                    <p style={{ marginBottom: '20px' }}>
                                        {currentLangName !== 'English'
                                            ? name === 'invoice'
                                                ? t('pages.editInvoice.previewModal.invoiceDocumentValid').text
                                                : name === 'proforma'
                                                ? t('pages.editInvoice.previewModal.proformaDocumentValid').text
                                                : t('pages.editInvoice.previewModal.advanceInvoiceDocumentValid').text
                                            : t('pages.editInvoice.previewModal.documentValid').text}
                                    </p>
                                    <p style={{ marginBottom: '20px', wordBreak: 'break-all' }}>
                                        {t('pages.editInvoice.previewModal.id').text}
                                        {agency.signature}
                                    </p>
                                    <p>{t('pages.editInvoice.previewModal.note').text}</p>
                                    {!isAdvance && <p>{t('pages.editInvoice.previewModal.note1').text}</p>}
                                    <p>
                                        {name === 'invoice'
                                            ? t('pages.editInvoice.previewModal.invoiceNote2').text
                                            : name === 'proforma'
                                            ? t('pages.editInvoice.previewModal.proformaNote2').text
                                            : t('pages.editInvoice.previewModal.advanceInvoiceNote2').text}
                                    </p>
                                    <p>
                                        {t('pages.editInvoice.previewModal.place').text} {agency.city}
                                    </p>
                                </Comments>
                            </Row>

                            {!isAdvance && qr_code && (
                                <img
                                    width={'200px'}
                                    height={'200px'}
                                    data-v-f036a56e=""
                                    data-v-251216d5=""
                                    src={`data:image/jpeg;base64,${qr_code}`}
                                    data-v-52b191c5=""
                                    key={qr_code}
                                ></img>
                            )}

                            <GroupedMemoSection>
                                <MemoDisplaySection>
                                    <div>
                                        {formData?.memorandums?.length > 0 && (
                                            <div className="selected-memos">
                                                {formData.memorandums.map((memo: string) => {
                                                    const selectedMemo = memos.find((m: ObjectKeys) => m.id === memo);
                                                    return (
                                                        <div key={memo} className="selected-memo">
                                                            <span>{selectedMemo?.heading}</span>
                                                            <FontAwesomeIcon
                                                                icon={faTrashCan}
                                                                style={{ color: colors.danger }}
                                                                onClick={() => {
                                                                    setFormData((prevFormData: ObjectKeys) => {
                                                                        return {
                                                                            ...prevFormData,
                                                                            memorandums:
                                                                                prevFormData.memorandums?.filter(
                                                                                    (m: string) => m !== memo,
                                                                                ),
                                                                        };
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </MemoDisplaySection>
                                <MemoSelect>
                                    <div className="select-memos">
                                        <h3>
                                            {memoForSelect.length > 0
                                                ? t('pages.editInvoice.previewModal.addedMemorandums').text
                                                : t('pages.editInvoice.previewModal.createFirstMemoNote').text}
                                        </h3>
                                        {memoForSelect.length > 0 ? (
                                            <>
                                                <SelectSearch
                                                    optionList={memoForSelect}
                                                    placeholder={t('pages.memos.chooseMemo').text}
                                                    handleSelectedValue={(option: { label: string; value: string }) => {
                                                        setFormData((prevFormData: ObjectKeys) => {
                                                            const existingMemorandums = prevFormData.memorandums || []; // Ensure it's an array
                                                            const updatedMemorandums = existingMemorandums?.includes(
                                                                option.value,
                                                            )
                                                                ? existingMemorandums // If already exists, keep it as is
                                                                : [...existingMemorandums, option.value]; // Add new value

                                                            return {
                                                                ...prevFormData,
                                                                memorandums: updatedMemorandums,
                                                            };
                                                        });
                                                    }}
                                                    maxHeight={170}
                                                    disabled={formData?.memorandums?.length >= 3}
                                                />
                                                {formData?.memorandums?.length >= 3 ? (
                                                    <p className="validation-message">
                                                        {t('pages.editInvoice.previewModal.createMemoLimitation').text}
                                                    </p>
                                                ) : null}
                                            </>
                                        ) : (
                                            <p>{t('pages.editInvoice.previewModal.noCreatedMemos').text}</p>
                                        )}
                                    </div>
                                </MemoSelect>
                            </GroupedMemoSection>

                            {has_logo_on_invoice ? (
                                <Footer>
                                    <p>
                                        {name === 'proforma'
                                            ? t('pages.editInvoice.previewModal.footerProforma').text
                                            : t('pages.editInvoice.previewModal.footerInvoice').text}
                                        <a href="http://www.pausal.rs" target="_blank" rel="noreferrer">
                                            pausal.rs
                                        </a>
                                    </p>
                                    <img src={logo} height={'15px'} />
                                </Footer>
                            ) : null}
                        </Container>
                    )}
                </Wrapper>
            )}
        </>
    );
};

export default InvoiceEdit;
