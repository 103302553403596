import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import PackageCard from './packageCard';
import communication from '../../../communication';
import { ObjectKeys } from '@/types/objectKeys';
import Loader from '../../../components/loader';
import useAgencyStore from '../../../store/agency';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import { useNavigate } from 'react-router-dom';
import { StepControlButton } from '../../../components/stepControlButton';
import useServicesCartStore from '../../../store/servicesCart';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';

interface StepTwoProps {
    cart: ObjectKeys;
    nextStep: Function;
    previousStep: Function;
    finallCart: Function;
}

const StepTwo: React.FC<StepTwoProps> = ({ cart, nextStep, previousStep, finallCart }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { agency } = useAgencyStore();
    const isActiveAccount = agency.user.data.active;
    const userPackage = agency.user.data.payment_package.data.name;
    const isTrialUser = agency.user.data.trial;
    const [loader, setLoader] = useState<boolean>(false);
    const [packages, setPackages] = useState<ObjectKeys[]>([]);
    const [selectedPackageOption, setSelectedPackageOption] = useState<ObjectKeys>(packages[0]);
    const navigate = useNavigate();

    const { cartData } = useServicesCartStore();

    useEffect(() => {
        if (cart && cart.items && cart.items.length > 0) {
            useServicesCartStore.setState({ cartData: cart });
        }
    }, [cart]);

    useEffect(() => {
        if (cartData && cartData.items && cartData.items.length > 0) {
            finallCart(cartData);
        }
    }, [cartData]);

    const getAllPackages = (): void => {
        setLoader(true);
        communication
            .getAllPackages()
            .then((response: ObjectKeys) => {
                const activePackages = response.data.data.filter((item: ObjectKeys) => item.active);
                const trialPackage = {
                    active: 1,
                    half_year_discount: 0,
                    id: '123',
                    monthly: 0,
                    months: 1,
                    name: 'Trial',
                    services: {
                        data: [],
                    },
                    services_discount: 0,
                    year_discount: 0,
                };
                const inactivePackage = {
                    active: 1,
                    half_year_discount: 0,
                    id: '0000',
                    monthly: 0,
                    months: 1,
                    name: t('pages.agency.subscription.your_cart').text,
                    services: {
                        data: [],
                    },
                    services_discount: 0,
                    year_discount: 0,
                };
                const allPackages = !isActiveAccount
                    ? [inactivePackage].concat(activePackages)
                    : isTrialUser
                    ? [trialPackage].concat(activePackages)
                    : activePackages;
                setPackages(allPackages);
                setSelectedPackageOption(activePackages[0]);
                setLoader(false);
            })
            .catch((error: any) => {
                setLoader(false);
                console.error('Error:', error);
            });
    };

    useEffect(() => {
        getAllPackages();
        if (cart && cart.items && cart.items.length < 1) {
            navigate('/services/1');
        }
    }, []);

    return (
        <Wrapper>
            {loader && <Loader />}
            <Title>
                {!isActiveAccount ? (
                    <h1>{t('pages.agency.subscription.keep_shopping').text}</h1>
                ) : isTrialUser ? (
                    <>
                        <h1> {t('pages.agency.subscription.title').text}</h1>
                    </>
                ) : (
                    <>
                        <h1> {t('pages.agency.subscription.title').text}</h1>
                    </>
                )}
            </Title>
            {userPackage !== 'Premium' ? (
                <Advertisement>
                    <h2>{t('pages.agency.subscription.packageAd').text}</h2>
                </Advertisement>
            ) : null}

            <Content>
                <div className="packages">
                    {packages.map((item, index) => (
                        <PackageCard
                            cart={cart}
                            price={item.monthly}
                            monthlyObligatory={item.months}
                            isClicked={true}
                            prevent={
                                (item.id === '0000' && !isActiveAccount) ||
                                (isActiveAccount && item.name === userPackage) ||
                                (isActiveAccount && item.name === 'Trial')
                            }
                            zeroPrice={cart.total_price === 0}
                            onClicked={() => {
                                nextStep();
                                navigate('/services/3');
                            }}
                            item={packages[index]}
                            key={index}
                        />
                    ))}
                </div>
            </Content>
            <Arrows>
                <StepControlButton
                    step="previous"
                    handleClick={() => {
                        previousStep();
                        navigate('/services/1');
                    }}
                />
                <StepControlButton
                    handleClick={() => {
                        nextStep();
                        navigate('/services/3');
                    }}
                    step="next"
                />
            </Arrows>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    padding: 20px 0;
`;
const Title = styled.div`
    display: flex;
    background-color: var(--purple);
    padding: 20px 10px;
    h1 {
        font-size: 20px;
        color: var(--white);
    }
    h2 {
        font-size: 20px;
        color: var(--purple);
    }
`;
const Arrows = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;
const Advertisement = styled.div`
    margin-top: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
        width: 50%;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            width: 100%;
        }
        font-size: 20px;
        color: var(--purple);
        margin: 0; // Remove default margin for better alignment and consistency
        padding: 10px 20px; // Add some padding for better visual spacing
        border-radius: 10px; // Optional: round corners
    }
`;
const Content = styled.div`
    .packages {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        padding: 20px;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-wrap: wrap;
        }
    }
`;
export default StepTwo;
