import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import styled from 'styled-components';
import colors from '../../../../global/colors';
import moment from 'moment';
import communication from '../../../../communication';
import { formatThousandsToK } from '../../../../functions/format';
import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';
import useResponsive from '../../../../hooks/responsive/useResponsive';
import Skeleton from '../barChartTop5/skeleton';
import { useNavigate } from 'react-router-dom';
import { formatTwoDecimals } from '../../../../functions/format';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

interface PeriodFiltersProps {
    choosenDate: number;
}

const BarChart: React.FC<PeriodFiltersProps> = ({ choosenDate }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [labels, setLabels] = useState<string[]>([]);

    const [startDate, setStartDate] = useState<string>(moment().subtract(365, 'days').format('YYYY-MM-DD'));
    const [loader, setLoader] = useState<boolean>(false);
    const [clientData, setClientData] = useState<any[]>([]);
    const [total, setTotal] = useState<number>(0);
    const { isMobileS, isMobileM, isMobileL, isTablet, isLaptop, isLaptopL, isDesktop, isDesktopL, isUltraWide } =
        useResponsive();

    const navigate = useNavigate();

    const chartRef = React.useRef<ChartJS<'bar'> | null>(null);

    const dataValues1 = Array.isArray(clientData)
        ? clientData.map((client) => parseFloat(client.outstanding_amount_in_rsd))
        : [];

    const data = {
        labels,
        datasets: [
            {
                data: dataValues1,
                backgroundColor: ['#ef3a5d', '#FF9C00', '#fa9353', '#54bfa5', '#5fb6ff'],
                borderWidth: 3,
                borderColor: 'white',
            },
        ],
    };

    const options: ChartOptions<'bar'> = {
        indexAxis: 'y', // Correctly inferred type, no need for 'as const'
        scales: {
            y: {
                display: false,
            },
            x: {
                type: 'linear', // TypeScript correctly infers this as the linear scale
                display: true,
                position: 'bottom',
                ticks: {
                    callback: function (value: number | string) {
                        return formatThousandsToK(value.toString());
                    },
                },
            },
        },
        onClick: (event) => {
            const chart = chartRef.current;
            if (chart) {
                const elements = chart.getElementsAtEventForMode(
                    event as unknown as Event,
                    'nearest',
                    { intersect: true },
                    true,
                );
                if (elements.length > 0) {
                    const index = elements[0].index;
                    const selectedUser = clientData[index];
                    const endDate = moment().format('YYYY-MM-DD');
                    navigate(`/statistics/reports/${selectedUser.client_id}?startDate=${startDate}&endDate=${endDate}`);
                }
            }
        },
        elements: {
            bar: {
                borderWidth: 1,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const label = tooltipItem.label || '';
                        const value = tooltipItem.raw as number;
                        const formattedValue = formatTwoDecimals(value.toString());
                        return `${label}: ${formattedValue}`;
                    },
                },
                backgroundColor: '#9b7fe9',
            },
            legend: {
                display: false, // Add this line to disable the legend
            },
            datalabels: {
                anchor: 'start',
                align: 'end',
                formatter: function (value: any, context: any) {
                    const company = clientData[context.dataIndex].company_name.replace(/"/g, '');
                    const percentage = `${clientData[context.dataIndex].percentage.toFixed(2)}%`;
                    let maximumNumberOfChars = 0;
                    let limitCharsAt = 0;

                    switch (true) {
                        case isMobileS:
                            maximumNumberOfChars = 22;
                            limitCharsAt = 22;
                            break;
                        case isMobileM:
                            maximumNumberOfChars = 35;
                            limitCharsAt = 35;
                            break;
                        case isMobileL:
                            maximumNumberOfChars = 40;
                            limitCharsAt = 40;
                            break;
                        case isTablet:
                            maximumNumberOfChars = 12;
                            limitCharsAt = 12;
                            break;
                        case isLaptop:
                            maximumNumberOfChars = 24;
                            limitCharsAt = 24;
                            break;
                        case isLaptopL:
                            maximumNumberOfChars = 25;
                            limitCharsAt = 25;
                            break;
                        case isDesktop:
                            maximumNumberOfChars = 44;
                            limitCharsAt = 44;
                            break;
                        case isDesktopL:
                            maximumNumberOfChars = 80;
                            limitCharsAt = 80;
                            break;
                        case isUltraWide:
                            maximumNumberOfChars = 120;
                            limitCharsAt = 120;
                            break;
                        default:
                            break;
                    }
                    if (company.length > maximumNumberOfChars) {
                        const shorterCompanyName: string = company.slice(0, limitCharsAt).concat('...');
                        return `${shorterCompanyName}: ${percentage.replace('.', ',')}`;
                    }
                    return `${company}: ${percentage.replace('.', ',')}`;
                },
                color: 'black',
                borderColor: 'black',
                font: {
                    weight: 'normal',
                    size: 14, // Number type instead of string for font size
                },
            },
        },
    };

    const companyNameShortener = (companyNames: string[]): string[] => {
        return companyNames.map((separateName: string) => {
            let maximumNumberOfChars = 0;
            let limitCharsAt = 0;

            switch (true) {
                case isMobileS:
                    maximumNumberOfChars = 20;
                    limitCharsAt = 20;
                    break;
                case isMobileM:
                    maximumNumberOfChars = 35;
                    limitCharsAt = 35;
                    break;
                case isMobileL:
                    maximumNumberOfChars = 50;
                    limitCharsAt = 50;
                    break;
                case isTablet:
                    maximumNumberOfChars = 100;
                    limitCharsAt = 100;
                    break;
                case isLaptop:
                    maximumNumberOfChars = 38;
                    limitCharsAt = 38;
                    break;
                case isLaptopL:
                    maximumNumberOfChars = 48;
                    limitCharsAt = 48;
                    break;
                case isDesktop:
                    maximumNumberOfChars = 47;
                    limitCharsAt = 47;
                    break;
                case isDesktopL:
                    maximumNumberOfChars = 100;
                    limitCharsAt = 100;
                    break;
                case isUltraWide:
                    maximumNumberOfChars = 200;
                    limitCharsAt = 200;
                    break;
                default:
                    break;
            }

            if (separateName.length > maximumNumberOfChars) {
                return separateName.slice(0, limitCharsAt).concat('...');
            }
            return separateName;
        });
    };

    const fetchTotale = (): void => {
        if (!startDate) return;
        setLoader(true);
        const propsTotal = {
            start_date: startDate,
            end_date: moment().format('YYYY-MM-DD') + ' 23:59:59',
        };
        communication.getTopDebtors(propsTotal).then((res: any) => {
            setTotal(res.data.data.total_sum);
            const clients = res.data.data.clients
                .map((client: any) => ({
                    client_id: client.client_id || '',
                    company_name: client.company_name ? client.company_name.replace(/"/g, '') : '/',
                    percentage: client.percentage || 0,
                    total_value_in_rsd: parseFloat(client.total_value_in_rsd) || 0,
                    outstanding_amount_in_rsd: parseFloat(client.outstanding_amount_in_rsd) || 0,
                }))
                .filter((client: any) => client.outstanding_amount_in_rsd >= 0);
            const placeholderClient = {
                client_id: '',
                company_name: '/',
                percentage: 0,
                total_value_in_rsd: '0',
            };
            while (clients.length < 5) {
                clients.push(placeholderClient);
            }
            setClientData(clients);
            const companyNames = clients.map((client: any) => client.company_name.replace(/"/g, ''));
            const shortenedNames = companyNameShortener(companyNames);
            setLabels(shortenedNames);
            setLoader(false);
        });
    };

    useEffect(() => {
        let daysBack = choosenDate;
        switch (choosenDate) {
            case 1:
                daysBack = 30;
                break;
            case 3:
                daysBack = 90;
                break;
            case 6:
                daysBack = 180;
                break;
            case 12:
                daysBack = 365;
                break;
            default:
                daysBack = 365;
        }

        const newStartDate = moment().subtract(daysBack, 'days').format('YYYY-MM-DD');
        setStartDate(newStartDate);
    }, [choosenDate]);

    useEffect(() => {
        fetchTotale();
    }, [startDate]);

    return (
        <>
            <Content>
                <h3>{t('pages.reports.charts.top_debtors').text}</h3>
                {loader ? (
                    <Skeleton />
                ) : (
                    <>
                        {total === 0 ? (
                            <NoData>{t('pages.reports.charts.noData').text}</NoData>
                        ) : (
                            <ResponsiveBar data={data} options={options} ref={chartRef} />
                        )}
                    </>
                )}
            </Content>
        </>
    );
};
const ResponsiveBar = styled(Bar)`
    max-width: 100%;
    max-height: 300px;
    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        margin-top: 20px;
        canvas {
            max-width: 100%;
            min-height: 300px;
        }
    }
    cursor: pointer;
`;
const Content = styled.div`
    margin-top: 20px;
    h3 {
        text-align: center;
        margin-bottom: 26px;
        font-size: 14px;
        border: 1px solid #ccc;
        background-color: var(--purple);
        color: #fff;
        padding: 10px;
        text-transform: uppercase;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            text-align: left;
        }
    }
    p {
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .period-container {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        margin-top: 10px;
        flex-wrap: wrap;
        gap: 1rem;
        margin-bottom: 1.5rem;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            justify-content: left;
        }
    }

    .period {
        padding: 5px 10px;
        border: 1px solid #ccc;
        cursor: pointer;
        text-align: center;
    }

    .period:hover {
        background-color: #f0f0f0;
    }
    .period.active {
        background-color: ${colors.purple};
        color: #fff;
    }

    .values {
        margin-top: 2.5rem;
        p {
            color: var(--gray);
            font-size: 15px;
        }
    }
`;
const NoData = styled.div`
    margin-top: 50px;
    padding: 20px;
    text-align: center;
    margin-bottom: 100px;
`;
export default BarChart;
