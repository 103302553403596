import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ObjectKeys } from '../../../../types/objectKeys';
import Pagination from '../../../../components/pagination';
import communication from '../../../../communication';
import Button, { ButtonVariant } from '../../../../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import FiltersStatistics from './filters';
import Loader from '../../../../components/loader';
import NoItems from '../../../../components/noItems';
import useLanguageStore from '../../../../store/language';
import exportToExcel from '../../../../functions/downloadXlsx';
import { transformDateFormat, transformDateTimeFormat } from '../../../../functions/formatDateTime';
import useUserStore from '../../../../store/user';

interface Props {
    t: Function;
}

const NewUsers: FunctionComponent<Props> = ({ t }) => {
    const { currentLangName } = useLanguageStore();
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<ObjectKeys>();
    const [users, setUsers] = useState<ObjectKeys>();
    const [testUsers, setTestUsers] = useState<ObjectKeys>();

    const [usersTableYearly, setUsersTableYearly] = useState<ObjectKeys>();
    const [shouldExport, setShouldExport] = useState(false);

    const [usersNo, setUsersNo] = useState(0);

    const [years, setYears] = useState<number[]>([]);
    const currentYear = new Date().getFullYear();

    const activityCodes = useUserStore((state) => state.activityCodes);

    const [paramsFilter, setParamsFilter] = useState({
        page: 1,
        limit: '10',
        start_date: `${currentYear}-01-01 00:00:00`,
        end_date: `${currentYear}-01-31 23:59:59`,
    });

    async function getData(): Promise<void> {
        setLoading(true);
        const res = await communication.getRegistrationStatistics(paramsFilter);
        setResponse(res.data);
        setTestUsers(res.data.data);
        setUsers(Object.values(res.data.data).flatMap((year: any) => year.users));
        setUsersNo(res.data.meta.pagination.total);

        setLoading(false);
    }

    async function downloadExcelTable(): Promise<void> {
        setLoading(true);
        const paramsWithoutPagination = {
            start_date: paramsFilter.start_date,
            end_date: paramsFilter.end_date,
        };
        const resWithoutPage = await communication.getRegistrationStatistics(paramsWithoutPagination);
        setUsersTableYearly(resWithoutPage.data.data);
        setShouldExport(true);
        setLoading(false);
    }

    useEffect(() => {
        const yearsArray = Array.from({ length: currentYear - 2000 + 1 }, (_, i) => currentYear - i);
        setYears(yearsArray);
    }, []);

    useEffect(() => {
        if (shouldExport && usersTableYearly?.length > 0) {
            setLoading(false);
            exportToExcel('download', 'newly-registered', 'newly-registered.xlsx');
        }
    }, [usersTableYearly, shouldExport]);

    useEffect(() => {
        getData();
    }, [paramsFilter.page]);

    const handlePageClick = (e: any): void => {
        setParamsFilter({ ...paramsFilter, page: e.selected + 1 });
    };
    const months =
        currentLangName === 'Srpski'
            ? [
                  { label: 'Januar', value: '01', days: '31' },
                  { label: 'Februar', value: '02', days: '28' },
                  { label: 'Mart', value: '03', days: '31' },
                  { label: 'April', value: '04', days: '30' },
                  { label: 'Maj', value: '05', days: '31' },
                  { label: 'Jun', value: '06', days: '30' },
                  { label: 'Jul', value: '07', days: '31' },
                  { label: 'Avgust', value: '08', days: '31' },
                  { label: 'Septembar', value: '09', days: '30' },
                  { label: 'Oktobar', value: '10', days: '31' },
                  { label: 'Novembar', value: '11', days: '30' },
                  { label: 'Decembar', value: '12', days: '31' },
              ]
            : [
                  { label: 'January', value: '01', days: '31' },
                  { label: 'February', value: '02', days: '28' },
                  { label: 'March', value: '03', days: '31' },
                  { label: 'April', value: '04', days: '30' },
                  { label: 'May', value: '05', days: '31' },
                  { label: 'June', value: '06', days: '30' },
                  { label: 'July', value: '07', days: '31' },
                  { label: 'August', value: '08', days: '31' },
                  { label: 'September', value: '09', days: '30' },
                  { label: 'October', value: '10', days: '31' },
                  { label: 'November', value: '11', days: '30' },
                  { label: 'December', value: '12', days: '31' },
              ];

    const handleEmploymentType = (employmentType: string): string => {
        if (employmentType === 'retired') {
            return 'Penzioner';
        } else if (employmentType === 'employment_relationship') {
            return 'U radnom odnosu';
        } else if (employmentType === 'flat_rate_agency') {
            return 'Pausalna agencija';
        } else {
            return 'Nepoznato';
        }
    };

    const getActivyCode = (code: string): string => {
        const activityCode = activityCodes?.find((item: ObjectKeys) => item.code === code);
        return activityCode?.name;
    };

    return (
        <>
            {loading && <Loader />}
            {
                <>
                    <Container id="download-current">
                        <FiltersStatistics
                            months={months}
                            years={years}
                            setParams={setParamsFilter}
                            params={paramsFilter}
                            refetch={() => {
                                getData();
                            }}
                        />
                        {users && users.length > 0 ? (
                            <>
                                <Table>
                                    <tbody>
                                        <Row>
                                            <Heading>{t('pages.admin.statistics.title').text}</Heading>
                                            <Heading>
                                                {months[new Date(paramsFilter.start_date).getMonth()].label +
                                                    ' ' +
                                                    new Date(paramsFilter.start_date).getFullYear() +
                                                    ' ' +
                                                    '-' +
                                                    ' ' +
                                                    months[new Date(paramsFilter.end_date).getMonth()].label +
                                                    ' ' +
                                                    new Date(paramsFilter.end_date).getFullYear()}
                                            </Heading>
                                        </Row>
                                        <Row>
                                            <Data>{t('pages.admin.statistics.bankNewUsers').text}</Data>
                                            <Data>{usersNo}</Data>
                                        </Row>
                                    </tbody>
                                </Table>

                                <Table>
                                    <tbody>
                                        <Row>
                                            <Heading>{t('pages.admin.statistics.email').text}</Heading>
                                            <Heading>{t('pages.admin.statistics.vatNo').text}</Heading>
                                            <Heading>{t('pages.admin.statistics.bank').text}</Heading>
                                            <Heading>Šifra delatnosti</Heading>
                                            <Heading>{t('pages.admin.statistics.activityCode').text}</Heading>
                                            <Heading>{t('pages.admin.statistics.companySeat').text}</Heading>
                                            <Heading>Datum registracije</Heading>
                                        </Row>
                                        {testUsers &&
                                            testUsers.map((user: ObjectKeys, index: number) => (
                                                <Row key={index}>
                                                    <Data>{user.email}</Data>
                                                    <Data>{user.pib}</Data>
                                                    <Data>{user.bank_name}</Data>
                                                    <Data>{user.activity_code}</Data>
                                                    <Data>{getActivyCode(user.activity_code)}</Data>
                                                    <Data>{user.municipality}</Data>
                                                    <Data>{transformDateFormat(user.date_of_registration)}</Data>
                                                </Row>
                                            ))}
                                    </tbody>
                                </Table>
                                <Pagination
                                    pageCount={response?.meta.pagination?.total_pages}
                                    onPageChange={(e: object) => handlePageClick(e)}
                                    nextLabel={`${
                                        response?.meta.pagination?.current_page ===
                                        response?.meta.pagination?.total_pages
                                            ? ''
                                            : '>'
                                    }`}
                                    previousLabel={`${response?.meta.pagination?.current_page === 1 ? '' : '<'}`}
                                    breakLabel="..."
                                    initialPage={response?.meta.pagination?.current_page - 1}
                                />
                            </>
                        ) : (
                            <NoItems text={t('pages.admin.statistics.noUsers').text} />
                        )}
                    </Container>
                    <ButtonContainer>
                        <Button
                            variant={ButtonVariant.solid}
                            color={'var(--purple)'}
                            icon
                            onClick={() => {
                                downloadExcelTable();
                            }}
                        >
                            <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>
                            {t('pages.admin.statistics.saveAll').text}
                        </Button>
                    </ButtonContainer>

                    {/* table for download yearly users */}
                    <Container style={{ display: 'none' }}>
                        <Table id="download">
                            <tbody>
                                <Row>
                                    <Heading>{t('pages.admin.statistics.title').text}</Heading>
                                    <Heading>
                                        {months[new Date(paramsFilter.start_date).getMonth()].label +
                                            ' ' +
                                            new Date(paramsFilter.start_date).getFullYear() +
                                            ' ' +
                                            '-' +
                                            ' ' +
                                            months[new Date(paramsFilter.end_date).getMonth()].label +
                                            ' ' +
                                            new Date(paramsFilter.end_date).getFullYear()}
                                    </Heading>
                                </Row>
                                <Row>
                                    <Data>{t('pages.admin.statistics.bankNewUsers').text}</Data>
                                    <Data>{usersNo}</Data>
                                </Row>
                            </tbody>
                            <tbody>
                                <Row>
                                    <Heading>{t('pages.admin.statistics.email').text}</Heading>
                                    <Heading>{t('pages.admin.statistics.vatNo').text}</Heading>
                                    <Heading>{t('pages.admin.statistics.bank').text}</Heading>
                                    <Heading>{t('pages.admin.statistics.activityCode').text}</Heading>
                                    <Heading>Delatnost</Heading>
                                    <Heading>{t('pages.admin.statistics.companySeat').text}</Heading>
                                    <Heading>Datum registracije</Heading>
                                    <Heading>Datum poslednjeg logina</Heading>
                                    <Heading>Broj komitenata</Heading>
                                    <Heading>Fizička lica kao komitenti</Heading>
                                    <Heading>Odabrani jezik</Heading>
                                    <Heading>Poslovni status</Heading>
                                    <Heading>Upisan swift i iban</Heading>
                                </Row>
                                {usersTableYearly?.length > 0 &&
                                    usersTableYearly?.map((user: ObjectKeys, index: number) => (
                                        <Row key={index}>
                                            <Data>{user.email}</Data>
                                            <Data>{user.pib}</Data>
                                            <Data>{user.bank_name}</Data>
                                            <Data>{user.activity_code}</Data>
                                            <Data>{getActivyCode(user.activity_code)}</Data>
                                            <Data>{user.municipality}</Data>
                                            <Data>{transformDateFormat(user.date_of_registration)}</Data>
                                            <Data>{transformDateTimeFormat(user.last_login)}</Data>
                                            <Data>{user.clients_count}</Data>
                                            <Data>{user.civil_clients_count}</Data>
                                            <Data>{user.lang}</Data>
                                            <Data>{handleEmploymentType(user?.employment_type)}</Data>
                                            <Data>{user.foreign_accounts_count > 0 ? 'DA' : 'NE'}</Data>
                                        </Row>
                                    ))}
                            </tbody>
                        </Table>
                    </Container>
                </>
            }
        </>
    );
};

const Container = styled.div`
    position: relative;
`;
export const Table = styled.table`
    :nth-of-type(1) {
        margin-top: 20px;
    }
    width: 100%;
    background-color: var(--white);
    border-collapse: collapse;
`;
export const Row = styled.tr`
    font-weight: 700;
`;
export const Heading = styled.th`
    background-color: var(--purple);
    color: var(--white);
    border: 1px solid rgba(0, 0, 0, 0.1);

    &.data {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px;
        font-size: 15px;
        text-align: center;
        vertical-align: middle;
    }
`;
export const Data = styled.td`
    border: 1px solid rgba(0, 0, 0, 0.1);

    padding: 10px;
    font-size: 15px;
    text-align: center;
    vertical-align: middle;
    font-weight: 400;
`;
export const ButtonContainer = styled.div`
    button {
        margin-top: 20px;
        float: right;
        font-size: 12px;
        padding: 8px 20px;
        vertical-align: top;
        border-radius: 100px;
    }
`;
export default NewUsers;
