import { Dispatch, FunctionComponent, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import colors from '../../../../global/colors';
import Button, { ButtonVariant } from '../../../../components/button';
import useAgencyStore from '../../../../store/agency';

export type ErrorObject = {
    error: string;
    row: number;
};

export type SuccessObject = {
    prefix: number;
    invoice_number: number;
    row: number;
    year: number;
};

type Props = {
    t: Function;
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    errorMessages: string | ErrorObject[];
    successMessages: string | SuccessObject[];
    buttonName?: string;
};

const ResponseModal: FunctionComponent<Props> = ({ t, setOpenModal, errorMessages, successMessages, buttonName }) => {
    const { agency } = useAgencyStore();

    const userLanguage = agency?.user?.data?.lang || 'rs';

    const translations = {
        rs: {
            invoiceUpload: 'UVOZ FAKTURA',
            row: 'Red',
            failedNote: 'Fakture koje nisu uspešno generisane unesi ponovo sa otklonjenim greškama',
            emptyFileUploadedMessage:
                'Dokument koji je dodat nema unetih podataka. Molimo te da uneseš potrebne podatke i pokušaš ponovo',
            success: 'Uspešno su generisane fakture sa brojem:',
            failure: 'Fakture koje nisu uspešno generisane:',
        },
        en: {
            invoiceUpload: 'INVOICE IMPORT',
            row: 'Row',
            failedNote:
                'Invoices that were not successfully generated, please re-submit them with the corrected errors',
            emptyFileUploadedMessage:
                'The document you uploaded contains no data. Please fill in the required information and try again',
            success: 'Invoices with the number have been successfully generated:',
            failure: 'Invoices that were not successfully generated:',
        },
        ru: {
            invoiceUpload: 'ИМПОРТ СЧЕТОВ',
            row: 'Строка',
            failedNote: 'Счета, которые не были успешно сгенерированы, введите снова с исправленными ошибками',
            emptyFileUploadedMessage:
                'Добавленный вами документ не содержит данных. Пожалуйста, введите необходимые данные и попробуйте снова',
            success: 'Счета с номером успешно сгенерированы:',
            failure: 'Счета, которые не были успешно сгенерированы:',
        },
    };

    const isFileEmpty = successMessages?.length === 0 && errorMessages?.length === 0;
    return (
        <ResponseModalWrapper>
            <ModalText>
                <h4 className="header">
                    {userLanguage === 'rs'
                        ? translations.rs.invoiceUpload
                        : userLanguage === 'en'
                        ? translations.en.invoiceUpload
                        : translations.ru.invoiceUpload}
                </h4>

                {isFileEmpty ? (
                    <p>
                        {userLanguage === 'rs'
                            ? translations.rs.emptyFileUploadedMessage
                            : userLanguage === 'en'
                            ? translations.en.emptyFileUploadedMessage
                            : translations.ru.emptyFileUploadedMessage}
                    </p>
                ) : (
                    <>
                        {successMessages && successMessages.length > 0 && (
                            <div className="successfulUploads">
                                <h4>
                                    {userLanguage === 'rs'
                                        ? translations.rs.success
                                        : userLanguage === 'en'
                                        ? translations.en.success
                                        : translations.ru.success}
                                </h4>
                                {typeof successMessages === 'string' ? (
                                    <ul>
                                        <li>{successMessages}</li>
                                    </ul>
                                ) : (
                                    <ul>
                                        {successMessages.map((message, index) => (
                                            <li key={index}>
                                                {message.prefix} {message.invoice_number} / {message.year}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        )}

                        {errorMessages && errorMessages.length > 0 && (
                            <>
                                <div className="failedUploads">
                                    <h4>
                                        {userLanguage === 'rs'
                                            ? translations.rs.failure
                                            : userLanguage === 'en'
                                            ? translations.en.failure
                                            : translations.ru.failure}
                                    </h4>
                                    {typeof errorMessages === 'string' ? (
                                        <ul>
                                            <li>{errorMessages}</li>
                                        </ul>
                                    ) : (
                                        <ul>
                                            {errorMessages.map((message, index) => (
                                                <li key={index}>
                                                    {userLanguage === 'rs'
                                                        ? translations.rs.row
                                                        : userLanguage === 'en'
                                                        ? translations.en.row
                                                        : translations.ru.row}{' '}
                                                    {message.row}, {message.error}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <p>
                                    {userLanguage === 'rs'
                                        ? translations.rs.failedNote
                                        : userLanguage === 'en'
                                        ? translations.en.failedNote
                                        : translations.ru.failedNote}
                                </p>
                            </>
                        )}
                    </>
                )}
            </ModalText>

            <Button
                variant={ButtonVariant.solid}
                color={colors.purple}
                size={'100%'}
                onClick={() => {
                    setOpenModal(false);
                }}
            >
                {buttonName ? buttonName : t('buttons.ok').text}
            </Button>
        </ResponseModalWrapper>
    );
};

const ResponseModalWrapper = styled.div`
    text-align: left;
    max-height: 90vh;
    overflow-y: auto;

    button {
        border-radius: 0 0 5px 5px;
    }

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: var(--purple);
        border-radius: 10px;
        border: 2px solid #f1f1f1;
    }
`;

const ModalText = styled.div`
    display: flex;
    flex-direction: column;
    padding: 35px 50px 30px;
    h4 {
        margin-bottom: 30px;
    }
    p {
        line-height: 30px;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .header {
        margin-bottom: 20px;
        text-align: center;
        color: var(--purple);
    }

    .successfulUploads,
    .failedUploads {
        margin-bottom: 20px;
    }
`;

export default ResponseModal;
