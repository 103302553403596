import { FunctionComponent, Dispatch, SetStateAction, useState, useEffect } from 'react';
import { faBars, faBorderAll } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../../../global/colors';
import { ObjectKeys } from '../../../../types/objectKeys';
import useTranslations from '../../../../hooks/useTranslation';
import useLanguageStore from '../../../../store/language';
import useYearsStore from '../../../../store/years';
import useCurrencyStore from '../../../../store/currency';
import SelectComponent from '../../../../components/select';
import Search from '../../../../components/input/search';
import {
    StyledFiltersWrapper,
    StyledTopFilters,
    StyledFiltersInner,
    StyledDisplayIcons,
    StyledBottomFilters,
    StyledShowSelect,
} from '../../common/styled-filters';

interface Props {
    setParams: Dispatch<SetStateAction<any>>;
    params: ObjectKeys;
    searchValue?: string;
    setSearchValue: Dispatch<SetStateAction<string>>;
    setDisplay: Dispatch<SetStateAction<string>>;
    selectedUserView?: string;
}

const Filters: FunctionComponent<Props> = ({
    setParams,
    params,
    searchValue,
    setSearchValue,
    setDisplay,
    selectedUserView,
}) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { quotationYears } = useYearsStore();
    const { currency } = useCurrencyStore();
    const [renderKey, setRenderKey] = useState(0);
    const [showSearch, setShowSearch] = useState(false);

    const [currencies, setCurrencies] = useState<Array<{ value: string; label: string }>>([]);
    const [yearsOptions, setYearsOptions] = useState<Array<{ value: any; label: any }>>([]);

    const [showItems] = useState([
        { value: '10', label: '10' },
        { value: '20', label: '20' },
        { value: '30', label: '30' },
        { value: '40', label: '40' },
    ]);

    useEffect(() => {
        const yearsData = quotationYears?.map((year) => {
            return { value: year, label: year };
        });
        setYearsOptions([{ value: '', label: t('pages.invoices.filters.status.all').text }, ...yearsData]);

        setRenderKey((prev) => prev + 1);
    }, [useYearsStore()]);

    useEffect(() => {
        const currencyData = currency?.map((curr) => {
            return { value: curr.currency.toUpperCase(), label: curr.currency };
        });
        const yearsData = quotationYears?.map((year) => {
            return { value: year, label: year };
        });

        setCurrencies([{ value: '', label: t('pages.invoices.filters.status.all').text }, ...currencyData]);
        setYearsOptions([{ value: '', label: t('pages.invoices.filters.status.all').text }, ...yearsData]);
    }, [currentLang]);

    return (
        <StyledFiltersWrapper>
            <StyledTopFilters>
                <StyledFiltersInner>
                    <SelectComponent
                        className="select-container"
                        optionList={currencies}
                        label={t('pages.proforma.filters.currency.label').text}
                        handleSelectedValue={(value: string) =>
                            setParams({
                                ...params,
                                search: { ...params.search, currency: value },
                                page: 1,
                                searchFields:
                                    value !== ''
                                        ? { ...params.searchFields, currency: '=' }
                                        : { ...params.searchFields, currency: value },
                            })
                        }
                        defaultSelectedOption={currencies[0]}
                    />
                    <SelectComponent
                        className="select-container"
                        optionList={yearsOptions}
                        label={t('pages.proforma.filters.year.label').text}
                        handleSelectedValue={(value: string) => {
                            setParams({
                                ...params,
                                year: value,
                                page: 1,
                            });
                        }}
                        defaultSelectedOption={yearsOptions[0]}
                        key={renderKey}
                    />
                </StyledFiltersInner>
                <StyledDisplayIcons>
                    <FontAwesomeIcon
                        icon={faBars}
                        color={colors.white}
                        className={`cursor-pointer ${selectedUserView === 'list' && 'userViewSelection'}`}
                        onClick={() => setDisplay('list')}
                    />
                    <FontAwesomeIcon
                        icon={faBorderAll}
                        color={colors.white}
                        className={`cursor-pointer ${selectedUserView === 'grid' && 'userViewSelection'}`}
                        onClick={() => setDisplay('grid')}
                    />
                </StyledDisplayIcons>
            </StyledTopFilters>
            <StyledBottomFilters>
                <StyledShowSelect>
                    <SelectComponent
                        optionList={showItems}
                        label={t('pages.proforma.filters.show.label').text}
                        handleSelectedValue={(value: string) => setParams({ ...params, limit: value })}
                        defaultSelectedOption={showItems[0]}
                    />
                    <p>{t('pages.proforma.filters.show.proforma').text}</p>
                </StyledShowSelect>
                <Search
                    placeholder={t('pages.proforma.filters.search').text}
                    value={searchValue}
                    onChange={(value: string) => {
                        setSearchValue(value);
                        setShowSearch(true);
                    }}
                    onLeaveOrEnter={(value: string) => {
                        setShowSearch(false);
                        setSearchValue(value);
                        setParams({
                            ...params,
                            search: { ...params.search, 'client.company_name': value },
                            page: 1,
                            searchFields:
                                value !== ''
                                    ? { ...params.searchFields, 'client.company_name': 'like' }
                                    : { ...params.searchFields, 'client.company_name': value },
                        });
                    }}
                    showSearchNote={showSearch}
                />
            </StyledBottomFilters>
        </StyledFiltersWrapper>
    );
};

export default Filters;
