import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare, faInfoCircle, faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { ObjectKeys } from '../../../types/objectKeys';
import colors from '../../../global/colors';
import useLanguageStore from '../../../store/language';
import { formatNumber } from '../../../functions/format';
import getNumberLabel from './../getNumberLabel';
import getStatus from './../getStatus';
import getRestOfPayment from '../getRestOfPayment';
import { transformDateFormat } from '../../../functions/formatDateTime';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';
import { Icon } from '../invoiceActionsWithoutDropdown';
import Tooltip from '../tooltip';
import TooltipMain from '../../../components/tooltipMain';
import Checkbox from '../../../components/checkbox';
import useResponsive from '../../../hooks/responsive/useResponsive';

interface Props {
    type: string;
    handlePayment?: Function;
    t: Function;
    cardData: ObjectKeys;
    handleUpdatePayment?: Function;
    handleDeletePayment?: Function;
    edit8millionLimit?: Function;
    cardDisplay: string;
}

const PaymentDropdown: FunctionComponent<Props> = ({
    cardData,
    t,
    handlePayment,
    type,
    handleUpdatePayment,
    handleDeletePayment,
    edit8millionLimit,
    cardDisplay,
}) => {
    const { currentLangName } = useLanguageStore();
    const { isTabletAndDown } = useResponsive();

    return (
        <PaymentDropdownWrapper>
            {cardData?.payments?.data?.map((payment: ObjectKeys, index: number) => (
                <PaymentCard key={payment?.id} cardLayout={cardDisplay}>
                    <div>{index + 1}</div>
                    <div className="number-col">
                        <div className="label">{getNumberLabel(currentLangName, type, t)}</div>
                        <div className="number">
                            {cardData?.invoice_number
                                ? cardData?.invoice_number
                                : getStatus(cardData?.status?.toLowerCase(), t)}
                        </div>
                    </div>
                    <div className="date-col">
                        <div className="label">{t('pages.invoiceCard.paymentCard.invoiceDate').text}</div>
                        <div className="date">{transformDateFormat(cardData?.invoice_date)}</div>
                    </div>
                    <div className="date-col">
                        <div className="label">{t('pages.invoiceCard.paymentCard.paymentDate').text}</div>
                        <div className="date">{transformDateFormat(payment?.paid_date)}</div>
                    </div>
                    <div className="amount-col">
                        <div className="label">{t('pages.invoiceCard.paymentCard.paidAmount').text}</div>
                        <div className="amount">
                            {formatNumber(payment?.value, 4, 3, '.', ',') + ' ' + cardData?.currency}
                        </div>
                    </div>
                    {payment?.prepaid_invoice_label ? (
                        <div className="number-col">
                            <div className="label">{t('pages.advanceInvoices.single').text}:</div>
                            <div className="number">{payment?.prepaid_invoice_label}</div>
                        </div>
                    ) : (
                        <PaymentAction>
                            <Tooltip text={t('pages.invoiceCard.paymentCard.editPayment').text}>
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faPenToSquare}
                                        style={{ color: colors.gray }}
                                        onClick={(e) => handleUpdatePayment && handleUpdatePayment(payment?.id)}
                                    />
                                </Icon>
                            </Tooltip>
                            <Tooltip text={t('pages.invoiceCard.paymentCard.deletePayment').text}>
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faTrashCan}
                                        style={{ color: colors.danger }}
                                        onClick={(e) => handleDeletePayment && handleDeletePayment(payment?.id)}
                                    />
                                </Icon>
                            </Tooltip>
                        </PaymentAction>
                    )}
                </PaymentCard>
            ))}
            <NotPaidCard cardLayout={cardDisplay}>
                <div className="notPaidTitle">
                    <p>{t('pages.invoiceCard.paymentCard.receivables').text}</p>
                </div>
                <div className="amount-action">
                    <div className="amount-col">
                        <div className="label">{t('pages.invoiceCard.paymentCard.totalClaims').text}</div>
                        <div className="amount">{getRestOfPayment(cardData) + ' ' + cardData?.currency}</div>
                    </div>
                    <Tooltip text={t('pages.invoiceCard.paymentCard.insertPayment').text}>
                        <Icon>
                            <FontAwesomeIcon
                                icon={faPenToSquare}
                                style={{ color: colors.blue }}
                                onClick={(e) => handlePayment && handlePayment(e)}
                            />
                        </Icon>
                    </Tooltip>
                </div>
            </NotPaidCard>
            {cardData && cardData.type === 'foreign' && edit8millionLimit ? (
                <Limit>
                    <div className="tooltipNote">
                        <p>{t('pages.editInvoice.previewModal.eightMillionLimit').text}</p>
                        <Checkbox
                            defaultChecked={cardData.limit8_million_exclude}
                            onChange={(e: MouseEvent) => {
                                edit8millionLimit(e);
                            }}
                            className={'limit-checkbox'}
                        />
                        <TooltipMain
                            text={t('pages.editInvoice.limit8milionTooltip').text}
                            isHtml={true}
                            position={`${isTabletAndDown ? 'left' : 'top'}`}
                            label={
                                <FontAwesomeIcon
                                    icon={faCircleQuestion}
                                    fontSize={'13px'}
                                    style={{ marginLeft: '0.5rem' }}
                                    color={colors.purple}
                                />
                            }
                        />
                    </div>
                </Limit>
            ) : null}
        </PaymentDropdownWrapper>
    );
};
const PaymentDropdownWrapper = styled.div`
    width: 100%;
    .label {
        text-transform: capitalize;
        font-size: 12px;
        color: var(--gray);
        line-height: 25px;
    }
`;

interface DisplayProps {
    cardLayout: string;
}

const PaymentCard = styled.div<DisplayProps>`
    background: #dcf1e4;
    grid-template-columns: ${(props) => (props.cardLayout === 'list' ? '1fr 2fr 2fr 2fr 2fr 1fr' : '1fr 1fr 1fr')};
    display: grid;
    align-items: center;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
    gap: ${(props) => (props.cardLayout === 'grid' ? '5px' : null)};
    &:hover {
        box-shadow: 0 0 5px 5px #eee;
    }

    @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        grid-template-columns: 1fr 1fr;
    }
`;
const PaymentAction = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`;
const NotPaidCard = styled.div<DisplayProps>`
    background-color: #ffdedc;
    padding: 10px;
    border-radius: 5px;
    grid-template-columns: ${(props) => (props.cardLayout === 'list' ? '9fr 3.85fr' : '1fr')};
    display: grid;
    align-items: center;
    gap: ${(props) => (props.cardLayout === 'grid' ? '10px' : null)};
    .amount-action {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &:hover {
        box-shadow: 0 0 5px 5px #eee;
    }

    .notPaidTitle p {
        font-size: 15px;
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        grid-template-columns: none;
    }
`;
export const Limit = styled.div`
    display: flex;
    justify-content: flex-end;
    div {
        display: flex;
        /* gap: 5px; */
        align-items: center;
    }
    p {
        font-size: 14px;
        color: var(--gray);
    }

    .tooltipNote {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 10px;
    }

    .limit-checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        flex-direction: column;
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        align-items: end;
        margin-top: 40px;
    }
`;
export default PaymentDropdown;
