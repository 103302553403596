import { useEffect, useState } from 'react';
import communication from '../communication';
import { ObjectKeys } from '../types/objectKeys';

export default function useSendMeEmail(
    setUserEmail: Function,
    setShowEmails: Function,
    setEmails: Function,
    setEmailsChanged: Function,
    handleRemoveEmail: Function,
    profile: ObjectKeys,
    sendMe: boolean,
    emailsChanged: boolean,
    userEmail: string,
    emails: string[],
    fromInput: boolean,
    setFromInput: Function,
): void {
    const [initialEmailUser, setInitialEmailUser] = useState<string | null>(null);
    const [isSendMeAdded, setIsSendMeAdded] = useState(false); // Track if the email was added due to `sendMe`

    useEffect(() => {
        const emailUser = profile.email || userEmail;

        const fetchUserEmail = async (): Promise<void> => {
            if (!profile.email) {
                const res: ObjectKeys = await communication.getUserProfile();
                if (res.status === 200) {
                    const fetchedEmail = res.data?.data?.email;
                    setUserEmail(fetchedEmail);
                    setInitialEmailUser(fetchedEmail);
                }
            } else {
                setInitialEmailUser(emailUser);
            }
        };

        fetchUserEmail();
    }, []); // Only run once to get the initial email

    useEffect(() => {
        const emailUser = initialEmailUser;

        if (!emailUser) return;

        if (sendMe) {
            setShowEmails(true);
            if (!emails.includes(emailUser)) {
                setEmails((prevEmails: any) => [...prevEmails, emailUser]);
                setEmailsChanged(!emailsChanged);
                setIsSendMeAdded(true); // Mark that this email was added due to `sendMe`
            }
        } else {
            if (isSendMeAdded) {
                handleRemoveEmail(emailUser); // Remove only if it was added by `sendMe`
                setIsSendMeAdded(false); // Reset after removal
            }
            if (fromInput) {
                setFromInput(false);
            }
        }
    }, [sendMe]);
}
