import styled from 'styled-components';
import { MQ_BREAKPOINTS, breakpoints } from '../../../../constants/breakpoints';

export const Wrapper = styled.div`
    .modal-backdrop {
        background-color: unset;
    }
    p {
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            font-size: 14px !important;
        }
    }
`;
export const Container = styled.div`
    color: var(--gray);
    p {
        font-size: 12px;
    }
    h3 {
        color: var(--black);
        font-weight: 600;
        font-size: 18px;
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        h1 {
            margin-top: 40px;
            margin-bottom: 20px;
            color: var(--black);
        }
    }
    h3 {
        &.services-title {
            font-size: 28px;
            font-weight: normal;
            margin: 20px 0;
        }
    }
    .sef-class {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
`;
export const ForeignInputs = styled.div`
    display: flex;
    justify-content: flex-end;
    > div {
        display: flex;
        flex-direction: column;
        > div {
            align-items: center;
            display: flex;
            @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
                flex-direction: column;
            }
        }
    }
    p {
        text-align: left;
    }
    .validation-message {
        color: var(--danger);
        font-size: 12px;
    }
`;
export const Header = styled.div`
    width: 100%;
    padding-bottom: 20px;
    border-collapse: separate;
    display: flex;
    flex-direction: column;
    h3 {
        margin-bottom: 20px;
    }
    h4 {
        color: var(--black);
        font-weight: 400;
        margin-bottom: 10px;
    }
    .inv-no {
        width: 50%;
        font-size: 24px;
        color: var(--black);
        flex-direction: column;
        h3 {
            margin-top: 10px;
            font-weight: 400;
            font-size: 16px;
        }
    }

    .date {
        width: 25%;
        display: flex;
        justify-content: flex-end;
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        flex-direction: column;
        .date {
            width: 100%;
            justify-content: flex-start;
        }
    }
    .warning {
        color: var(--danger);
        font-size: 12px;
    }
`;
export const Row = styled.div`
    .prefix {
        max-width: 100px;
    }
    display: flex;
    &.right {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    &.services {
        display: flex;
        justify-content: space-around;
        text-align: end;
        width: 100%;
        padding-top: 40px;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--border-color);
        .type {
            overflow-wrap: break-word;
            text-align: left;
        }
        .unit {
            text-align: left;
        }
        .type {
            padding-left: 10px;
            overflow-wrap: break-word;
        }
        p {
            font-size: 14px;
        }
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            flex-direction: row;
        }
    }
    &.service-list {
        display: flex;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            box-shadow: 0 0 3px 3px #ddd;
            border-radius: 5px;
            flex-wrap: wrap;
            margin: 30px 0;
            padding: 5px;
            background: var(--white);
        }
        text-align: left;
        position: relative;
        vertical-align: top;
        line-height: 24px;
        font-weight: 400;
        color: gray;
        border-bottom: 1px solid var(--border-color);
        background: var(--light-gray);
        flex: 1;
        display: flex;
        align-items: center;
        p {
            font-size: 14px;
            line-height: 24px;
            padding: 10px 5px;
            text-align: center;
        }
        span {
            margin-top: 2px;

            @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                width: 13%;
            }
        }
        .price,
        .unit,
        .total {
            text-align: right;
            input {
                font-size: 14px;
            }
        }
        .type,
        .unit {
            text-align: left;
            cursor: help;
            color: var(--purple);
            overflow-wrap: break-word;
        }
        .type {
            padding-left: 10px;
            overflow-wrap: break-word;
        }

        .input-container {
            margin-bottom: 0px;
        }
        input {
            border-bottom: none;
            text-align: end;
        }
        .select-wrapper {
            width: calc(20% - 15px);
            @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                width: 100%;
            }
        }
        .select__control {
            border: none;
        }
        .select__placeholder,
        svg {
            color: var(--purple) !important;
        }
    }
    &.invoice-dates-text {
        display: flex;
        justify-content: space-between;
        .trading-place-tooltip {
            display: flex;
        }
        p {
            font-weight: 400;
            font-size: 16px;
            color: var(--black);
        }

        @media screen and (min-width: ${breakpoints.laptop}px) and (max-width: ${breakpoints.laptopM - 1}px) {
            align-items: self-end;

            .date-input-class {
                margin-left: 5px;
                margin-right: 5px;
            }
        }
        @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
        }
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            grid-template-columns: 1fr;
            p {
                margin-bottom: 10px;
            }
            .input {
                margin-left: 0;
            }
        }
    }

    .type,
    .price,
    .unit,
    .total {
        width: calc(20% - 15px);
        > div {
            width: 100%;
            input {
                width: 100%;
            }
        }
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        flex-direction: column;
        align-items: end;
        &.inv-no {
            align-items: start;
            width: 100%;
            margin-bottom: 10px;
        }
        .input {
            width: 100%;
        }
    }
    .validation-error {
        > p {
            display: none;
        }
    }
    &.accounts {
        align-items: center;
        font-size: 14px;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            align-items: flex-start;
        }
        .select-wrapper {
            width: auto;
            .select__single-value,
            .select__placeholder,
            .select__input-container {
                color: var(--purple);
                font-size: 14px;
            }
            .select__menu {
                min-width: 300px;
                .select__menu-list {
                    .select__option {
                        font-size: 14px;
                        margin-top: 15px;
                        color: var(--gray);
                        &:hover {
                            color: var(--white);
                            background: var(--purple);
                        }
                    }
                    .select__option:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
    }
    &.foreign-accounts {
        align-items: center;
        font-size: 14px;

        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            align-items: flex-start;
        }
        .select-wrapper {
            width: auto;
            .select__single-value,
            .select__placeholder,
            .select__input-container {
                color: var(--purple);
                font-size: 14px;
            }
            .select__menu {
                min-width: 300px;
                .select__menu-list {
                    .select__option {
                        font-size: 14px;
                        margin-top: 15px;
                        color: var(--gray);
                        &:hover {
                            color: var(--white);
                            background: var(--purple);
                        }
                    }
                    .select__option:last-child {
                        border-bottom: none;
                    }
                }
            }
            .select__control {
                margin-left: 0;
            }
        }
    }
    .agency-bank-account {
        font-weight: 700;
    }
    .prefix-number {
        display: flex;
        align-items: center;
        margin-right: 10%;
        gap: 20px;
        p {
            margin-right: 5px;
        }

        @media screen and (min-width: ${breakpoints.laptop}px) and (max-width: ${breakpoints.laptopM - 1}px) {
            margin-right: 5%;
        }
    }
    .input-wrapper {
        position: relative;
        display: inline-block;
    }

    .suffix {
        position: absolute;
        left: 68px;
        transform: translateY(-155%);
        pointer-events: none;
        color: var(--gray);
        font-size: 16px;

        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            transform: translateY(-150%);
        }
    }
    .preffix {
        position: absolute;
        left: -10px;
        top: 62%;
        transform: translateY(-50%);
        pointer-events: none;
        color: var(--gray);
        font-size: 16px;
    }
`;
export const StyledInput = styled.div`
    display: flex;
    flex-direction: row;
    width: 20%;
    padding: 10px 5px;

    span {
        margin-left: 5px;
    }
    .input-container {
        height: 34px;
    }
`;
export const Agency = styled.div`
    display: flex;
    > div {
        width: 50%;
        &:nth-child(2) {
            p {
                font-weight: 600;
            }
        }
    }
    .select__control {
        margin-left: 8px;
    }
    h3 {
        margin-top: 15px;
        margin-bottom: 15px;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            font-size: 18px;
        }
        overflow-wrap: anywhere;
    }
    p {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 30px;
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        flex-direction: column;
        > div {
            width: 100%;
        }
    }
`;
export const AddServiceButton = styled.div`
    padding: 30px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--purple);
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    .plus {
        cursor: pointer;
        text-align: center;
        font-size: 50px;
        border: 5px solid;
        border-radius: 50%;
        width: 90px;
        height: 90px;
        padding: 0;
        padding-top: 5px;
        color: var(--purple);
    }
    svg {
        height: 50px;
        width: 50px;
    }
    p {
        color: var(--purple);
        font-style: italic;
        font-size: 20px;
        margin-top: 20px;
    }
`;
export const AddServiceCurrency = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding: 18px;
    display: flex;
    align-items: center;
    .select-wrapper {
        margin-left: 10px;
        > div {
            display: flex;
            align-items: center;
        }
        label {
            font-size: 15px;
            color: var(--gray);
            display: flex;
            text-transform: uppercase;
        }
        .select__single-value,
        .select__placeholder,
        .select__input-container {
            color: var(--gray);
        }
        .currency-select {
            display: flex;
            align-items: center;
            .select__single-value,
            .select__placeholder,
            .select__input-container {
                text-transform: uppercase;
                font-size: 14px;
            }
            div {
                .select__option {
                    text-transform: uppercase;
                }
            }
        }
    }
`;
export const AddService = styled.div`
    display: flex;
    color: var(--purple);
    cursor: pointer;
    svg {
        height: 12px;
        width: 12px;
        margin-right: 4px;
    }
`;
export const Total = styled.div`
    text-align: right;
    border-bottom: 1px solid var(--border-color);
    padding: 35px 18px 35px 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 0fr;
    p {
        text-transform: uppercase;
        color: var(--gray);
        margin-top: 15px;
    }
    h3 {
        font-size: 30px;
        clear: right;
        font-weight: 400;
        color: var(--purple);
        margin-left: 30px;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            font-size: 18px;
        }
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        p {
            margin-top: 0px;
            font-size: 12px !important;
        }
    }
`;

export const Comments = styled.div`
    padding-top: 20px;

    p {
        margin-top: 5px;
        font-size: 14px;
        line-height: 20px;
    }
    .input {
        width: 100%;
    }
    :first-of-type {
        width: 30%;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            width: 100%;
        }
    }
    :nth-of-type(2) {
        width: 65%;
        margin-left: 5%;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            width: 100%;
        }
    }
    textarea {
        font-size: 14px;
    }
`;
export const Footer = styled.div`
    width: 100%;
    padding-top: 20px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        flex-direction: column;
        img {
            margin-top: 10px;
        }
    }
`;

export const MobileRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 95%;

    p {
        width: 100% !important;
        margin: 0px;
        padding: 0px;
    }

    span {
        font-size: 12px;
        width: 100%;
        color: var(--gray) !important;
    }
    > div {
        width: 100%;
        :nth-of-type(2) {
            margin-left: 10px;
        }
        .type {
            background: var(--light-gray);
        }
    }
    .total {
        color: var(--purple) !important;
    }
    .select-wrapper {
        background: var(--light-gray);
        width: auto;
    }

    .price {
        width: 90%;
        height: 34px;
        background: var(--light-gray);
    }
    span {
        margin-left: 5px;
        width: auto;
    }
    input {
        margin-top: -10px;
    }
`;

export const MobileTotal = styled.div`
    float: left;
    width: 100%;
    padding-left: 5px;
    .total {
        width: 100% !important;
    }

    span {
        font-size: 12px;
        color: var(--gray) !important;
        float: right;
        margin-right: 15px;
    }
`;
export const GroupedMemoSection = styled.div`
    display: flex;
    flex-direction: row;

    @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        flex-direction: column-reverse;
    }
`;

export const MemoDisplaySection = styled.div`
    width: 30%;
    margin-left: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    max-width: 500px;
    min-height: 178px;
    padding: 20px;

    .selected-memo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 10px;
        font-size: 1rem;
        background-color: var(--light-gray);
        color: var(--black);

        span {
            margin-right: 10px;
            flex: 1;
            word-wrap: break-word;
        }

        svg {
            cursor: pointer;
            color: var(--purple);
            transition: color 0.3s;

            &:hover {
                color: #900;
            }
        }
    }

    .select-memos {
        margin-top: 10px;
    }

    .select-memos select {
        width: 100%;
        padding: 10px;
        font-size: 1rem;
        border: 1px solid #ddd;
        border-radius: 5px;
        background-color: #fff;
        color: #333;
        outline: none;
        transition: border-color 0.3s;

        &:focus {
            border-color: #0056b3;
        }

        &:hover {
            border-color: #888;
        }
    }

    .select-memos select:disabled {
        background-color: #f0f0f0;
        cursor: not-allowed;
    }

    @media screen and (max-width: ${MQ_BREAKPOINTS.mobileXS}) {
        padding: 20px 10px;
    }

    @media screen and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
        width: 35%;
    }
    @media screen and (max-width: ${breakpoints.laptopM - 1}px) {
        width: 45%;
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        width: 50%;
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        width: 100%;
        padding: 20px 0;
    }
`;

export const MemoSelect = styled.div`
    width: 30%;
    margin-bottom: 20px;
    margin-top: 20px;
    max-width: 500px;
    min-height: 178px;
    padding: 20px;
    border-radius: 8px;
    h3 {
        font-size: 18px;
        color: var(--black);
        margin-bottom: 20px;
        font-weight: 600;
    }

    .selected-memos {
        margin-bottom: 20px;
    }
    .validation-message {
        color: var(--danger);
        font-size: 12px;
    }

    @media screen and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
        width: 35%;
    }
    @media screen and (max-width: ${breakpoints.laptopM - 1}px) {
        width: 45%;
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        width: 50%;
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        width: 100%;
    }
`;
