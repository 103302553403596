import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useNavigate, useBlocker } from 'react-router-dom';
import moment from 'moment';
import { isEmpty, some } from 'lodash';
import { SendDocumentFormData } from '../../../../types/communication/document';
import { ObjectKeys } from '../../../../types/objectKeys';
import communication from '../../../../communication';
import useTranslations from '../../../../hooks/useTranslation';
import { getAccessToken } from '../../../../functions/auth';
import useLanguageStore from '../../../../store/language';
import useAgencyStore from '../../../../store/agency';
import ErrorModal from '../../../../components/errorModal';
import Loader from '../../../../components/loader';
import Sidebar from '../../../../components/sideModal';
import ChangeModal from '../../../../components/changeModal';
import InvoiceClients from '../../../../components/invoiceClients';
import Modal from '../../../../components/modal';
import Success from '../../../../components/successModal';
import EditClientModal from '../../../myBusiness/clients/editClientModal';
import AddClientModal from '../../../myBusiness/clients/addClientModal';
import SendModal from '../../common/sendModal';
import PreviewInvoiceModalPlain from '../../common/previewInvoiceModalPlain';
import PreviewInvoiceModalArtistic from '../../common/previewInvoiceModalArtistic';
import PreviewInvoiceModalBusiness from '../../common/previewInvoiceModalBusiness';
import PreviewInvoiceModalInvoicePro from '../../common/previewInvoiceModalInvoicePro';
import PreviewInvoiceModalPlain2 from '../../common/previewInvoiceModalPlain2';
import PreviewInvoiceModalPlainSanta from '../../common/previewInvoiceModalPlainSanta';
import PreviewInvoiceModalPlainPenguin from '../../common/previewInvoiceModalPlainPenguin';
import useUserStore from '../../../../store/user';
import { Body, Container, Invoice } from '../../common//style';
import MobileHeader from '../../common/header/mobileHeader';
import WebHeader from '../../common/header/webHeader';
import InvoiceEdit from '../invoiceEdit';
import MobileFooter from '../../common/footer/mobileFooter';
import WebFooter from '../../common/footer/webFooter';
import getAllClients from '../../../myBusiness/clients/getAllClients';
import InvoiceForeignEdit from '../invoiceForeignEdit';
import useYearsStore from '../../../../store/years';
import getAllAInvoices from '../getAllAInvoices';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Warning from '../../../../components/successModal';
import WarningClientCard from '../../../myBusiness/clients/warningCard';
import ConfirmModal from '../../../../components/confirmModal';
import useAllBanksQuery from '../../../../react-query/useAllBanksQuery';
import { validateIbanSwift } from '../../common/ibanSwiftValidate';
import useResponsive from '../../../../hooks/responsive/useResponsive';

const NewInvoice: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { agency } = useAgencyStore();
    const token = getAccessToken();
    const { userInfo } = useUserStore();
    const preferredTemplate = userInfo?.preferred_template?.data.slug;

    const invoiceType = pathname.includes('domestic') ? 'domestic' : 'foreign';
    const isAdvance = pathname.includes('advance');

    const { data } = useAllBanksQuery();
    const [badSwiftOrIban, setBadSwiftOrIban] = useState<boolean>(false);
    const [badSwiftOrIbanModal, setBadSwiftOrIbanModal] = useState<boolean>(false);

    const swiftIbanOptions =
        agency.bankAccounts.data.length > 0
            ? agency.bankAccounts.data
                  .filter((account: ObjectKeys) => account.type === 'foreign')
                  .map((account: ObjectKeys) => ({
                      value: account,
                  }))
            : [];

    const domesticBankAccounts =
        agency.bankAccounts.data.length > 0
            ? agency.bankAccounts.data
                  .filter((account: ObjectKeys) => account.type === 'domestic')
                  .sort((a: ObjectKeys, b: ObjectKeys) => (b.default === 1 ? 1 : -1)) // Sort by default property
                  .map((account: ObjectKeys) => ({
                      value: account,
                  }))
            : [];

    const [clients, setClients] = useState<ObjectKeys>([]);

    const [invoice, setInvoice] = useState<ObjectKeys>({
        client: { data: {} },
        comment: '',
        bank_account:
            invoiceType === 'foreign'
                ? swiftIbanOptions[0]?.value?.account_number
                : domesticBankAccounts[0]?.value?.account_number,
        bank_account_id: invoiceType === 'foreign' ? '' : domesticBankAccounts[0]?.value?.id,
        currency: invoiceType === 'domestic' ? 'RSD' : 'EUR',
        paid_date: '',
        paid_value: false,
        prepaid_invoice_ids: [],
        rate: 1,
        seal_required: false,
        iban: null,
        swift: null,
        trading_place: '',
        is_prepaid: true,
        prefix: 'A',
        limit8_million_exclude: false,
        type: invoiceType,
        invoice_date: moment().format('YYYY-MM-DD').toString(),
    });
    const [newCreatedClient, setNewCreatedClient] = useState<ObjectKeys>({});

    const [clickedClient, setClickedClient] = useState('');
    const [success, setSuccess] = useState(false);
    const [changeHappened, setChangeHappened] = useState(false);
    const { isMobile } = useResponsive();

    //modals
    const [modalMessage, setModalMessage] = useState('');
    const [menuVisible, setMenuVisible] = useState(false);
    const [editClientModal, setEditClientModal] = useState(false);
    const [addClientModal, setAddClientModal] = useState(false);
    const [deleteClientModal, setDeleteClientModal] = useState(false);
    const [deleteInvoiceModal, setDeleteInvoiceModal] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [clientChanged, setClientChanged] = useState(0);
    const [clientEmails, setClientEmails] = useState<Array<string>>([]);

    const [warningModal, setWarningModal] = useState(false);
    const [clientsDuplicated, setClientsDuplicated] = useState<ObjectKeys>([]);

    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });

    useEffect(() => {
        if (agency && invoiceType && currentLang) {
            const domesticNote = agency.user.data.note_domestic;
            const foreignNote = agency.user.data.note_foreign;
            if (invoiceType === 'domestic') {
                setInvoice({
                    ...invoice,
                    comment: domesticNote ? domesticNote : t('pages.editInvoice.previewModal.due').text,
                });
            } else {
                setInvoice({
                    ...invoice,
                    comment: foreignNote ? foreignNote : 'Payment deadline is 15 days',
                });
            }
        }
    }, [invoiceType, currentLang]);

    useEffect(() => {
        if (!isEmpty(newCreatedClient)) {
            setInvoice((prev: ObjectKeys) => {
                const newInvoiceData = { ...prev };
                newInvoiceData.client_id = newCreatedClient && newCreatedClient?.id;
                newInvoiceData.client.data = newCreatedClient;
                return newInvoiceData;
            });
        }
    }, [newCreatedClient]);

    const updateYears = async (): Promise<void> => {
        const yearsData = await communication.getYears();
        useYearsStore.setState({ years: yearsData?.data?.years });
    };

    const handleClientDelete = async (): Promise<void> => {
        setLoading(true);
        setClientEmails([]);
        await communication
            .deleteClient(clickedClient)
            .then((res: ObjectKeys) => {
                if (res) {
                    getAllClients(setClients, agency?.user_id, invoiceType === 'domestic' ? 0 : 1);
                    setDeleteClientModal(false);
                    setLoading(false);
                    toast.success(t('pages.clients.archiveModal.message').text);
                }
                setInvoice((prev: ObjectKeys) => ({
                    ...prev,
                    client: { data: {} },
                }));
            })
            .catch((error: ObjectKeys) => {
                setLoading(false);
                if (error) {
                    toast.error(error.response.data.message);
                }
            });
    };

    const handleInvoiceDelete = async (): Promise<void> => {
        try {
            const res = await communication.deleteInvoice(invoice.id);
            if (res.status === 200) {
                setDeleteInvoiceModal(false);
                updateYears();
                navigate('/advance-invoices');
            }
        } catch (e: any) {
            setErrorMessage(e.response.data.message);
            setOpenErrorModal(true);
            setLoading(false);
        }
    };

    const isInvoiceValid = (): boolean => {
        const real = invoice.real_invoice_number;
        if (!real || /^0+$/.test(real)) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.missingInvoiceNumber').text);
            setLoading(false);
            return false;
        }
        if (!invoice.services || invoice.services.length === 0) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.missingService').text);
            setLoading(false);
            return false;
        }

        if (
            some(
                invoice.services,
                (s) =>
                    Math.ceil(Number(s.pivot.quantity.replace(',', '.'))) === 0 ||
                    Math.ceil(Number(s.pivot.price_per_unit.replace(',', '.'))) === 0 ||
                    s.pivot.measurement_unit === '',
            )
        ) {
            setModalMessage(t('pages.editInvoice.invalidInvoice').text);
            setSuccess(true);
            setLoading(false);
            return false;
        }

        if (!invoice.client_id || Object.keys(invoice.client.data).length === 0) {
            setModalMessage(t('pages.editInvoice.missingClient').text);
            setSuccess(true);
            setLoading(false);
            return false;
        }

        if (invoice.trading_place === '') {
            setModalMessage(t('pages.editInvoice.missingTradingPlace').text);
            setSuccess(true);
            setLoading(false);
            return false;
        }
        if (
            !validateIbanSwift(
                invoice.iban,
                invoice.swift,
                invoice.bank_account_id,
                returnBankCodeFromId(invoice.bank_id) === '000' || invoiceType !== 'foreign',
                agency,
                swiftIbanOptions,
                data,
            )
        ) {
            setBadSwiftOrIban(true);
            setBadSwiftOrIbanModal(true);
            setChangeHappened(false);
            setLoading(false);
            return false;
        }

        return true;
    };

    useEffect(() => {
        setChangeHappened(false);
    }, []);

    //Add new invoice
    const handleAddNewInvoice = async (): Promise<any> => {
        if (!isInvoiceValid()) return;
        try {
            setChangeHappened(false);
            setLoading(true);
            const newInvoiceData = {
                ...invoice,
                auto_increment: true,
                bank_account: invoice.bank_account,
                cancel_number: false,
                cancelled: false,
                client_id: invoice?.client?.data?.id,
                currency: invoice.currency,
                custom_number: false,
                custom_text: false,
                iban: invoiceType === 'foreign' ? invoice?.iban : undefined,
                invoice_date: moment(invoice.invoice_date).format('YYYY-MM-DD').toString(),
                invoice_number: invoice.real_invoice_number,
                invoice_per_owner: true,
                is_custom: false,
                is_prepaid: true,
                paid_date: false,
                prefix: 'A',
                rate: Number(invoice.rate),
                sef_invoice_number: invoice.sef_invoice_number ? invoice.sef_invoice_number : null,
                services: invoice.services.map((service: ObjectKeys, i: number) => ({
                    ...service,
                    index: i,
                    id: service.id,
                    measurement_unit: service.pivot.measurement_unit,
                    quantity: service.pivot.quantity.replace(',', '.'),
                    price_per_unit: service.pivot.price_per_unit.replace(',', '.'),
                    pivot: {
                        discount: 0,
                        index: i, // Add index to pivot
                        measurement_unit: service.pivot.measurement_unit,
                        price_per_unit: service.pivot.price_per_unit.replace(',', '.'),
                        quantity: service.pivot.quantity.replace(',', '.'),
                        total_price: service.pivot.total_price,
                    },
                })),
                status: 'open',
                swift: invoiceType === 'foreign' ? invoice?.swift : undefined,
                trading_date: moment(invoice.trading_date).format('YYYY-MM-DD').toString(),
                trading_place: invoice.trading_place,
                user_id: agency?.user_id,
                value: Number(invoice.value),
            };

            const res = await communication.newInvoice(newInvoiceData, invoiceType);
            if (res && res.data && res.data.data) {
                const new_invoice = res.data.data;
                setLoading(false);
                return {
                    id: new_invoice.id,
                    number: new_invoice.invoice_number,
                    prefix: new_invoice.prefix,
                    agency: new_invoice.original_data?.agency.full_name,
                    client: new_invoice.client.data.company_name,
                    amount: new_invoice.total_for_payment_in_rsd,
                    account: new_invoice.bank_account,
                    currency: new_invoice.currency,
                    foreign: new_invoice.type === 'foreign' ? true : false,
                    prepaid: true,
                };
            }
        } catch (e: any) {
            setErrorMessage(e.response.data.message);
            setOpenErrorModal(true);
            setLoading(false);
        }
    };

    const handleSelectedClient = (client: ObjectKeys): void => {
        setClientChanged(clientChanged + 1);
        setChangeHappened(true);
        if (client) {
            const emails = client.clientEmails?.data?.map((item: ObjectKeys) => item.email) || [];
            setClientEmails(emails.length > 0 ? emails : client.email ? [client.email] : []);
        } else {
            setClientEmails([]);
        }
        setInvoice((prev: ObjectKeys) => {
            const newInvoiceData = { ...prev };
            newInvoiceData.client_id = client?.id;
            newInvoiceData.client.data = client;
            newInvoiceData.currency = client?.currency;
            if (client.is_foreign || client.type === 'civil') {
                newInvoiceData.sef_invoice_number = null;
            }
            return newInvoiceData;
        });
    };

    useEffect(() => {
        const matchingClient = Array.isArray(clients.data)
            ? clients.data.find((client: ObjectKeys) => client.id === invoice.client.data.id)
            : null;
        matchingClient && handleSelectedClient(matchingClient);
    }, [clients]);

    //Invoice Download
    const handleDownloadInvoice = async (): Promise<void> => {
        try {
            const newCreatedInvoice = await handleAddNewInvoice();
            if (newCreatedInvoice?.error) {
                setErrorMessage(newCreatedInvoice.error);
                setOpenErrorModal(true);
                setLoading(false);
                return;
            }
            if (newCreatedInvoice) {
                setLoading(true);
                communication
                    .downloadInvoice(
                        newCreatedInvoice.id,
                        token,
                        invoice.invoice_number,
                        invoice.currency !== 'RSD',
                        false,
                        undefined,
                    )
                    .then(() => {
                        setLoading(false);
                        updateYears();
                        setLoading(false);
                        navigate('/advance-invoices');
                    });
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent): void => {
            if (changeHappened) {
                event.preventDefault();
                event.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [changeHappened]);

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => changeHappened && currentLocation.pathname !== nextLocation.pathname,
    );

    // const validateIbanSwift = (iban: string, swift: string, bank_acc_id: string, skip: boolean): boolean => {
    //     if (skip || !bank_acc_id) {
    //         return true;
    //     }
    //     if (agency && swiftIbanOptions.length > 0) {
    //         if (!iban || !swift) {
    //             return false;
    //         }
    //         const ibanRegex = /^[A-Z0-9]{15,34}$/;
    //         if (!ibanRegex.test(iban)) {
    //             return false;
    //         }
    //         const ibanFirst4Digits = iban.slice(0, 4);
    //         if (ibanFirst4Digits !== 'RS35') {
    //             return false;
    //         }
    //         const bankCode = iban.slice(4, 7);
    //         const bank = data?.find((banka: { code: string }) => banka.code === bankCode);
    //         if (!bank) {
    //             return false;
    //         }
    //         if (swift !== bank.swift) {
    //             return false;
    //         }
    //         const matchedAccount = swiftIbanOptions.find(
    //             (banka: { value: { id: string; bank: { data: { id: string } } } }) => banka.value.id === bank_acc_id,
    //         );
    //         if (!matchedAccount || !matchedAccount.value.bank) {
    //             return false;
    //         }
    //         if (bank.id === matchedAccount.value.bank.data.id) {
    //             return true;
    //         } else {
    //             return false;
    //         }
    //     } else {
    //         return false;
    //     }
    // };

    const returnBankCodeFromId = (bankId: string): string => {
        if (data) {
            const bank = data.find((banka: { id: string }) => banka.id === bankId);
            if (bank && bank.code) {
                return bank.code;
            } else {
                return '';
            }
        } else {
            return '';
        }
    };

    return (
        <>
            {loading && <Loader />}
            <ToastContainer />
            <Modal modalVisible={blocker.state === 'blocked'} closeModal={() => blocker.reset && blocker.reset()}>
                <ConfirmModal
                    message={t('warnings.youHaveUnsavedChanges').text}
                    close={() => blocker.reset && blocker.reset()}
                    action={() => blocker.proceed && blocker.proceed()}
                />
            </Modal>
            {warningModal && (
                <Modal modalVisible={warningModal} closeModal={() => setWarningModal(false)}>
                    <Warning
                        close={() => {
                            setWarningModal(false);
                        }}
                    >
                        <WarningClientCard clients={clientsDuplicated} />
                    </Warning>
                </Modal>
            )}
            {badSwiftOrIban && badSwiftOrIbanModal && (
                <Modal
                    modalVisible={true}
                    preventOutsideClickClose={false}
                    closeModal={() => {
                        setBadSwiftOrIbanModal(false);
                    }}
                >
                    <ErrorModal
                        t={t}
                        setOpenModal={() => {
                            navigate('/agency/bank-accounts?=foreign');
                            setBadSwiftOrIbanModal(false);
                        }}
                        errorMessage={t('pages.invoices.bad_iban_or_swift').text}
                        buttonName={t('pages.invoices.enter').text}
                    />
                </Modal>
            )}
            {openErrorModal && (
                <Modal modalVisible={openErrorModal} closeModal={() => setOpenErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            {success && (
                <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                    <Success close={() => setSuccess(false)} message={modalMessage} />
                </Modal>
            )}
            {sendModal && (
                <Sidebar close={() => setSendModal(false)}>
                    <SendModal
                        close={() => setSendModal(false)}
                        handleAddNewInvoice={handleAddNewInvoice}
                        setFormData={setSendData}
                        formData={sendData}
                        invoice={invoice}
                        t={t}
                        success={() => {
                            setSendModal(false);
                            navigate('/advance-invoices');
                        }}
                        loading={(e: boolean) => setLoading(e)}
                        clientEmails={clientEmails}
                        errorMessage={(mess: string) => {
                            setErrorMessage(mess);
                            setOpenErrorModal(true);
                        }}
                        modalVisible={sendModal}
                        editInvoice={(updatedInvoice: ObjectKeys) => {
                            setInvoice(updatedInvoice);
                        }}
                    />
                </Sidebar>
            )}
            {addClientModal && (
                <Sidebar close={() => setAddClientModal(false)}>
                    <AddClientModal
                        t={t}
                        clickedClient={invoiceType === 'domestic' ? 'domestic' : 'foreign'}
                        userId={agency?.user_id}
                        setAddClientModal={setAddClientModal}
                        setClients={setClients}
                        tabIndex={invoiceType === 'domestic' ? 0 : 1}
                        setNewCreatedClient={setNewCreatedClient}
                        isDuplicate={(duplicated: Array<ObjectKeys>) => {
                            setWarningModal(true);
                            setClientsDuplicated(duplicated);
                        }}
                    />
                </Sidebar>
            )}
            {editClientModal && (
                <Sidebar close={() => setEditClientModal(false)}>
                    <EditClientModal
                        t={t}
                        clickedClient={clickedClient}
                        userId={agency?.user_id}
                        setEditClientModal={setEditClientModal}
                        setClients={setClients}
                        tabIndex={0}
                    />
                </Sidebar>
            )}
            {deleteClientModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteClientModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={() => {
                            handleClientDelete();
                            setDeleteClientModal(false);
                        }}
                        close={() => {
                            setDeleteClientModal(false);
                        }}
                        message={`${t('pages.clients.archiveModal.title').text}\n${
                            t('pages.clients.archiveModal.description').text
                        }`}
                    />
                </Modal>
            )}
            {deleteInvoiceModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteInvoiceModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={handleInvoiceDelete}
                        close={() => {
                            setDeleteInvoiceModal(false);
                        }}
                        message={t('pages.editInvoice.deleteInvoice').text}
                    />
                </Modal>
            )}
            {previewModal && (
                <Modal
                    width="700px"
                    className="preview-modal"
                    modalVisible={true}
                    hasCloseButton={true}
                    closeModal={() => {
                        setPreviewModal(false);
                    }}
                >
                    {preferredTemplate === 'plain' && (
                        <PreviewInvoiceModalPlain
                            agency={agency}
                            invoice={invoice}
                            title="advance"
                            type={invoiceType}
                            note={invoice?.comment}
                        />
                    )}
                    {/* {preferredTemplate === 'Artistic' && (
                        <PreviewInvoiceModalArtistic
                            agency={agency}
                            invoice={invoice}
                            title="advance"
                            type={invoiceType}
                            note={invoice?.comment}
                        />
                    )} */}
                    {preferredTemplate === 'business' && (
                        <PreviewInvoiceModalBusiness
                            agency={agency}
                            invoice={invoice}
                            title="advance"
                            type={invoiceType}
                            note={invoice?.comment}
                        />
                    )}
                    {preferredTemplate === 'invoicePro' && (
                        <PreviewInvoiceModalInvoicePro
                            agency={agency}
                            invoice={invoice}
                            title="advance"
                            type={invoiceType}
                            note={invoice?.comment}
                        />
                    )}
                    {preferredTemplate === 'plain_2' && (
                        <PreviewInvoiceModalPlain2
                            agency={agency}
                            invoice={invoice}
                            title="advance"
                            type={invoiceType}
                            note={invoice?.comment}
                        />
                    )}
                    {preferredTemplate === 'santa' && (
                        <PreviewInvoiceModalPlainSanta
                            agency={agency}
                            invoice={invoice}
                            title="advance"
                            type={invoiceType}
                            note={invoice?.comment}
                        />
                    )}
                    {preferredTemplate === 'penguin' && (
                        <PreviewInvoiceModalPlainPenguin
                            agency={agency}
                            invoice={invoice}
                            title="advance"
                            type={invoiceType}
                            note={invoice?.comment}
                        />
                    )}
                </Modal>
            )}

            <Container>
                {isMobile ? (
                    <MobileHeader
                        t={t}
                        handleUpdateInvoice={() => {
                            handleAddNewInvoice().then((response: any) => {
                                if (!response) return;
                                if (response?.error) {
                                    setErrorMessage(response.error);
                                    setOpenErrorModal(true);
                                    return;
                                } else {
                                    navigate('/advance-invoices');
                                }
                            });
                        }}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={() => {
                            isInvoiceValid() && setPreviewModal(true);
                        }}
                        setSendModal={() => {
                            isInvoiceValid() && setSendModal(true);
                        }}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={() => {
                            handleAddNewInvoice().then((response: any) => {
                                if (!response) return;
                                if (response?.error) {
                                    setErrorMessage(response.error);
                                    setOpenErrorModal(true);
                                    return;
                                } else {
                                    navigate('/advance-invoices');
                                }
                            });
                        }}
                        invoice={invoice}
                        newInvoice={true}
                        title={pathname.includes('advance') ? 'advance' : 'invoice'}
                        handleDownloadInvoice={handleDownloadInvoice}
                    />
                ) : (
                    <WebHeader
                        t={t}
                        handleUpdateInvoice={() => {
                            handleAddNewInvoice().then((response: any) => {
                                if (!response) return;
                                if (response?.error) {
                                    setErrorMessage(response.error);
                                    setOpenErrorModal(true);
                                    return;
                                } else {
                                    navigate('/advance-invoices');
                                }
                            });
                        }}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={() => {
                            isInvoiceValid() && setPreviewModal(true);
                        }}
                        setSendModal={() => {
                            isInvoiceValid() && setSendModal(true);
                        }}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={() => {
                            handleAddNewInvoice().then((response: any) => {
                                if (!response) return;
                                if (response?.error) {
                                    setErrorMessage(response.error);
                                    setOpenErrorModal(true);
                                    return;
                                } else {
                                    navigate('/advance-invoices');
                                }
                            });
                        }}
                        invoice={invoice}
                        newInvoice={true}
                        title={pathname.includes('advance') ? 'advance' : 'invoice'}
                        handleDownloadInvoice={handleDownloadInvoice}
                    />
                )}
                <Body className="new-invoice">
                    <InvoiceClients
                        t={t}
                        setClients={setClients}
                        clients={clients}
                        setAddClientModal={setAddClientModal}
                        agency={agency}
                        handleSelectedClient={handleSelectedClient}
                        addClientModal={addClientModal}
                        deleteClientModal={deleteClientModal}
                        editClientModal={editClientModal}
                        setClickedClient={setClickedClient}
                        setEditClientModal={setEditClientModal}
                        setDeleteClientModal={setDeleteClientModal}
                        invoice={invoice}
                        type={
                            pathname.includes('foreign') ? 'foreign' : pathname.includes('domestic') ? 'domestic' : ''
                        }
                        loading={(isLoading: boolean) => setLoading(isLoading)}
                    />
                    {agency && invoice && (
                        <Invoice>
                            {pathname.includes('domestic') ? (
                                <InvoiceEdit
                                    name={'advance'}
                                    formData={invoice}
                                    setFormData={setInvoice}
                                    agency={agency}
                                    invoice={invoice}
                                    handleTotal={(sum: any) => {
                                        setInvoice({
                                            ...invoice,
                                            value: sum,
                                            value_in_rsd: sum,
                                            total_for_payment: sum,
                                            total_for_payment_in_rsd: sum,
                                        });
                                    }}
                                    isMobile={isMobile}
                                    newInvoice={true}
                                    type={invoiceType}
                                    setLoading={setLoading}
                                />
                            ) : (
                                <InvoiceForeignEdit
                                    name={'advance'}
                                    formData={invoice}
                                    setFormData={setInvoice}
                                    agency={agency}
                                    invoice={invoice}
                                    handleTotal={(sum: any) => {
                                        setInvoice({
                                            ...invoice,
                                            value: sum,
                                            total_for_payment: sum,
                                        });
                                    }}
                                    isMobile={isMobile}
                                    newInvoice={true}
                                    type={invoiceType}
                                    setLoading={setLoading}
                                    clientChanged={clientChanged}
                                    handleChangeHappend={(change: boolean) => {
                                        setChangeHappened(change);
                                    }}
                                />
                            )}
                        </Invoice>
                    )}
                    {isMobile ? (
                        <MobileFooter
                            handleUpdateInvoice={() => {
                                handleAddNewInvoice().then((response: any) => {
                                    if (!response) return;
                                    if (response?.error) {
                                        setErrorMessage(response.error);
                                        setOpenErrorModal(true);
                                        return;
                                    } else {
                                        navigate('/advance-invoices');
                                    }
                                });
                            }}
                            setDeleteInvoiceModal={setDeleteInvoiceModal}
                            setPreviewModal={() => {
                                isInvoiceValid() && setPreviewModal(true);
                            }}
                            setSendModal={() => {
                                isInvoiceValid() && setSendModal(true);
                            }}
                            handleDownloadInvoice={handleDownloadInvoice}
                            invoice={invoice}
                        />
                    ) : (
                        <WebFooter
                            t={t}
                            handleUpdateInvoice={() => {
                                handleAddNewInvoice().then((response: any) => {
                                    if (!response) return;
                                    if (response?.error) {
                                        setErrorMessage(response.error);
                                        setOpenErrorModal(true);
                                        return;
                                    } else {
                                        navigate('/advance-invoices');
                                    }
                                });
                            }}
                            setDeleteInvoiceModal={setDeleteInvoiceModal}
                            setPreviewModal={() => {
                                isInvoiceValid() && setPreviewModal(true);
                            }}
                            setSendModal={() => {
                                isInvoiceValid() && setSendModal(true);
                            }}
                            handleDownloadInvoice={handleDownloadInvoice}
                            invoice={invoice}
                        />
                    )}
                </Body>
            </Container>
        </>
    );
};
export default NewInvoice;
