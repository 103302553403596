import { FunctionComponent } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { getAccessToken } from '../functions/auth';
import Navbar from '../components/navbar';
import styled from 'styled-components';
import { TopBar } from '../components/topBar/TopBar';
import { NAVBAR_WIDTH } from '../constants/index';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../constants/breakpoints';

const Private: FunctionComponent = () => {
    const token = getAccessToken();
    const isLaptopWidth = useMediaQuery({
        query: `(max-width: ${breakpoints.laptop - 1}px)`,
    });
    const location = useLocation();

    const agencyPage = location.pathname.startsWith('/agency');

    if (!token) {
        return <Navigate to="/login" />;
    }

    return (
        <>
            {!isLaptopWidth ? (
                <>
                    <WebWrapper className="body private" navbarWidth={`${NAVBAR_WIDTH}px`}>
                        <Navbar />
                        <div className="right-side-wrapper">
                            <TopBar />
                            <div className={!agencyPage ? 'content-wrapper' : 'agency-wrapper'}>
                                <Outlet />
                            </div>
                        </div>
                    </WebWrapper>
                </>
            ) : (
                <>
                    <Navbar />
                    <MobileBody className="body private">
                        <Outlet />
                    </MobileBody>
                </>
            )}
        </>
    );
};

interface WebWrapperProps {
    navbarWidth: string;
}

const WebWrapper = styled.div<WebWrapperProps>`
    background: var(--body-gray);
    display: flex;
    height: 100%;
    .right-side-wrapper {
        width: 100vw;
        position: relative;
        height: 100%;
        .content-wrapper,
        .agency-wrapper {
            padding: 30px 100px;
            position: relative;
            height: 100%;
        }

        @media only screen and (min-width: ${breakpoints.laptop}px) and (max-width: ${breakpoints.laptop + 225}px) {
            .agency-wrapper {
                padding: 0;
            }
        }
    }
`;

const MobileBody = styled.div`
    padding: 30px 15px;
    padding-bottom: 104px;
    width: 100%;
    background: var(--body-gray);
    min-height: calc(100vh - 56px);
`;
export default Private;
