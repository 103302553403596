import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faSquare, faCheckSquare, faUserCheck, faPrint } from '@fortawesome/free-solid-svg-icons';
import SelectComponent from '../../../components/select';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import NoItems from '../../../components/noItems';
import moment from 'moment';
import communication from '../../../communication';
import TransferOrderPreviewModal from '../../../components/transferOrderPreviewModal';
import Modal from '../../../components/modal';
import useAgencyStore from '../../../store/agency';
import { generateQrCode } from '../../../communication/qr';
import { ObjectKeys } from '../../../types/objectKeys';
import TransferOrderTemplate from '../../../components/transferOrderTemplate';
import ConfirmModal from '../../../components/confirmModal';
import ManageOrdersModal from './manageOrdersModal';
import InfoModal from '../../../components/infoModal';
import { useNavigate, useParams } from 'react-router-dom';
import Input, { InputComponentType } from '../../../components/input';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Success from './successModal';
import TaxSolutionComponent from './taxSolution';
import ToggleSwitch from '../../../components/input/switch';
import TransferOrderSkeleton from '../../../components/transferOrderSkeleton';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';

const TransferOrders: FunctionComponent = () => {
    const paramsYear = useParams().year;
    const open_manage_modal = useParams().generate;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [previewModal, setPreviewModal] = useState<boolean>(false);

    const [selectedTransfer, setSelectedTransfer] = useState<ObjectKeys>({});
    const [yearsOptions, setYearsOptions] = useState<Array<{ value: any; label: any }>>([]);

    const [ecotaxTemplates, setEcotaxTemplates] = useState<ObjectKeys>([]);
    const [ecotaxQuarterlyTemplates, setEcotaxQuarterlyTemplates] = useState<ObjectKeys>([]);

    const [isDeleteModalActive, setIsDeleteModalActive] = useState<boolean>(false);
    const [isInfoModalActive, setIsInfoModalActive] = useState<boolean>(false);
    const [manageOrdersModalActive, setIsManageOrdersModalActive] = useState<boolean>(false);

    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [qrCode, setQrCode] = useState<string>('');
    const [isQrCode, setIsQrCode] = useState<boolean>(false);
    const agency = useAgencyStore((props) => props.agency);

    const [selectedYear, setSelectedYear] = useState<string>(paramsYear ? paramsYear : moment().year().toString());

    const navigate = useNavigate();
    const [paymentDate, setPaymentDate] = useState<Date | null>(null);
    const [isChecked, setChecked] = useState(false);
    const [paidCheck, setPaidCheck] = useState<boolean>(false);
    const [showChekInput, setShowChekInput] = useState<boolean>(false);
    const [warningModal, setWarningModal] = useState(false);

    const [renderKey, setRenderKey] = useState<number>(0);
    const [renderKey2, setRenderKey2] = useState<number>(0);

    const [annualShow, setAnnualShow] = useState<boolean>(false);

    const [currentEcoTaxOrder, setCurrentEcoTaxOrder] = useState<ObjectKeys>({
        id: '',
        recipient: '',
        orderer: '',
        total_amount: 0,
        payment_slip_heading: '',
        purpose_of_payment: '',
        recipient_account: '',
        debtor_account: '',
        currency: '',
        model_number_recipient: '',
        call_number_recipient: '',
        payment_code: '',
        status: '',
        paid_amount: 0,
        paid_date: '',
        quarter: false,
    });

    const [quarterOptions, setQuarterOptions] = useState<Array<{ value: number; label: string }>>([
        { value: 0, label: `1. ${t('pages.eco_tax_transfer.quarter').text}` },
        { value: 1, label: `2. ${t('pages.eco_tax_transfer.quarter').text}` },
        { value: 2, label: `3. ${t('pages.eco_tax_transfer.quarter').text}` },
        { value: 3, label: `4. ${t('pages.eco_tax_transfer.quarter').text}` },
    ]);

    const currentQuarterIndex = moment().quarter();
    const [quarter, setQuarter] = useState<number>(0);

    useEffect(() => {
        if (open_manage_modal) {
            setIsManageOrdersModalActive(true);
        }
    }, [open_manage_modal]);

    useEffect(() => {
        // Revoke quarterOptions when translation changes
        setQuarterOptions([
            { value: 0, label: `1. ${t('pages.eco_tax_transfer.quarter').text}` },
            { value: 1, label: `2. ${t('pages.eco_tax_transfer.quarter').text}` },
            { value: 2, label: `3. ${t('pages.eco_tax_transfer.quarter').text}` },
            { value: 3, label: `4. ${t('pages.eco_tax_transfer.quarter').text}` },
        ]);
    }, [currentLang]);

    useEffect(() => {
        if (paramsYear) {
            setSelectedYear(paramsYear);
            setRenderKey(renderKey + 1);
        }
    }, [paramsYear]);

    const getAllTransferTemplates = async (): Promise<void> => {
        setIsLoading(true);
        communication
            .getAllEcotaxTransfers(selectedYear)
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setEcotaxTemplates(res.data.data);
                    if (res.data.data.length > 0) {
                        const parentData = res.data.data[0];
                        const quarterlyData = parentData.quarterly_eko_taxes.data;

                        const ecotaxQuarterly = quarterlyData.map((item: any) => {
                            return {
                                ...parentData,
                                id: item.id,
                                paid_amount: item.paid_amount,
                                paid_date: item.paid_date,
                                quarter: item.quarter,
                                status: item.status,
                                total_amount: item.total_amount,
                            };
                        });

                        setEcotaxQuarterlyTemplates(ecotaxQuarterly);
                    } else {
                        setEcotaxQuarterlyTemplates([]);
                    }

                    setIsLoading(false);
                }
            })
            .catch((err: any) => {
                setIsLoading(false);
                console.error(err);
            });
    };

    useEffect(() => {
        setRenderKey2(renderKey2 + 1);
    }, [ecotaxTemplates]);

    const generateYearsForFilters = (): void => {
        setYearsOptions([]);
        const currentYear = moment().year();
        const createYear = moment(agency.date_of_registration).year();
        // eslint-disable-next-line for-direction
        for (let i = currentYear; i >= createYear; i--) {
            setYearsOptions((prevState) => [...prevState, { label: i, value: i }]);
        }
    };

    useEffect(() => {
        generateYearsForFilters();
        setQuarter(currentQuarterIndex - 1);
    }, []);

    useEffect(() => {
        if (!annualShow && ecotaxQuarterlyTemplates.length > 0) {
            const item = ecotaxQuarterlyTemplates[quarter];
            setPaymentDate(item.paid_date && item.status === 'paid' ? new Date(item.paid_date) : null);
            setCurrentEcoTaxOrder({
                id: item.id,
                recipient: item.recipient,
                orderer: item.orderer,
                total_amount: item.total_amount,
                payment_slip_heading: item.quarterly_eko_taxes.data[quarter].purpose_of_payment,
                purpose_of_payment: item.quarterly_eko_taxes.data[quarter].purpose_of_payment,
                recipient_account: item.recipient_account,
                debtor_account: item.debtor_account,
                currency: item.currency,
                model_number_recipient: item.model_number_recipient,
                call_number_recipient: item.call_number_recipient,
                payment_code: item.payment_code,
                status: item.status,
                paid_amount: item.paid_amount,
                paid_date: item.paid_date,
                quarter: true,
            });
            setChecked(item.status === 'paid');
            setPaidCheck(item.status === 'paid');
            setShowChekInput(true);
        } else if (ecotaxTemplates.length > 0) {
            const item = ecotaxTemplates[0];
            setPaymentDate(item.paid_date && item.status === 'paid' ? new Date(item.paid_date) : null);
            setCurrentEcoTaxOrder({
                id: item.id,
                recipient: item.recipient,
                orderer: item.orderer,
                total_amount: item.total_amount,
                payment_slip_heading: item.purpose_of_payment,
                purpose_of_payment: item.purpose_of_payment,
                recipient_account: item.recipient_account,
                debtor_account: item.debtor_account,
                currency: item.currency,
                model_number_recipient: item.model_number_recipient,
                call_number_recipient: item.call_number_recipient,
                payment_code: item.payment_code,
                status: item.status,
                paid_amount: item.paid_amount,
                paid_date: item.paid_date,
                quarter: false,
            });
            setChecked(item.status === 'paid');
            setPaidCheck(item.status === 'paid');
            setShowChekInput(true);
        } else {
            setShowChekInput(false);
        }
    }, [annualShow, quarter, ecotaxQuarterlyTemplates]);

    useEffect(() => {
        getAllTransferTemplates();
    }, [selectedYear]);

    const getQrCode = async (transfer: ObjectKeys): Promise<string | undefined> => {
        try {
            const res: any = await generateQrCode(
                transfer?.recipient,
                transfer?.orderer,
                transfer.total_amount,
                transfer.purpose_of_payment,
                transfer.recipient_account,
                transfer.currency,
                true,
                `${transfer.model_number_recipient}${transfer.call_number_recipient}`,
                transfer.payment_code,
            );
            setQrCode(res?.i);
            setIsQrCode(true);

            return res?.i;
        } catch (error) {
            setQrCode('');
            console.error('An error occurred:', error);
            return undefined;
        }
    };

    const downloadEcotax = async (): Promise<void> => {
        try {
            const qrCodeR = await getQrCode(currentEcoTaxOrder);
            communication.downloadSingleEcotax(
                currentEcoTaxOrder?.id,
                annualShow ? 'year' : 'quarter',
                qrCodeR ? qrCodeR : '',
            );
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const handleDelete = async (): Promise<void> => {
        setIsLoading(true);
        setIsDeleteModalActive(false);
        const res = await communication.deleteTransferOrder(selectedTransfer?.id);
        if (res.data.message === 'TemplateTransfer () Deleted Successfully.') {
            await getAllTransferTemplates();
        }
        setIsLoading(false);
    };

    const updateEcotaxPayment = (date: Date): void => {
        setIsLoading(true);
        const payload = {
            id: currentEcoTaxOrder.id,
            paid_date: moment(date).format('YYYY-MM-DD'),
        };
        try {
            communication
                .updateEcotaxAnnual(payload)
                .then(() => {
                    toast.success(t('pages.eco_tax_transfer.payment_date_success').text);
                    getAllTransferTemplates();
                })
                .catch((err: any) => {
                    toast.error(err.response.data.message);
                });
        } catch (error: any) {
            console.error('An error occurred:', error);
        }
    };

    const updateQuarterPayment = (date: Date): void => {
        setIsLoading(true);
        const payload = {
            paid_amount: currentEcoTaxOrder.total_amount,
            paid_date: moment(date).format('YYYY-MM-DD'),
        };
        try {
            communication
                .updateEcotaxQuarterly(currentEcoTaxOrder.id, payload)
                .then(() => {
                    toast.success(t('pages.eco_tax_transfer.payment_date_success').text);
                    getAllTransferTemplates();
                })
                .catch((err: any) => {
                    toast.error(err.response.data.message);
                });
        } catch (error: any) {
            console.error('An error occurred:', error);
        }
    };

    const removeAnnualPayment = (): void => {
        setIsLoading(true);
        const payload = {
            id: currentEcoTaxOrder.id,
            paid_date: null,
        };
        try {
            communication
                .updateEcotaxAnnual(payload)
                .then(() => {
                    toast.success(t('pages.eco_tax_transfer.payment_date_success').text);
                    getAllTransferTemplates();
                })
                .catch((err: any) => {
                    toast.error(err.response.data.message);
                });
        } catch (error: any) {
            console.error('An error occurred:', error);
        }
    };

    const removeQuarterPayment = (): void => {
        setIsLoading(true);
        const payload = {
            paid_amount: 0,
            paid_date: null,
        };
        try {
            communication
                .updateEcotaxQuarterly(currentEcoTaxOrder.id, payload)
                .then(() => {
                    toast.success(t('pages.eco_tax_transfer.payment_date_success').text);
                    getAllTransferTemplates();
                })
                .catch((err: any) => {
                    toast.error(err.response.data.message);
                });
        } catch (error: any) {
            console.error('An error occurred:', error);
        }
    };

    return (
        <PageWrapper>
            <ToastContainer />
            {warningModal && (
                <Modal modalVisible={true} closeModal={() => setWarningModal(false)}>
                    <Success close={() => setWarningModal(false)} />
                </Modal>
            )}
            {previewModal && (
                <Modal
                    modalVisible={previewModal}
                    closeModal={() => {
                        setPreviewModal(false);
                        setIsQrCode(false);
                    }}
                    size={'800px'}
                    className={'preview-tax-modal'}
                >
                    <TransferOrderPreviewModal
                        downloadTax={downloadEcotax}
                        isQrCode={isQrCode}
                        image={qrCode}
                        onClose={() => {
                            setPreviewModal(false);
                        }}
                        transfer={currentEcoTaxOrder}
                    />
                </Modal>
            )}

            {manageOrdersModalActive && (
                <Modal
                    className={'manage-orders-modal'}
                    modalVisible={manageOrdersModalActive}
                    closeModal={() => {
                        setIsManageOrdersModalActive(false);
                    }}
                >
                    <ManageOrdersModal
                        closeModal={() => setIsManageOrdersModalActive(false)}
                        yearsOptions={yearsOptions}
                        year={selectedYear}
                        setIsLoader={setIsLoading}
                        setOpenInfoModal={setIsInfoModalActive}
                        refresh={() => {
                            setIsManageOrdersModalActive(false);
                            getAllTransferTemplates();
                        }}
                        transferOrders={ecotaxTemplates}
                        key={renderKey2}
                        isEdit={ecotaxTemplates.length > 0}
                    />
                </Modal>
            )}

            <Modal
                className={'info-modal '}
                modalVisible={isInfoModalActive}
                closeModal={() => setIsInfoModalActive(false)}
            >
                <InfoModal
                    setOpenModal={setIsInfoModalActive}
                    message={t('pages.eco_tax_transfer.eco_tax_transfer_upload_success').text}
                    buttonText={t('buttons.ok').text}
                />
            </Modal>

            <Modal modalVisible={isDeleteModalActive} closeModal={() => setIsDeleteModalActive(false)}>
                <ConfirmModal
                    close={() => setIsDeleteModalActive(false)}
                    action={() => handleDelete()}
                    message={'Da li ste sigurni da želite da obrišete nalog za prenos?'}
                />
            </Modal>

            <Header transfers={ecotaxTemplates.length > 0}>
                <Title>
                    <h1>{t('navbar.pausal.ecotax').text}</h1>
                    <div className="header-right">
                        <div className="header-options">
                            <div className="header-options-buttons">
                                <Button
                                    className={'button'}
                                    variant={ButtonVariant.outlined}
                                    color={colors.purple}
                                    onClick={() => {
                                        navigate('/agency/authorization-transfer');
                                    }}
                                    icon={true}
                                    height={35}
                                >
                                    <FontAwesomeIcon icon={faUserCheck} />
                                    {t('pages.agency.navbar.authorizationTransfer').text}
                                </Button>
                                <Button
                                    className={'button'}
                                    variant={ButtonVariant.outlined}
                                    color={colors.purple}
                                    onClick={() => {
                                        setIsManageOrdersModalActive(true);
                                    }}
                                    icon={true}
                                    height={35}
                                >
                                    <FontAwesomeIcon icon={faPencil} />
                                    {t('pages.transfers.manage_transfers').text}
                                </Button>
                                {ecotaxTemplates.length > 0 && (
                                    <Button
                                        className={'button'}
                                        variant={ButtonVariant.outlined}
                                        color={colors.purple}
                                        onClick={() => {
                                            downloadEcotax();
                                        }}
                                        icon={true}
                                        height={35}
                                    >
                                        <FontAwesomeIcon icon={faPrint} />
                                        {t('pages.transfers.download_orders').text}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </Title>

                <Filters>
                    <label className="second-title">{`${
                        t('pages.eco_tax_transfer.label_ecotax').text
                    } ${selectedYear}`}</label>
                    <div className="search-filter">
                        {!annualShow ? (
                            <SelectComponent
                                optionList={quarterOptions}
                                defaultSelectedOption={quarterOptions[quarter]}
                                className={'select-month'}
                                width={'150px'}
                                handleSelectedValue={(value: number) => {
                                    setQuarter(value);
                                }}
                            />
                        ) : (
                            <div className="period">
                                <p>
                                    {t('pages.transfers.payment_lists.january').text} -{' '}
                                    {t('pages.transfers.payment_lists.december').text}
                                </p>
                            </div>
                        )}

                        <SelectComponent
                            optionList={yearsOptions}
                            defaultSelectedOption={yearsOptions.find((option) => option.value === selectedYear)}
                            className={'select-year'}
                            placeholder={selectedYear}
                            handleSelectedValue={(value: string) => {
                                setSelectedYear(value);
                            }}
                            key={renderKey}
                            width={'100px'}
                        />
                    </div>
                </Filters>
            </Header>
            <div className="toggle-label">
                <ToggleRow>
                    <ToggleSwitch
                        isChecked={annualShow}
                        onChange={() => {
                            setAnnualShow(!annualShow);
                        }}
                        isDisabled={false}
                        offLabel={t('pages.eco_tax_transfer.annual').text}
                        onLabel={t('pages.eco_tax_transfer.quarterly').text}
                    />
                </ToggleRow>
                {showChekInput ? (
                    <DateWrapper>
                        {paidCheck ? (
                            <span className="text">{t('pages.transfers.payment_lists.paided').text}:</span>
                        ) : null}
                        <Input
                            type={InputComponentType.Date}
                            date={paymentDate}
                            onChange={(value: Date) => {
                                setPaymentDate(value);
                                if (annualShow) {
                                    updateEcotaxPayment(value);
                                } else {
                                    updateQuarterPayment(value);
                                }
                            }}
                            inputClassName="payment-tax-date"
                            placeholder={t('pages.transfers.enter_date').text}
                        />
                        <div
                            onClick={() => {
                                if (!isChecked) return;
                                if (annualShow) {
                                    removeAnnualPayment();
                                } else {
                                    removeQuarterPayment();
                                }
                            }}
                            className="checkbox"
                        >
                            <FontAwesomeIcon
                                icon={isChecked ? faCheckSquare : faSquare}
                                className={`check-icon ${isChecked ? 'checked' : ''}`}
                            />
                        </div>
                    </DateWrapper>
                ) : null}
            </div>

            <TransferOrderTemplateContainer>
                {isLoading ? (
                    <SkeletonContainer>
                        <TransferOrderSkeleton />
                    </SkeletonContainer>
                ) : (
                    <>
                        {ecotaxTemplates.length !== 0 && currentEcoTaxOrder.id ? (
                            <TransferOrderTemplate
                                key={currentEcoTaxOrder.id}
                                template={currentEcoTaxOrder}
                                onClick={() => {
                                    getQrCode(currentEcoTaxOrder);
                                    setSelectedTransfer(currentEcoTaxOrder);
                                    setPreviewModal(true);
                                }}
                                isPaid={currentEcoTaxOrder.status === 'paid'}
                            />
                        ) : (
                            <NoItems
                                text={`${t('pages.eco_tax_transfer.no_items').text}${selectedYear}. ${
                                    t('pages.eco_tax_transfer.choosen_year').text
                                }`}
                            />
                        )}
                    </>
                )}
            </TransferOrderTemplateContainer>
            <TaxSolutionContainer>
                <TaxSolutionComponent />
            </TaxSolutionContainer>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    display: inline-block;
    vertical-align: top;
    width: -webkit-fill-available;
    width: -moz-available;
    .button-add {
        margin-top: 20px;
    }
    .preview-modal {
        .modal-wrapper {
            width: 800px;
            padding: 15px;
            overflow: auto;
            max-height: 74vh;
        }
    }
    .manage-orders-modal {
        .modal-wrapper {
            max-height: 90%;
            overflow-y: auto;
        }
    }
    label {
        font-size: 14px;
        line-height: 34px;
    }
    .toggle-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            align-items: flex-start;
            flex-direction: column;
        }
    }
`;

const TaxSolutionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 100px 0px;
    @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
        padding: 0;
    }
    .tax-solution {
        margin: 10px;
    }
`;

const Title = styled.div`
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-color);
    justify-content: flex-start;
    align-items: center;
    column-gap: 2rem;
    row-gap: 1rem;
    flex-wrap: wrap;
    @media (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const ToggleRow = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        color: var(--gray);
        font-size: 15px;
    }
    .left {
        margin-right: 10px;
    }
    .right {
        margin-left: 10px;
    }
`;

const DateWrapper = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    .input {
        > div {
            margin-bottom: 0;
        }
    }
    .text {
        margin-right: 10px;
        margin-top: 5px;
        font-size: 18px;
    }
    .checkbox {
        margin-left: 10px;
        cursor: pointer;
        .check-icon {
            border: 1px solid var(--purple);
            border-radius: 5px;
            font-size: 18px;
            color: var(--white);
        }
        .check-icon.checked {
            background-color: var(--purple);
        }
    }
`;

const TransferOrderTemplateContainer = styled.div`
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 1fr;
    margin: 1.5rem 0;
`;

const Header = styled.div<{ transfers: boolean }>`
    padding-bottom: 10px;
    position: relative;
    display: inline-block;
    width: 100%;
    @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
    }
    h1 {
        font-size: 36px;
        display: inline-block;
    }
    button {
        font-size: 12px;
    }
    .header-right {
        float: right;
    }
    .header-options-button {
        display: inline-block;
    }
    .buttons {
        color: var(--purple);
        display: inline-block;
        &:first-child {
            margin-right: 10px;
        }
    }
    .input-container {
        margin-bottom: 0;
    }
    .find {
        background-color: var(--white);
        padding: 20px 0px 20px 30px;
        border: none;
    }
    .find-icon {
        position: relative;
        align-self: flex-start;
        justify-self: center;
        bottom: 27px;
        left: 9px;
    }
    .header-options {
        display: flex;
    }
    .header-options-buttons {
        display: flex;
        column-gap: 1rem;
        @media (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            flex-wrap: wrap;
            gap: 10px;
        }
        .button {
            width: ${({ transfers }) => (transfers ? '30%' : '45%')};
            @media (max-width: ${MQ_BREAKPOINTS.mobileL}) {
                width: 45%;
            }
        }
    }
`;

const Filters = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: var(--purple);
    color: var(--white);
    vertical-align: middle;
    flex-wrap: wrap;
    gap: 1rem;
    height: 65px;
    @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
        height: auto;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.tablet}) and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.desktop}) and (max-width: ${MQ_BREAKPOINTS.desktopL}) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.ultraWide}) {
        height: 65px;
    }
    .period {
        display: flex;
        align-items: center;
        width: 150px;
        gap: 10px;
        p {
            font-size: 14px;
            line-height: 34px;
        }
    }
    .filter-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            align-items: flex-start;
        }
        .show-2 {
            padding: 0;
        }
    }
    .select {
        width: 100%;
        display: inline-block;
        background-color: var(--white);
    }
    .select-wrapper {
        // display: inline-block;
    }
    .filter {
        max-width: 100px;
        .show {
            margin-right: 10px;
        }
        display: flex;
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            align-items: flex-start;
        }
        align-items: center;
        margin-right: 10px;
        max-width: 200px;
        @media (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            display: flex;
            margin-top: 20px;
            margin-right: 0;
        }
        @media (max-width: ${MQ_BREAKPOINTS.laptopM}) {
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
            width: 80px;
        }
    }
    .filter-search {
        .show {
            margin-right: 10px;
        }
        display: flex;
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            align-items: flex-start;
        }
        align-items: center;
        margin-right: 10px;
        @media (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            display: flex;
            margin-top: 20px;
            margin-right: 0;
        }
        @media (max-width: ${MQ_BREAKPOINTS.laptopM}) {
        }
        label {
            display: inline-block;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
        }
    }
    .search-filter {
        top: 78px;
        display: flex;
        gap: 20px;
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        @media (max-width: ${MQ_BREAKPOINTS.laptopM}) {
            margin-right: 0;
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
        }
        .select-wrapper {
            display: inline-block;
        }
        input {
            font-size: 14px;
            width: 150px;
        }
        .input {
            width: 150px;
        }
        .select-search {
            height: 44px;
        }
    }
    .select-year {
        background-color: var(--white);
        color: var(--gray);
        width: fit-content;
    }
    .select-month {
        background-color: var(--white);
        color: var(--gray);
        width: 130px;
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            float: none;
        }
    }
    .select-items {
        background-color: var(--white);
        color: var(--gray);
        width: 80px;
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            float: none;
        }
    }
    .select__control {
        border-radius: 0px;
    }
    .second-title {
        font-size: 14px;
        line-height: 34px;
        min-width: 120px;
        text-transform: uppercase;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            font-size: 15px;
        }
    }
`;

const SkeletonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .skeleton {
        margin: 10px;
    }
    width: 100%;
`;
export default TransferOrders;
