export function transformDateFormat(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Months are zero-based, so we add 1
    const year = date.getUTCFullYear();

    // Ensure leading zeros for single-digit day and month
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;

    // Return the transformed date in the desired format
    return `${formattedDay}.${formattedMonth}.${year}.`;
}

export const transformDateTimeFormat = (dateString: string): string => {
    const date = new Date(dateString);

    // Extract date components
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based, so we add 1
    const year = date.getFullYear();

    // Extract time components
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Format date components with leading zeros where needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    // Format time components with leading zeros
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    // Combine formatted date and time
    return `${formattedDay}.${formattedMonth}.${year}. ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};
