import { FunctionComponent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faSearch, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import colors from '../../../../global/colors';
import Button, { ButtonVariant } from '../../../../components/button';
import Input, { InputComponentType } from '../../../../components/input';
import communication from '../../../../communication';
import Loader from '../../../../components/loader';
import CartCard from '../cartCard';
import useAllUsersStore from '../../../../store/admin/allUsers';

interface Props {
    close: Function;
    success: Function;
    loading: Function;
    modalVisible: boolean;
    errorMessage: Function;
}

const FindModal: FunctionComponent<Props> = ({ close, modalVisible }) => {
    const [loader, setLoader] = useState<boolean>(false);
    const [cart, setCart] = useState<any>({});

    const [cartID, setCartID] = useState<string>('');

    const [userID, setUserID] = useState<string>('');
    const { allUsers } = useAllUsersStore();

    const [errorMessages, setErrorMessages] = useState<string>('');

    const [userFromCart, setUserFromCart] = useState<any>({});

    const [nonUserCart, setNonUserCart] = useState<boolean>(false);
    const [nonUserEmail, setNonUserEmail] = useState<string>('');
    const [nonUserFullName, setNonUserFullName] = useState<string>('');
    const [nonUserPib, setNonUserPib] = useState<string>('');

    const getCart = async (): Promise<any> => {
        setNonUserEmail('');
        setNonUserFullName('');
        setNonUserPib('');
        setCart({});
        try {
            setLoader(true);
            communication
                .getCartById(cartID)
                .then((res: any) => {
                    if (res?.data?.data?.length === 0) {
                        setLoader(false);
                        setNonUserEmail('');
                        setNonUserFullName('');
                        setNonUserPib('');
                        setCart({});
                        setUserID('');
                        return setErrorMessages('Korpa je prazna.');
                    }
                    if (res?.data?.data?.detail?.data.length === 0) {
                        setNonUserCart(false);
                        setCart(res.data.data);
                        setUserID(res.data.data.user_id);
                        setErrorMessages('');
                        setLoader(false);
                        return res.data.data;
                    } else {
                        setNonUserCart(true);
                        setNonUserEmail(res.data.data.detail.data.email);
                        setNonUserFullName(
                            res.data.data.detail.data.first_name + ' ' + res.data.data.detail.data.last_name,
                        );
                        setNonUserPib(res.data.data.detail.data.pib);
                        setCart(res.data.data);
                        setErrorMessages('');
                        setLoader(false);
                        return res.data.data;
                    }
                })
                .catch((err: any) => {
                    setErrorMessages(err.response.data.message);
                    setCart({});
                    setUserFromCart({});
                    setLoader(false);
                });
        } catch (err: any) {
            setLoader(false);
            setErrorMessages('Došlo je do greške prilikom pretrage korpe.');
            setUserFromCart({});
            setCart({});
        }
    };

    useEffect(() => {
        if (!modalVisible) return;
    }, [modalVisible]);

    useEffect(() => {
        if (userID && allUsers) {
            const user = allUsers.find((userObject) => userObject.id === userID);
            setUserFromCart(user);
        } else {
            setUserFromCart({});
        }
    }, [userID]);

    const transformDateTimeFormat = (dateString: string): string => {
        const date = new Date(dateString);

        // Extract date components
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-based, so we add 1
        const year = date.getFullYear();

        // Extract time components
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        // Format date components with leading zeros where needed
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;

        // Format time components with leading zeros
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

        // Combine formatted date and time
        return `${formattedDay}.${formattedMonth}.${year}. ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    const handleDownloadClick = (url: string): void => {
        window.open(url, '_blank');
    };

    return (
        <>
            {loader && <Loader />}
            <>
                <Container>
                    <Header>
                        <h2>Pretraži korisnika prema cart ID</h2>
                        <Icon onClick={() => close()}>
                            <FontAwesomeIcon icon={faXmark} style={{ color: 'var(--gray)' }} />
                        </Icon>
                    </Header>
                    <Input
                        type={InputComponentType.Text}
                        label={'Cart ID (Shopping cart ID)'}
                        value={cartID}
                        onChange={(value: string) => {
                            setCartID(value);
                        }}
                    />
                    <div className="button-container">
                        <Button
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            className="small"
                            size={200}
                            onClick={() => {
                                getCart();
                            }}
                        >
                            <FontAwesomeIcon icon={faSearch} style={{ color: 'var(--white)', marginRight: '10px' }} />
                            Pretraži
                        </Button>
                    </div>
                    {userFromCart ? (
                        <div className="info">
                            <h3>Korisnik {nonUserCart ? ' - van aplikacije' : null}</h3>
                            {nonUserCart ? (
                                <>
                                    <p>
                                        <span>email:</span> {nonUserEmail}
                                    </p>
                                    <p>
                                        <span>PIB:</span> {nonUserPib}
                                    </p>
                                    <p>
                                        <span>Ime i prezime:</span> {nonUserFullName}
                                    </p>
                                </>
                            ) : (
                                <>
                                    <p>
                                        <span>email:</span> {userFromCart.email}
                                    </p>
                                    <p>
                                        <span>PIB:</span> {userFromCart.pib}
                                    </p>
                                </>
                            )}

                            <h3>Korpa</h3>
                        </div>
                    ) : null}
                    {cart && cart.id ? (
                        <>
                            <CartTable>
                                <thead>
                                    <tr>
                                        <TableHeader colSpan={2}>Uplata</TableHeader>
                                        <TableHeader>Datum</TableHeader>
                                        <TableHeader>Ukupan iznos</TableHeader>
                                    </tr>
                                </thead>
                                <tbody>{cart && cart.id ? <CartCard key={cart.id} cart={cart} /> : null}</tbody>
                            </CartTable>
                            <div className="info">
                                <p>
                                    <span>Kreiran:</span> {transformDateTimeFormat(cart.created_at)}
                                </p>
                                <p>
                                    <span>Status:</span> {cart.status}
                                </p>
                                <p>
                                    <span>Racun:</span>
                                    {cart.invoice_pdf_url ? (
                                        <span onClick={() => handleDownloadClick(cart.invoice_pdf_url)}>
                                            <FontAwesomeIcon icon={faFileInvoice} className="billDownload" />
                                        </span>
                                    ) : null}
                                </p>
                            </div>
                        </>
                    ) : null}

                    {errorMessages ? <p style={{ color: 'red' }}>{errorMessages}</p> : null}
                </Container>
            </>
        </>
    );
};

export default FindModal;
const Container = styled.div`
    .button-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .input-container {
        width: 100%;
    }
    label {
        color: var(--gray);
    }
    .six-months {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .discount {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
            display: flex;
            align-items: center;
            font-size: 18px;
        }
    }
    .discount-input {
        width: 15%;
    }
    .services {
        font-weight: bold;
        margin-bottom: 15px;
    }
    .items {
        margin-left: 5px;
        margin-bottom: 5px;
        display: flex;
        gap: 10px;
        cursor: pointer;
    }
    .info {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        margin-top: 20px;
        gap: 10px;
        font-size: 15px;
        justify-content: flex-start;
        h3 {
            font-size: 18px;
            margin-bottom: 10px;
            margin-top: 10px;
            color: var(--purple);
        }
        p {
            font-size: 15px;
        }
    }
    .billDownload {
        cursor: pointer;
        color: var(--purple);
        margin-left: 15px;
    }
`;

const Header = styled.div`
    display: grid;
    grid-template-columns: 95% 5%;
    h2 {
        font-size: 24px;
        font-weight: 400;
        text-align: left;
    }
    p {
        width: 90%;
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
        color: var(--gray);
        margin-bottom: 30px;
    }
`;

const Icon = styled.div`
    float: right;
    font-size: 30px;
    margin-top: -8px;
    &.attach {
        font-size: 13px;
        color: var(--gray);
        float: left;
        margin-top: 0px;
    }
`;
const CartTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    font-weight: normal;
    margin-top: 20px;
    margin-bottom: 20px;
    .info {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        gap: 10px;
        font-size: 15px;
        color: var(--purple);
        justify-content: flex-start;
        p {
            font-size: 15px;
        }
    }
`;
const TableHeader = styled.th`
    padding: 10px;
    font-weight: normal;
`;
