import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import logo from '../../../assets/logo.png';
import { faChartSimple, faHeadset, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../adminDashboard/AdminDashboardModal/index';

const AdminDashboard: FunctionComponent = () => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [pageType, setPageType] = useState<string>('');

    const validateUser = (value: string): void => {
        setModalOpen(true);
        setPageType(value);
    };

    useEffect(() => {
        const userRole = sessionStorage.getItem('admin-permission-role');
        if (!userRole) {
            sessionStorage.setItem('admin-permission-role', '');
        }
    }, []);

    return (
        <>
            <ContentWrapper>
                <InteractionSection>
                    <UserInteraction>
                        <Button onClick={() => validateUser('management')}>
                            <FontAwesomeIcon className="buttonIcon" icon={faUser} color="white" />
                        </Button>
                        <h1>Menadžment</h1>
                    </UserInteraction>
                    <UserInteraction>
                        <Button onClick={() => validateUser('marketing')}>
                            <FontAwesomeIcon className="buttonIcon" icon={faChartSimple} color="white" />
                        </Button>
                        <h1>Marketing</h1>
                    </UserInteraction>
                    <UserInteraction>
                        <Button onClick={() => validateUser('support')}>
                            <FontAwesomeIcon className="buttonIcon" icon={faHeadset} color="white" />
                        </Button>
                        <h1>Support</h1>
                    </UserInteraction>
                </InteractionSection>
                <LogoSection>
                    <Logo>
                        <img src={logo} alt="" />
                    </Logo>
                </LogoSection>
            </ContentWrapper>
            <Modal modalVisible={modalOpen} closeModal={() => setModalOpen(!modalOpen)} heading={pageType} />
        </>
    );
};

const ContentWrapper = styled.div`
    height: 87vh;
    display: flex;
    flex-direction: column;
`;

const InteractionSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    gap: 80px;
    background-color: transparent;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const UserInteraction = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h1 {
        margin-top: 10px;
        font-size: 1.2rem;
        color: var(--purple);
    }
`;

const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    background-color: var(--purple);
    border-radius: 50%;
    min-width: 50px;
    min-height: 50px;
    border: 2px solid transparent;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    cursor: pointer;

    &:hover {
        background-color: white;
        border-color: var(--purple);
    }

    &:hover .buttonIcon {
        color: var(--purple);
    }

    .buttonIcon {
        height: 35%;
        width: 35%;
        color: white;
        transition: color 0.3s ease;
    }
`;

const LogoSection = styled.div`
    background-color: transparent;
    padding: 20px;
    text-align: center;
`;

const Logo = styled.div`
    font-size: 2rem;
    color: var(--purple);
    font-weight: bold;
`;

export default AdminDashboard;
