import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import { faChevronDown, faChevronUp, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import PausalIcon, { CustomIconName } from '../../../components/pausal-icons/PausalIcon';
import { isPathActive } from '../../../functions/isPathActive';
import { MQ_BREAKPOINTS, breakpoints } from '../../../constants/breakpoints';

type CustomIconData = {
    type: 'custom';
    icon: CustomIconName;
};

type FaIconData = {
    type: 'fa';
    icon: IconDefinition;
};

export type IconData = CustomIconData | FaIconData;
export interface NavItemType {
    text: string;
    type?: string;
    iconData?: IconData;
    link?: string;
    dropdownItems?: NavItemType[];
    action?: () => void;
    actionType?: string;
    otherActivePaths?: string[];
}

const NavItem: FunctionComponent<NavItemType> = ({
    text,
    iconData,
    link,
    type,
    dropdownItems,
    action,
    otherActivePaths,
}) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const toggleDropdown = (): void => {
        setDropdownOpen((prev) => !prev);
    };
    const location = useLocation();
    const isItemActive = isPathActive(link || '', otherActivePaths, location.pathname);

    return (
        <>
            {link ? (
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                <Link to={link!}>
                    <NavItemContent key={text} className={isItemActive ? 'active' : ''} onClick={action}>
                        {iconData &&
                            (iconData?.type === 'custom' ? (
                                <PausalIcon name={iconData.icon} size={15} color="white" className="icon" />
                            ) : (
                                <FontAwesomeIcon className="icon" color="white" icon={iconData.icon} />
                            ))}
                        <Label className="label">{t(text).text}</Label>
                        {/* Layer for new features in app */}
                        {/* {link.includes('/services') && <SupText>{t('navbar.pausal.new').text}</SupText>} */}
                    </NavItemContent>
                </Link>
            ) : type === 'dropdown' ? (
                <NavItemDropdown
                    key={text}
                    className={`${dropdownOpen ? 'dropdownOpen' : ''}`}
                    itemsNumber={dropdownItems?.length || 39}
                >
                    <div className="content" onClick={toggleDropdown}>
                        {iconData &&
                            (iconData?.type === 'custom' ? (
                                <PausalIcon name={iconData.icon} size={15} color="white" className="icon" />
                            ) : (
                                <FontAwesomeIcon className="icon" color="white" icon={iconData.icon} />
                            ))}
                        <div className="label-arrow-wrapper">
                            <Label className="label">{t(text).text}</Label>
                            <FontAwesomeIcon
                                icon={dropdownOpen ? faChevronUp : faChevronDown}
                                color="white"
                                className="icon"
                                width={11}
                                height={11}
                            />
                        </div>
                    </div>
                    {dropdownItems && dropdownItems?.length >= 1 && (
                        <div className="dropdown">
                            {dropdownItems?.map((navItem) => {
                                const isDropdownItemActive = isPathActive(
                                    navItem.link || '',
                                    navItem.otherActivePaths,
                                    location.pathname,
                                );
                                return (
                                    <Link key={navItem.link || navItem.text} to={navItem.link || ''}>
                                        <NavItemContent
                                            className={isDropdownItemActive ? 'active' : ''}
                                            onClick={action}
                                            style={{ paddingLeft: '50px' }}
                                        >
                                            {navItem.iconData &&
                                                (navItem.iconData?.type === 'custom' ? (
                                                    <PausalIcon
                                                        name={navItem.iconData.icon}
                                                        size={15}
                                                        color="white"
                                                        className="icon"
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        className="icon"
                                                        color="white"
                                                        icon={navItem.iconData.icon}
                                                    />
                                                ))}
                                            <Label className="label">{t(navItem.text).text}</Label>
                                            {navItem.link === '/services' && (
                                                <SupText>{t('navbar.pausal.new').text}</SupText>
                                            )}
                                        </NavItemContent>
                                    </Link>
                                );
                            })}
                        </div>
                    )}
                </NavItemDropdown>
            ) : (
                <NavItemContent key={text} onClick={action}>
                    {iconData &&
                        (iconData?.type === 'custom' ? (
                            <PausalIcon name={iconData.icon} size={15} color="white" className="icon" />
                        ) : (
                            <FontAwesomeIcon className="icon" color="white" icon={iconData.icon} />
                        ))}
                    <Label className="label">{t(text).text}</Label>
                </NavItemContent>
            )}
        </>
    );
};
export default NavItem;

const NavItemContent = styled.div`
    display: flex;
    align-items: center;
    min-height: 39px;
    cursor: pointer;
    position: relative;
    padding: 0 30px;
    transition: all 0.2s ease;
    .supText {
        position: absolute;
        left: -10px;
        top: 5px;
        color: var(--white);
        background-color: var(--purple);
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 9px;
        transform: rotate(-41deg);
        overflow: hidden;
        height: 12px;
        display: flex;
        align-items: center;
        clip-path: polygon(0% 0%, 70% 0%, 100% 100%, 0% 100%);
    }

    &.active {
        background-color: var(--white);
        border-left: 3px solid var(--purple);
        .label,
        .icon {
            color: var(--black);
            fill: var(--black);
        }
    }

    @media only screen and (min-width: ${MQ_BREAKPOINTS.laptop}) {
        &:hover {
            background-color: var(--white);
            border-left: 3px solid var(--purple);
            .label,
            .icon {
                color: var(--black);
                fill: var(--black);
            }
        }
    }
`;

const NavItemDropdown = styled.div<{ itemsNumber: number }>`
    display: flex;
    height: 39px;
    flex-direction: column;
    cursor: pointer;
    transition: height 0.3s ease;
    position: relative;
    overflow: hidden;
    &.dropdownOpen {
        max-height: fit-content;
        height: ${(p) => (p.itemsNumber + 1) * 39}px;
    }
    .content {
        min-height: 39px;
        display: flex;
        align-items: center;
        padding: 0 30px;
        .label-arrow-wrapper {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }

        @media only screen and (min-width: ${MQ_BREAKPOINTS.laptop}) {
            &:hover {
                background-color: var(--white);
                border-left: 3px solid var(--purple);
                .label,
                .icon {
                    color: var(--black);
                    fill: var(--black);
                }
            }
        }
    }
`;

const Label = styled.p`
    color: var(--light-gray);
    margin-left: 10px;
    font-family: Open Sans, sans-serif;
    font-size: 12px;
    @media only screen and (max-width: ${breakpoints.laptop - 1}px) {
        font-size: 16px;
    }
`;

const SupText = styled.p`
    @media only screen and (min-width: ${MQ_BREAKPOINTS.laptop}) {
        position: absolute;
        left: -10px;
        top: 5px;
        color: var(--white);
        background-color: var(--purple);
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 9px;
        transform: rotate(-41deg);
        overflow: hidden;
        height: 12px;
        display: flex;
        align-items: center;
        clip-path: polygon(0% 0%, 70% 0%, 100% 100%, 0% 100%);
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        position: absolute;
        right: 10px;
        top: 10px;
        color: var(--purple);
        background-color: var(--white);
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 12px;
    }
`;
