import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Modal from '../../../components/modal';
import ConfirmModal from '../../../components/confirmModal';

import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { CreateNotifications } from './createNotifications';
import { NotificationsHistory } from './notificationsHistory';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';
import { useBackOfficePasswordProtect } from '../../../hooks/backOfficePasswordProtect';

const AdminNotificationsNew: FunctionComponent = () => {
    useBackOfficePasswordProtect();

    const [tabIndex, setTabIndex] = useState(0);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { type } = useParams<{ type: string }>();

    const [deleteModal, setDeleteModal] = useState<boolean>(false);

    const tabs = ['KREIRANJE NOTIFIKACIJA', 'ISTORIJA NOTIFIKACIJA'];

    useEffect(() => {
        if (type === 'create') {
            setTabIndex(0);
        } else if (type === 'history') {
            setTabIndex(1);
        }
    }, [pathname]);

    return (
        <>
            <ToastContainer />
            {deleteModal && (
                <Modal
                    width="500px"
                    className="package-modal"
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteModal(false);
                    }}
                >
                    <ConfirmModal
                        action={() => {
                            setDeleteModal(false);
                            // onDelete();
                            // Update services
                        }}
                        close={() => {
                            setDeleteModal(false);
                        }}
                        active={deleteModal}
                        message={'Da li ste sigurni da zelite da obrisete uslugu?'}
                    />
                </Modal>
            )}
            <Header>
                <h1>Notifikacije</h1>
                <TabsWrapper>
                    <Tabs
                        selectedIndex={tabIndex}
                        onSelect={(index) => {
                            setTabIndex(index);
                            if (index === 0) navigate('/admin/notifications/create');
                            if (index === 1) navigate('/admin/notifications/history');
                        }}
                    >
                        <TabList>
                            {tabs.map((tab, index) => (
                                <Tab key={index}>{tab}</Tab>
                            ))}
                        </TabList>

                        {tabs.map((tab, index) => (
                            <TabPanel key={index} className={tab}>
                                {index === 0 ? <CreateNotifications /> : index === 1 ? <NotificationsHistory /> : <></>}
                            </TabPanel>
                        ))}
                    </Tabs>
                </TabsWrapper>
            </Header>
        </>
    );
};

const Header = styled.div`
    padding-bottom: 20px;
    margin-bottom: 20px;
`;

const TabsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 30px;

    .react-tabs {
        width: 100%;
    }

    .react-tabs__tab-list {
        background-color: var(--purple);
        display: flex;
        align-items: center;
        margin: 0;
        border-bottom: 0;
        padding: 10px;
        margin-bottom: 20px;
        .react-tabs__tab {
            font-size: 17px;
            padding: 10px;
            border-bottom: 2px solid transparent;
            color: var(--white);
            @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
                width: 100%;
            }
            &.react-tabs__tab--selected {
                background: transparent;
                border: 0;
                border-color: var(--purple);
                color: var(--white);
                border-radius: 0;
                border-bottom: 2px solid var(--white);
                &:focus {
                    &:after {
                        content: none;
                    }
                }
            }
            :hover {
                &.react-tabs__tab {
                    border-bottom: 2px solid var(--border-color);
                }
            }
        }
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            flex-direction: column;
        }
    }
    .react-tabs__tab-panel,
    .react-tabs__tab-panel--selected {
        /* background: var(--white); */
        /* padding: 20px; */
    }
`;

export default AdminNotificationsNew;
