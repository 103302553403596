import {
    faUsd,
    faIndustry,
    faRightLeft,
    faChartArea,
    faGift,
    faFileInvoice,
    faChartBar,
    faBox,
    faCog,
    faUserNinja,
    faCalendarDays,
    faNotesMedical,
    faChartLine,
    faRefresh,
    faCircleInfo,
    faLeaf,
    faCoins,
    faChartPie,
    faBell,
} from '@fortawesome/free-solid-svg-icons';

import { faFileAlt, faEnvelope, faSquarePlus } from '@fortawesome/free-regular-svg-icons';
import { NavItemType } from '../components/navbar/navItem';

// Initial role retrieval
let adminRole = sessionStorage.getItem('admin-permission-role');

// Backup the original sessionStorage methods
const originalSetItem = sessionStorage.setItem;

// Override sessionStorage.setItem to track changes to 'admin-permission-role'
sessionStorage.setItem = function (this: Storage, key: string, value: string) {
    if (key === 'admin-permission-role') {
        // Immediately set the new role in sessionStorage
        originalSetItem.call(this, key, value);

        // Immediately update adminRole after setting in sessionStorage
        adminRole = value;

        // Update the navigation
        updateAdminNavigation();
    } else {
        originalSetItem.call(this, key, value); // For all other session storage keys, just set normally
    }
};

let adminNavigation: NavItemType[] = [];

const generateAdminNavigation = (role: string | null): NavItemType[] => {
    if (!role || role === '') {
        return [];
    }

    const fullAdminNavigation: NavItemType[] = [
        {
            text: 'navbar.admin.users',
            iconData: { type: 'fa', icon: faUsd },
            link: '/admin/users',
        },
        {
            text: 'navbar.admin.invoices',
            iconData: { type: 'fa', icon: faFileAlt },
            link: '/admin/invoices',
        },
        {
            text: 'navbar.admin.prePayment',
            iconData: { type: 'custom', icon: 'faFileInvoiceSharpLight' },
            link: '/admin/advances',
        },
        {
            text: 'navbar.admin.proInvoices',
            iconData: { type: 'fa', icon: faFileInvoice },
            link: '/admin/proforma',
        },
        {
            text: 'navbar.admin.taxes',
            type: 'dropdown',
            iconData: { type: 'custom', icon: 'faBuildingColumnsLight' },
            dropdownItems: [
                {
                    text: 'navbar.admin.transfers',
                    iconData: { type: 'custom', icon: 'faHandHoldingCircleDollarLight' },
                    link: '/admin/transfer-orders',
                    otherActivePaths: ['/admin/transfer-orders/*'],
                },
                {
                    text: 'navbar.admin.ecotax',
                    iconData: { type: 'fa', icon: faLeaf },
                    link: '/admin/ecotax',
                    otherActivePaths: ['/admin/ecotax/*'],
                },
            ],
        },
        {
            text: 'navbar.admin.promoCodes',
            iconData: { type: 'fa', icon: faUsd },
            link: '/admin/promo-codes',
        },
        {
            text: 'navbar.admin.transactions',
            iconData: { type: 'fa', icon: faRightLeft },
            link: '/admin/transactions',
        },
        {
            text: 'navbar.admin.suggestions',
            iconData: { type: 'fa', icon: faEnvelope },
            link: '/admin/suggestions',
        },
        {
            text: 'navbar.admin.tax',
            iconData: { type: 'fa', icon: faSquarePlus },
            link: '/admin/tax',
        },
        {
            text: 'navbar.admin.notifications',
            iconData: { type: 'fa', icon: faBell },
            link: '/admin/notifications/history',
            otherActivePaths: ['/admin/notifications/create', '/admin/notifications/history'],
        },
        {
            text: 'navbar.admin.statistics',
            iconData: { type: 'fa', icon: faIndustry },
            link: '/admin/statistics',
        },
        {
            text: 'navbar.admin.benefits',
            iconData: { type: 'fa', icon: faGift },
            link: '/admin/benefits',
        },
        {
            text: 'navbar.admin.packages',
            iconData: { type: 'fa', icon: faBox },
            link: '/admin/packages',
        },
        {
            text: 'navbar.admin.requests',
            iconData: { type: 'fa', icon: faEnvelope },
            link: '/admin/requests',
        },
        {
            text: 'navbar.admin.services',
            iconData: { type: 'fa', icon: faCog },
            link: '/admin/services',
        },
        {
            text: 'navbar.admin.googleAnalytics',
            iconData: { type: 'fa', icon: faChartBar },
            link: '/admin/google-analytics',
        },
        {
            text: 'navbar.admin.payments',
            iconData: { type: 'fa', icon: faChartLine },
            link: '/admin/finance',
        },
    ];

    const supportNavigation: NavItemType[] = [
        {
            text: 'navbar.admin.users',
            iconData: { type: 'fa', icon: faUsd },
            link: '/admin/users',
        },
        {
            text: 'navbar.admin.invoices',
            iconData: { type: 'fa', icon: faFileAlt },
            link: '/admin/invoices',
        },
        {
            text: 'navbar.admin.prePayment',
            iconData: { type: 'custom', icon: 'faFileInvoiceSharpLight' },
            link: '/admin/advances',
        },
        {
            text: 'navbar.admin.proInvoices',
            iconData: { type: 'fa', icon: faFileInvoice },
            link: '/admin/proforma',
        },
        {
            text: 'navbar.admin.taxes',
            type: 'dropdown',
            iconData: { type: 'custom', icon: 'faBuildingColumnsLight' },
            dropdownItems: [
                {
                    text: 'navbar.admin.transfers',
                    iconData: { type: 'custom', icon: 'faHandHoldingCircleDollarLight' },
                    link: '/admin/transfer-orders',
                    otherActivePaths: ['/admin/transfer-orders/*'],
                },
                {
                    text: 'navbar.admin.ecotax',
                    iconData: { type: 'fa', icon: faLeaf },
                    link: '/admin/ecotax',
                    otherActivePaths: ['/admin/ecotax/*'],
                },
            ],
        },
        {
            text: 'navbar.admin.suggestions',
            iconData: { type: 'fa', icon: faEnvelope },
            link: '/admin/suggestions',
        },
        {
            text: 'navbar.admin.tax',
            iconData: { type: 'fa', icon: faSquarePlus },
            link: '/admin/tax',
        },
        {
            text: 'navbar.admin.requests',
            iconData: { type: 'fa', icon: faEnvelope },
            link: '/admin/requests',
        },
        {
            text: 'navbar.admin.payments',
            iconData: { type: 'fa', icon: faChartLine },
            link: '/admin/finance',
        },
    ];

    const marketingNavigation: NavItemType[] = [
        {
            text: 'navbar.admin.users',
            iconData: { type: 'fa', icon: faUsd },
            link: '/admin/users',
        },
        {
            text: 'navbar.admin.invoices',
            iconData: { type: 'fa', icon: faFileAlt },
            link: '/admin/invoices',
        },
        {
            text: 'navbar.admin.prePayment',
            iconData: { type: 'custom', icon: 'faFileInvoiceSharpLight' },
            link: '/admin/advances',
        },
        {
            text: 'navbar.admin.proInvoices',
            iconData: { type: 'fa', icon: faFileInvoice },
            link: '/admin/proforma',
        },
        {
            text: 'navbar.admin.taxes',
            type: 'dropdown',
            iconData: { type: 'custom', icon: 'faBuildingColumnsLight' },
            dropdownItems: [
                {
                    text: 'navbar.admin.transfers',
                    iconData: { type: 'custom', icon: 'faHandHoldingCircleDollarLight' },
                    link: '/admin/transfer-orders',
                    otherActivePaths: ['/admin/transfer-orders/*'],
                },
                {
                    text: 'navbar.admin.ecotax',
                    iconData: { type: 'fa', icon: faLeaf },
                    link: '/admin/ecotax',
                    otherActivePaths: ['/admin/ecotax/*'],
                },
            ],
        },
        {
            text: 'navbar.admin.suggestions',
            iconData: { type: 'fa', icon: faEnvelope },
            link: '/admin/suggestions',
        },
        {
            text: 'navbar.admin.tax',
            iconData: { type: 'fa', icon: faSquarePlus },
            link: '/admin/tax',
        },
        {
            text: 'navbar.admin.notifications',
            iconData: { type: 'fa', icon: faBell },
            link: '/admin/notifications/history',
            otherActivePaths: ['/admin/notifications/create', '/admin/notifications/history'],
        },
        {
            text: 'navbar.admin.requests',
            iconData: { type: 'fa', icon: faEnvelope },
            link: '/admin/requests',
        },
    ];

    const managementNavigation: NavItemType[] = fullAdminNavigation;

    if (role === 'management') {
        return managementNavigation;
    } else if (role === 'support') {
        return supportNavigation;
    } else if (role === 'marketing') {
        return marketingNavigation;
    }

    return [];
};

const updateAdminNavigation = () => {
    adminNavigation = generateAdminNavigation(adminRole);
};

updateAdminNavigation();

export { adminNavigation };

export const userNavigation: NavItemType[] = [
    {
        text: 'navbar.pausal.invoicing',
        type: 'dropdown',
        iconData: { type: 'fa', icon: faFileAlt },
        dropdownItems: [
            {
                text: 'navbar.pausal.invoices',
                iconData: { type: 'custom', icon: 'faFileLinesSharpSolid' },
                link: '/invoices',
                otherActivePaths: ['/invoices/add/domestic', '/invoices/add/foreign'],
            },
            {
                text: 'navbar.pausal.eInvoices',
                iconData: { type: 'custom', icon: 'faFileLinesSharpRegular' },
                link: '/e-invoices',
            },
            {
                text: 'navbar.pausal.proInvoices',
                iconData: { type: 'fa', icon: faFileInvoice },
                link: '/proforma',
                otherActivePaths: ['/proforma/add/domestic', '/proforma/add/foreign'],
            },
            {
                text: 'navbar.pausal.prePayment',
                iconData: { type: 'custom', icon: 'faFileInvoiceSharpLight' },
                link: '/advance-invoices',
                otherActivePaths: ['/advance-invoices/add/domestic', '/advance-invoices/add/foreign'],
            },
            {
                text: 'navbar.pausal.ePrePayment',
                iconData: { type: 'custom', icon: 'faFileInvoiceSharpThin' },
                link: '/eadvance-invoices',
            },
        ],
    },
    {
        text: 'navbar.pausal.myBusiness',
        type: 'dropdown',
        iconData: { type: 'custom', icon: 'faChartUserRegular' },
        dropdownItems: [
            {
                text: 'navbar.pausal.kpoBook',
                iconData: { type: 'custom', icon: 'faBookUserSolid' },
                link: '/e-invoices/kpo',
            },
        ],
    },
    {
        text: 'navbar.pausal.services',
        iconData: { type: 'fa', icon: faUserNinja },
        link: '/services/1',
        otherActivePaths: ['/services/2', '/services/3', '/services/4'],
    },
];

export const activeUserNavigation: NavItemType[] = [
    {
        text: 'navbar.pausal.dashboard',
        iconData: { type: 'fa', icon: faChartPie },
        link: '/dashboard',
    },
    {
        text: 'navbar.pausal.invoicing',
        type: 'dropdown',
        iconData: { type: 'fa', icon: faFileAlt },
        dropdownItems: [
            {
                text: 'navbar.pausal.invoices',
                iconData: { type: 'custom', icon: 'faFileLinesSharpSolid' },
                link: '/invoices',
                otherActivePaths: ['/invoices/add/domestic', '/invoices/add/foreign'],
            },
            {
                text: 'navbar.pausal.eInvoices',
                iconData: { type: 'custom', icon: 'faFileLinesSharpRegular' },
                link: '/e-invoices',
            },
            {
                text: 'navbar.pausal.proInvoices',
                iconData: { type: 'fa', icon: faFileInvoice },
                link: '/proforma',
                otherActivePaths: ['/proforma/add/domestic', '/proforma/add/foreign'],
            },
            {
                text: 'navbar.pausal.prePayment',
                iconData: { type: 'custom', icon: 'faFileInvoiceSharpLight' },
                link: '/advance-invoices',
                otherActivePaths: ['/advance-invoices/add/domestic', '/advance-invoices/add/foreign'],
            },
            {
                text: 'navbar.pausal.ePrePayment',
                iconData: { type: 'custom', icon: 'faFileInvoiceSharpThin' },
                link: '/eadvance-invoices',
            },
        ],
    },
    {
        text: 'navbar.pausal.myBusiness',
        type: 'dropdown',
        iconData: { type: 'custom', icon: 'faChartUserRegular' },
        dropdownItems: [
            {
                text: 'navbar.pausal.kpoBook',
                iconData: { type: 'custom', icon: 'faBookUserSolid' },
                link: '/e-invoices/kpo',
            },
            {
                text: 'navbar.pausal.memorandums',
                iconData: { type: 'custom', icon: 'faFilePenRegular' },
                link: '/memorandums/templates',
                otherActivePaths: ['/memorandums/all', '/memorandums/preparation', '/memorandums/sent'],
            },
            {
                text: 'navbar.pausal.listOfArticles',
                iconData: { type: 'custom', icon: 'faWrenchSharpRegular' },
                link: '/list-of-articles',
            },
            {
                text: 'navbar.pausal.listOfClients',
                iconData: { type: 'custom', icon: 'faAddressBookLight' },
                link: '/clients',
            },
            {
                text: 'navbar.pausal.healthCards',
                iconData: { type: 'fa', icon: faNotesMedical },
                link: '/health-cards',
            },
            {
                text: 'navbar.pausal.reports',
                iconData: { type: 'custom', icon: 'faChartMixedUpCircleDollarLight' },
                link: '/statistics/reports',
            },
        ],
    },
    {
        text: 'navbar.pausal.obligations',
        type: 'dropdown',
        iconData: { type: 'custom', icon: 'faBuildingColumnsLight' },
        dropdownItems: [
            {
                text: 'navbar.pausal.transfers',
                iconData: { type: 'custom', icon: 'faHandHoldingCircleDollarLight' },
                link: '/transfer-orders',
            },
            {
                text: 'navbar.pausal.ecotax',
                iconData: { type: 'fa', icon: faLeaf },
                link: '/ecotax',
            },
            {
                text: 'navbar.pausal.customTax',
                iconData: { type: 'fa', icon: faCoins },
                link: '/custom-transfer-orders',
            },
            {
                text: 'navbar.pausal.paymentCalendar',
                iconData: { type: 'fa', icon: faCalendarDays },
                link: `/transfer-orders/payment-list/${new Date().getFullYear()}`,
            },
        ],
    },
    {
        text: 'navbar.pausal.myAgency',
        iconData: { type: 'custom', icon: 'faAddressCardSharp' },
        link: '/agency/profile',
        otherActivePaths: [
            '/agency/user',
            '/agency/tax-solution',
            '/agency/authorization-transfer',
            '/agency/bank-accounts',
            '/agency/payment-instructions',
            '/agency/send-data',
            '/agency/add-logo',
            '/agency/invoice-template',
            '/agency/documents',
        ],
    },
    {
        text: 'navbar.pausal.services',
        iconData: { type: 'fa', icon: faUserNinja },
        link: '/services/1',
        otherActivePaths: ['/services/2', '/services/3', '/services/4'],
    },
    { text: 'navbar.pausal.suggestion', iconData: { type: 'fa', icon: faEnvelope } },
    {
        text: 'navbar.pausal.nbs',
        iconData: { type: 'fa', icon: faRefresh },
        type: 'nbs',
        actionType: 'toggleNbs',
    },
    {
        text: 'navbar.pausal.instructions',
        iconData: { type: 'fa', icon: faCircleInfo },
        type: 'nbs',
        link: '/instructions',
    },
];

type UserDItemType = {
    icon: string;
    label: string;
    link?: string;
    newTab?: boolean;
    handler?: string;
};
export const userDropdownList: UserDItemType[] = [
    {
        icon: 'faCog',
        label: 'navbar.main.settings',
        link: '/settings/notifications',
        newTab: false,
    },
    {
        icon: 'faLifeRing',
        label: 'navbar.main.help',
        link: 'https://pausal.rs/pausal-app/pitanja/',
        newTab: true,
    },
    {
        icon: 'faPaste',
        label: 'navbar.main.privacy',
        link: '/Privacy.pdf',
        newTab: true,
    },
    {
        icon: 'faFile',
        label: 'navbar.main.paymentTerms',
        link: '/Uslovi_plaćanja.pdf',
        newTab: true,
    },
    {
        icon: 'faFile',
        label: 'navbar.main.terms',
        link: '/USLOVI_KORIŠĆENJA_Clever.pdf',
        newTab: true,
    },
    { icon: 'faRightToBracket', label: 'navbar.main.logOut', handler: 'logoutHandler' },
];
