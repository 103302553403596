import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import Loader from '../../../components/loader';
import ReportFilters from './filters';
import ReportsMainTable from './mainTable';
import communication from '../../../communication';
import { ObjectKeys } from '../../../types/objectKeys';
import Pagination from '../../../components/pagination';
import ReportsClientsTable from './clientsTable';
import colors from '../../../global/colors';
import Button, { ButtonVariant } from '../../../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { getAccessToken } from '../../../functions/auth';
import useResponsive from '../../../hooks/responsive/useResponsive';
import { useParams, useSearchParams } from 'react-router-dom';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';

interface FilterProps {
    start_date: string;
    end_date: string;
    type: string;
    client_id?: string;
    currency?: string;
}

const Statistic: FunctionComponent = () => {
    const { isMobile } = useResponsive();
    const { currentLang } = useLanguageStore();
    const [searchParams] = useSearchParams();
    const token = getAccessToken();
    const t = useTranslations(currentLang);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [page, setPage] = useState<number>(1);
    const [reportsData, setReportsData] = useState<ObjectKeys>([]);
    const [props, setProps] = useState<FilterProps>({
        start_date: '',
        end_date: '',
        type: '',
    });
    const activeButton = useParams().tab;

    const paramsClientId = useParams().clientId;
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');

    const [renderKey, setRenderKey] = useState(0);

    useEffect(() => {
        if (!props.start_date || !props.end_date) return;
        setRenderKey((prev) => prev + 1);
        setLoaderVisible(true);
        communication
            .getAllReports(page, props)
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setLoaderVisible(false);
                    setReportsData(res?.data);
                }
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setLoaderVisible(false);
                    setReportsData([]);
                }
            });
    }, [page, props]);

    useEffect(() => {
        setRenderKey((prev) => prev + 1);
    }, [reportsData]);

    const handleFilterChange = (filters: FilterProps): void => {
        setPage(1);
        setProps(filters);
    };

    useEffect(() => {
        if (paramsClientId === 'total' && startDate && endDate) {
            setProps({
                start_date: startDate,
                end_date: `${endDate} 23:59:59`,
                type: 'total',
            });
            setRenderKey((prev) => prev + 1);
        } else if (paramsClientId === 'allclients' && startDate && endDate) {
            setProps({
                start_date: startDate,
                end_date: `${endDate} 23:59:59`,
                type: 'client_single',
            });
            setRenderKey((prev) => prev + 1);
        } else if (paramsClientId === 'limit8milion' && startDate && endDate) {
            setProps({
                start_date: startDate,
                end_date: `${endDate} 23:59:59`,
                type: 'limit8_million_exclude',
            });
            setRenderKey((prev) => prev + 1);
        } else if (
            paramsClientId &&
            paramsClientId !== 'allclients' &&
            paramsClientId !== 'total' &&
            paramsClientId !== 'limit8milion' &&
            startDate &&
            endDate
        ) {
            setProps({
                start_date: startDate,
                end_date: `${endDate} 23:59:59`,
                type: 'client_single',
                client_id: paramsClientId,
            });
            setRenderKey((prev) => prev + 1);
        }
    }, [paramsClientId && startDate && endDate]);

    return (
        <>
            {loaderVisible && <Loader />}
            <Header>
                <h1>{t('pages.reports.title').text}</h1>
            </Header>
            <TabMenu>
                <div style={{ marginLeft: '10px' }}>
                    <span style={{ textTransform: 'uppercase' }}>{t('pages.reports.charts.reports').text}</span>
                </div>
            </TabMenu>
            <PageWrapper className="page">
                <Content>
                    <ReportFilters onFilterChange={handleFilterChange} />
                    {isMobile ? (
                        <MobileItems>
                            {t('pages.reports.mobileText').text}
                            <Buttons>
                                <ButtonContainer>
                                    <Button
                                        variant={ButtonVariant.solid}
                                        color={'var(--purple)'}
                                        icon
                                        onClick={async () => {
                                            const response = await communication.downloadPdf(token, props);
                                            // Creating new object of PDF file
                                            const file = new Blob([response?.data], {
                                                type: 'application/pdf',
                                            });
                                            const fileURL = window.URL.createObjectURL(file);
                                            // Setting various property values
                                            const alink = document.createElement('a');
                                            alink.href = fileURL;
                                            alink.download = t('pages.reports.report_pdf').text;
                                            alink.click();
                                        }}
                                    >
                                        <FontAwesomeIcon color={colors.white} icon={faSave}></FontAwesomeIcon>
                                        {t('pages.reports.download_pdf').text}
                                    </Button>
                                </ButtonContainer>
                                <ButtonContainer>
                                    <Button
                                        variant={ButtonVariant.solid}
                                        color={'var(--purple)'}
                                        icon
                                        onClick={async () => {
                                            const response = await communication.downloadXlsx(token, props);
                                            // Creating new object of PDF file
                                            const file = new Blob([response?.data], {
                                                type: 'application/vnd.ms-excel',
                                            });
                                            const fileURL = window.URL.createObjectURL(file);
                                            // Setting various property values
                                            const alink = document.createElement('a');
                                            alink.href = fileURL;
                                            alink.download = t('pages.reports.report_xlsx').text;
                                            alink.click();
                                        }}
                                    >
                                        <FontAwesomeIcon color={colors.white} icon={faSave}></FontAwesomeIcon>
                                        {t('pages.reports.download_xlsx').text}
                                    </Button>
                                </ButtonContainer>
                            </Buttons>
                        </MobileItems>
                    ) : (
                        <>
                            {props.type === 'total' || props.type === 'limit8_million_exclude' ? (
                                <>
                                    <ReportsMainTable reports={reportsData} filters={props} />
                                    {reportsData?.invoices?.data?.length > 0 &&
                                        reportsData?.invoices?.meta?.pagination?.total_pages > 1 && (
                                            <Pagination
                                                pageCount={reportsData?.invoices?.meta?.pagination?.total_pages}
                                                onPageChange={(e: ObjectKeys) => setPage(e.selected + 1)}
                                                nextLabel={`${
                                                    reportsData?.invoices?.meta?.pagination?.current_page ===
                                                    reportsData?.invoices?.meta?.pagination?.total_pages
                                                        ? ''
                                                        : '>'
                                                }`}
                                                previousLabel={`${
                                                    reportsData?.invoices?.meta?.pagination?.current_page === 1
                                                        ? ''
                                                        : '<'
                                                }`}
                                                breakLabel="..."
                                                initialPage={reportsData?.invoices?.meta?.pagination?.current_page - 1}
                                            />
                                        )}
                                </>
                            ) : null}
                            {props.type === 'client_single' && props.client_id ? (
                                <>
                                    <ReportsClientsTable
                                        reports={reportsData}
                                        filters={props}
                                        loading={loaderVisible}
                                        key={renderKey}
                                    />
                                    {reportsData?.invoices?.data?.length > 0 &&
                                        reportsData?.invoices?.meta?.pagination?.total_pages > 1 && (
                                            <Pagination
                                                pageCount={reportsData?.invoices?.meta?.pagination?.total_pages}
                                                onPageChange={(e: ObjectKeys) => setPage(e.selected + 1)}
                                                nextLabel={`${
                                                    reportsData?.invoices?.meta?.pagination?.current_page ===
                                                    reportsData?.invoices?.meta?.pagination?.total_pages
                                                        ? ''
                                                        : '>'
                                                }`}
                                                previousLabel={`${
                                                    reportsData?.invoices?.meta?.pagination?.current_page === 1
                                                        ? ''
                                                        : '<'
                                                }`}
                                                breakLabel="..."
                                                initialPage={reportsData?.invoices?.meta?.pagination?.current_page - 1}
                                            />
                                        )}
                                </>
                            ) : null}
                            {props.type === 'client_single' && !props.client_id ? (
                                <>
                                    <ReportsClientsTable
                                        reports={reportsData}
                                        filters={props}
                                        loading={loaderVisible}
                                    />
                                    {reportsData?.client?.data?.length > 0 &&
                                        reportsData?.client?.meta?.pagination?.total_pages > 1 && (
                                            <Pagination
                                                pageCount={reportsData?.client?.meta?.pagination?.total_pages}
                                                onPageChange={(e: ObjectKeys) => setPage(e.selected + 1)}
                                                nextLabel={`${
                                                    reportsData?.client?.meta?.pagination?.current_page ===
                                                    reportsData?.client?.meta?.pagination?.total_pages
                                                        ? ''
                                                        : '>'
                                                }`}
                                                previousLabel={`${
                                                    reportsData?.client?.meta?.pagination?.current_page === 1 ? '' : '<'
                                                }`}
                                                breakLabel="..."
                                                initialPage={reportsData?.client?.meta?.pagination?.current_page - 1}
                                            />
                                        )}
                                </>
                            ) : null}
                        </>
                    )}
                </Content>
            </PageWrapper>
        </>
    );
};
const PageWrapper = styled.div``;
const Content = styled.div`
    margin-top: 30px;
`;
const MobileItems = styled.div`
    margin-top: 30px;
    text-align: center;
    font-size: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const Header = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
`;
export const TabMenu = styled.div`
    display: flex;
    background-color: ${colors.purple};
    padding: 10px 20px;
    color: white;

    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileXS}) and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        height: 85px;
        align-items: center;
        justify-content: center;
    }

    @media screen and (min-width: ${MQ_BREAKPOINTS.tablet}) and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        height: 65px;
        align-items: center;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
        height: 65px;
        align-items: center;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.desktop}) and (max-width: ${MQ_BREAKPOINTS.desktopL}) {
        height: 65px;
        align-items: center;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.ultraWide}) {
        height: 65px;
        align-items: center;
    }
    span {
        font-size: 14px;
        cursor: pointer;
        padding: 10px;
        margin-right: 10px;
        background-color: transparent;
        text-decoration: none;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            bottom: 2px;
            left: 0;
            right: 0;
            height: 2px;
            background-color: transparent;
            transition: background-color 0.5s ease;
        }

        &.active:before {
            background-color: white;
        }
    }
`;
const ButtonContainer = styled.div`
    button {
        margin-top: 20px;
        float: right;
        font-size: 12px;
        padding: 8px 20px;
        vertical-align: top;
        border-radius: 100px;
        margin-left: 10px;
        margin-right: 10px;
    }
`;
const Buttons = styled.div`
    display: flex;
`;
export default Statistic;
