import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useBlocker } from 'react-router-dom';
import { isEmpty, some } from 'lodash';
import moment from 'moment';
import useTranslations from '../../../../hooks/useTranslation';
import { getAccessToken } from '../../../../functions/auth';
import { ObjectKeys } from '../../../../types/objectKeys';
import { SendDocumentFormData } from '../../../../types/communication/document';
import communication from '../../../../communication';
import { generateQrCode } from '../../../../communication/qr';
import useLanguageStore from '../../../../store/language';
import useAgencyStore from '../../../../store/agency';
import Modal from '../../../../components/modal';
import Success from '../../../../components/successModal';
import InvoiceClients from '../../../../components/invoiceClients';
import Sidebar from '../../../../components/sideModal';
import Loader from '../../../../components/loader';
import ChangeModal from '../../../../components/changeModal';
import ErrorModal from '../../../../components/errorModal';
import AddClientModal from '../../../myBusiness/clients/addClientModal';
import SendModal from '../../common/sendModal';
import EditClientModal from '../../../myBusiness/clients/editClientModal';
import MobileHeader from '../../common/header/mobileHeader';
import WebHeader from '../../common/header/webHeader';
import WebFooter from '../../common/footer/webFooter';
import PreviewInvoiceModalPlain from '../../common/previewInvoiceModalPlain';
import PreviewInvoiceModalArtistic from '../../common/previewInvoiceModalArtistic';
import PreviewInvoiceModalBusiness from '../../common/previewInvoiceModalBusiness';
import PreviewInvoiceModalInvoicePro from '../../common/previewInvoiceModalInvoicePro';
import PreviewInvoiceModalPlain2 from '../../common/previewInvoiceModalPlain2';
import PreviewInvoiceModalPlainSanta from '../../common/previewInvoiceModalPlainSanta';
import PreviewInvoiceModalPlainPenguin from '../../common/previewInvoiceModalPlainPenguin';
import useUserStore from '../../../../store/user';
import InvoiceEdit from '../invoiceEdit';
import MobileFooter from '../../common/footer/mobileFooter';
import { Body, Container, Invoice } from '../../common/style';
import InvoiceForeignEdit from '../invoiceForeignEdit';
import getAllInvoices from '../getAllInvoices';
import getAllClients from '../../../myBusiness/clients/getAllClients';
import useYearsStore from '../../../../store/years';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Warning from '../../../../components/successModal';
import WarningClientCard from '../../../myBusiness/clients/warningCard';
import countTotal from '../../common/countTotal';
import useUnitsStore from '../../../../store/measurementUnits';
import ConfirmModal from '../../../../components/confirmModal';
import useAllBanksQuery from '../../../../react-query/useAllBanksQuery';
import { validateIbanSwift } from '../../common/ibanSwiftValidate';
import useResponsive from '../../../../hooks/responsive/useResponsive';

const CopyEInvoice: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const { agency } = useAgencyStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const token = getAccessToken();
    const { pathname } = useLocation();
    const invoiceId = useParams().id;
    const isCopiedQuotation = pathname.includes('quotation');
    const isAdvance = pathname.includes('advance');
    const { userInfo } = useUserStore();
    const preferredTemplate = userInfo?.preferred_template?.data.slug;

    const { units } = useUnitsStore();

    const [clients, setClients] = useState<ObjectKeys>([]);
    const [invoice, setInvoice] = useState<ObjectKeys>({});
    const [clickedClient, setClickedClient] = useState('');
    const [success, setSuccess] = useState(false);
    const [changeHappened, setChangeHappened] = useState(false);
    const [newCreatedClient, setNewCreatedClient] = useState<ObjectKeys>({});

    const [totalAvance, setTotalAvance] = useState('');

    const { data } = useAllBanksQuery();
    const [badSwiftOrIban, setBadSwiftOrIban] = useState<boolean>(false);
    const [badSwiftOrIbanModal, setBadSwiftOrIbanModal] = useState<boolean>(false);

    const swiftIbanOptions =
        agency.bankAccounts.data.length > 0
            ? agency.bankAccounts.data
                  .filter((account: ObjectKeys) => account.type === 'foreign')
                  .map((account: ObjectKeys) => ({
                      value: account,
                  }))
            : [];

    //modals
    const [modalMessage, setModalMessage] = useState('');
    const [menuVisible, setMenuVisible] = useState(false);
    const [editClientModal, setEditClientModal] = useState(false);
    const [addClientModal, setAddClientModal] = useState(false);
    const [deleteClientModal, setDeleteClientModal] = useState(false);
    const [deleteInvoiceModal, setDeleteInvoiceModal] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [qrCode, setQrCode] = useState('');
    const [clientChanged, setClientChanged] = useState(0);
    const [clientEmails, setClientEmails] = useState<Array<string>>([]);

    const [warningModal, setWarningModal] = useState(false);
    const [clientsDuplicated, setClientsDuplicated] = useState<ObjectKeys>([]);

    const userOnSef = agency?.e_invoice_api_key ? true : false;

    const { isMobile } = useResponsive();
    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });

    const [isFetched, setIsFetched] = useState(false);

    const updateYears = async (): Promise<void> => {
        const yearsData = await communication.getYears();
        useYearsStore.setState({ years: yearsData?.data?.years });
    };

    useEffect(() => {
        if (!isEmpty(newCreatedClient)) {
            setInvoice((prev: ObjectKeys) => {
                const newInvoiceData = { ...prev };
                newInvoiceData.client_id = newCreatedClient && newCreatedClient?.id;
                newInvoiceData.client.data = newCreatedClient;
                return newInvoiceData;
            });
        }
    }, [newCreatedClient]);

    useEffect(() => {
        setChangeHappened(true);
    }, []);

    const handleClientDelete = async (): Promise<void> => {
        setLoading(true);
        setClientEmails([]);
        await communication
            .deleteClient(clickedClient)
            .then((res: ObjectKeys) => {
                if (res) {
                    getAllClients(setClients, agency?.user_id, invoice.type === 'national' ? 0 : 1);
                    setDeleteClientModal(false);
                    toast.success(t('pages.clients.archiveModal.message').text);
                }
                setInvoice((prev: ObjectKeys) => ({
                    ...prev,
                    client: { data: {} },
                }));
                setLoading(false);
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    toast.error(error.response.data.message);
                    setLoading(false);
                }
            });
    };

    const handleInvoiceDelete = async (): Promise<void> => {
        setDeleteInvoiceModal(false);
        setLoading(true);
        try {
            const res = await communication.deleteInvoice(invoice.id);
            if (res.status === 200) {
                navigate(-1);
                updateYears();
                setLoading(false);
            }
        } catch (e: any) {
            setErrorMessage(e.response.data.message);
            setOpenErrorModal(true);
            setLoading(false);
        }
    };

    const isInvoiceValid = (): boolean => {
        const real = invoice.real_invoice_number;
        if (!real || /^0+$/.test(real)) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.missingInvoiceNumber').text);
            setLoading(false);
            return false;
        }
        if (!invoice.services || invoice.services.length === 0) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.missingService').text);
            setLoading(false);
            return false;
        }

        if (
            some(
                invoice.services,
                (s) =>
                    Math.ceil(Number(s.pivot.quantity.replace(',', '.'))) === 0 ||
                    Math.ceil(Number(s.pivot.price_per_unit.replace(',', '.'))) === 0 ||
                    s.pivot.measurement_unit === '',
            )
        ) {
            setModalMessage(t('pages.editInvoice.invalidInvoice').text);
            setSuccess(true);
            setLoading(false);
            return false;
        }

        if (!invoice.client_id || Object.keys(invoice.client.data).length === 0) {
            setModalMessage(t('pages.editInvoice.missingClient').text);
            setSuccess(true);
            setLoading(false);
            return false;
        }

        if (invoice.trading_place === '') {
            setModalMessage(t('pages.editInvoice.missingTradingPlace').text);
            setSuccess(true);
            setLoading(false);
            return false;
        }
        if (
            !validateIbanSwift(
                invoice.iban,
                invoice.swift,
                invoice.bank_account_id,
                returnBankCodeFromId(invoice.bank_id) === '000' || invoice.type !== 'foreign',
                agency,
                swiftIbanOptions,
                data,
            )
        ) {
            setBadSwiftOrIban(true);
            setBadSwiftOrIbanModal(true);
            setChangeHappened(false);
            setLoading(false);
            return false;
        }

        return true;
    };

    //Add new invoice
    const handleAddNewInvoice = async (): Promise<any> => {
        if (!isInvoiceValid()) return;
        try {
            setChangeHappened(false);
            setLoading(true);
            const newInvoiceData = {
                auto_increment: true,
                bank_account: invoice.type !== 'foreign' ? invoice.bank_account || '' : undefined,
                bank_account_id: invoice.bank_account_id,
                cancel_number: false,
                cancelled: false,
                client: invoice.client,
                client_id: invoice?.client?.data?.id,
                comment: invoice.comment,
                currency: invoice?.currency,
                custom_number: false,
                custom_text: false,
                iban: invoice.type === 'foreign' ? invoice.iban : undefined,
                invoice_date: moment(invoice.invoice_date).format('YYYY-MM-DD').toString(),
                invoice_number: invoice.real_invoice_number,
                invoice_per_owner: true,
                memorandums: invoice.memorandums,
                is_custom: false,
                is_prepaid: false,
                limit8_million_exclude: invoice.limit8_million_exclude,
                paid_date: false,
                paid_value: false,
                prefix: invoice.prefix ? invoice.prefix : null,
                prepaid_invoice_ids: invoice.prepaid_invoice_ids,
                rate: Number(invoice.rate),
                real_invoice_number: invoice.real_invoice_number,
                seal_required: invoice.seal_required,
                sef_invoice_number: invoice.sef_invoice_number ? invoice.sef_invoice_number : null,
                services: invoice.services.map((service: ObjectKeys, i: number) => ({
                    ...service,
                    index: i,
                    id: service.id,
                    measurement_unit: service.pivot.measurement_unit,
                    quantity: service.pivot.quantity.replace(',', '.'),
                    price_per_unit: service.pivot.price_per_unit.replace(',', '.'),
                    discount: service.pivot.discount,
                    pivot: {
                        discount: service.pivot.discount,
                        index: i,
                        measurement_unit: service.pivot.measurement_unit,
                        price_per_unit: service.pivot.price_per_unit.replace(',', '.'),
                        quantity: service.pivot.quantity.replace(',', '.'),
                        total_price: service.pivot.total_price,
                    },
                })),
                status: 'open',
                swift: invoice.type === 'foreign' ? invoice.swift : undefined,
                trading_date: moment(invoice.trading_date).format('YYYY-MM-DD').toString(),
                trading_place: invoice?.trading_place,
                type: invoice.type === 'national' ? 'domestic' : 'foreign',
                user_id: agency?.user_id,
                value: Number(countTotal(invoice.services).replace(',', '.')),
                value_in_rsd: invoice.value_in_rsd,
                value_in_rsd_without_rabat: invoice.value_in_rsd_without_rabat,
                value_without_rabat: invoice.value_without_rabat,
            };

            const res = await communication.newInvoice(newInvoiceData, newInvoiceData.type);
            if (res && res.data && res.data.data) {
                const new_invoice = res.data.data;
                setLoading(false);
                updateYears();
                return {
                    id: new_invoice.id,
                    number: new_invoice.invoice_number,
                    prefix: new_invoice.prefix,
                    agency: new_invoice.original_data?.agency.full_name,
                    client: new_invoice.client.data.company_name,
                    amount: new_invoice.total_for_payment_in_rsd,
                    account: new_invoice.bank_account,
                    currency: new_invoice.currency,
                    foreign: new_invoice.type === 'foreign' ? true : false,
                };
            }
        } catch (e: any) {
            const errorMsg = e.response?.data?.message || 'An unknown error occurred';
            setErrorMessage(errorMsg);
            setOpenErrorModal(true);
            setLoading(false);
            return { error: errorMsg }; // Return an error object
        }
    };

    useEffect(() => {
        setLoading(true);
        if (!isCopiedQuotation) {
            if (invoiceId) {
                communication.getInvoice(invoiceId).then((res: ObjectKeys) => {
                    if (res && res.data && res.data.data) {
                        const invoiceData = res.data.data;
                        setIsFetched(true);
                        setInvoice({
                            ...invoiceData,
                            prepaid_invoices: [],
                            invoice_number: '',
                            prefix: invoiceData.prefix,
                            id: '',
                            sef_invoice_number: userOnSef ? invoiceData.sef_invoice_number : null,
                            memorandums: invoiceData.memorandums?.data?.map((item: { id: string }) => item.id),
                        });
                        if (invoiceData.client?.data) {
                            const emails =
                                invoiceData.client.data.clientEmails?.data?.map((item: ObjectKeys) => item.email) || [];
                            setClientEmails(
                                emails.length > 0
                                    ? emails
                                    : invoiceData.client.data.email
                                    ? [invoiceData.client.data.email]
                                    : [],
                            );
                        } else {
                            setClientEmails([]);
                        }
                        const newUnits = res?.data.data.services
                            .map((item: ObjectKeys) => item.pivot.measurement_unit)
                            .filter((unit: any) => !units.includes(unit));

                        useUnitsStore.setState({ units: [...units, ...newUnits] });
                        setLoading(false);
                        agency &&
                            invoiceData.type !== 'foreign' &&
                            handleGenerateQRCode(
                                agency,
                                invoiceData.client?.data?.company_name,
                                totalAvance ? totalAvance : invoiceData.value_in_rsd,
                                `${invoiceData.prefix ? invoiceData.prefix + ' ' : ''}${invoiceData.invoice_number}`,
                                invoiceData.bank_account,
                                invoiceData.currency,
                                true,
                            );
                    }
                });
            }
        } else {
            if (invoiceId) {
                communication.getProformaById(invoiceId).then((res: ObjectKeys) => {
                    if (res && res.data && res.data.data) {
                        const proformaData = res.data.data;
                        setIsFetched(true);
                        setInvoice({
                            ...proformaData,
                            quotation_number: '',
                            id: '',
                            limit8_million_exclude: false,
                            invoice_date: proformaData.quotation_date,
                            memorandums: proformaData.memorandums?.data?.map((item: { id: string }) => item.id),
                        });
                        if (proformaData.client?.data) {
                            const emails =
                                proformaData.client.data.clientEmails?.data?.map((item: ObjectKeys) => item.email) ||
                                [];
                            setClientEmails(
                                emails.length > 0
                                    ? emails
                                    : proformaData.client.data.email
                                    ? [proformaData.client.data.email]
                                    : [],
                            );
                        } else {
                            setClientEmails([]);
                        }
                        const newUnits = res?.data.data.services
                            .map((item: ObjectKeys) => item.pivot.measurement_unit)
                            .filter((unit: any) => !units.includes(unit));

                        useUnitsStore.setState({ units: [...units, ...newUnits] });
                        setLoading(false);
                        agency &&
                            proformaData.type !== 'foreign' &&
                            handleGenerateQRCode(
                                agency,
                                proformaData.client?.data?.company_name,
                                totalAvance ? totalAvance.toString() : proformaData.value_in_rsd,
                                `${proformaData.prefix ? proformaData.prefix + ' ' : ''}${proformaData.invoice_number}`,
                                proformaData.bank_account,
                                proformaData.currency,
                                true,
                            );
                    }
                });
            }
        }
    }, [invoiceId && isCopiedQuotation]);

    useEffect(() => {
        if (clickedClient) {
            const matchingClient = Array.isArray(clients.data)
                ? clients.data.find((client: ObjectKeys) => client.id === clickedClient)
                : null;
            matchingClient && handleSelectedClient(matchingClient);
        }
    }, [clients]);

    //QR code
    const handleGenerateQRCode = (
        agencyData: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
    ): void => {
        try {
            generateQrCode(
                agencyData.full_name,
                clientData,
                amount,
                invoiceNumber,
                bankAccount,
                currency,
                convert,
                `00${invoiceNumber?.replace('/', '-')}`,
                '221',
            )
                .then((response: ObjectKeys | any) => {
                    if (response.i) setQrCode(response.i);
                    else setQrCode('');
                })
                .catch((error: any) => {
                    setQrCode('');
                    if (error) {
                        console.error(error);
                    }
                });
        } catch (e: any) {
            setQrCode('');
            setErrorMessage(e.response.data.message);
            setOpenErrorModal(true);
        }
    };

    const handleSelectedClient = (client: ObjectKeys): void => {
        setClientChanged(clientChanged + 1);
        setChangeHappened(true);
        if (client) {
            const emails = client.clientEmails?.data?.map((item: ObjectKeys) => item.email) || [];
            setClientEmails(emails.length > 0 ? emails : client.email ? [client.email] : []);
        } else {
            setClientEmails([]);
        }
        setInvoice((prev: ObjectKeys) => {
            const newInvoiceData = { ...prev };
            newInvoiceData.client_id = client?.id;
            newInvoiceData.client.data = client;
            newInvoiceData.currency = client?.currency;
            if (client.is_foreign || client.type === 'civil') {
                newInvoiceData.sef_invoice_number = null;
            }
            return newInvoiceData;
        });
        agency &&
            invoice &&
            invoice.type !== 'foreign' &&
            handleGenerateQRCode(
                agency,
                client.company_name,
                totalAvance ? totalAvance : invoice.value,
                `${invoice.prefix ? invoice.prefix : ''}${invoice.invoice_number}`,
                invoice.bank_account,
                invoice.currency,
                true,
            );
    };

    //Invoice Download
    const handleDownloadInvoice = async (): Promise<void> => {
        const invoiceWithNewDataId = await handleAddNewInvoice();
        if (invoiceWithNewDataId?.error) {
            setErrorMessage(invoiceWithNewDataId.error);
            setOpenErrorModal(true);
            setLoading(false);
            return;
        }
        if (invoiceWithNewDataId) {
            setLoading(true);
            const invoiceNumber = `${invoiceWithNewDataId.prefix ? invoiceWithNewDataId.prefix : ''}${
                invoiceWithNewDataId.number
            }`;
            if (invoiceWithNewDataId.foreign) {
                communication
                    .downloadInvoice(
                        invoiceWithNewDataId.id,
                        token,
                        `${invoice.prefix ? invoice.prefix : ''}${invoice.invoice_number}`,
                        invoice.currency !== 'RSD',
                        false,
                        undefined,
                    )
                    .then(() => {
                        setLoading(false);
                        updateYears();
                        navigate('/invoices');
                        setLoading(false);
                    });
            } else {
                generateQrCode(
                    invoiceWithNewDataId.agency,
                    invoice.client ? invoice.client.data.company_name : {},
                    totalAvance
                        ? totalAvance.toString()
                        : invoiceWithNewDataId.amount
                        ? invoiceWithNewDataId.amount
                        : '0',
                    invoiceNumber,
                    invoiceWithNewDataId.account,
                    invoice.currency,
                    true,
                    invoiceWithNewDataId ? `00${invoiceNumber.toString().replace('/', '-')}` : '',
                    '221',
                )
                    .then(async (res: ObjectKeys | any) => {
                        if (res) {
                            await communication
                                .downloadInvoice(
                                    invoiceWithNewDataId.id,
                                    token,
                                    invoice.invoice_number,
                                    invoice.currency !== 'RSD',
                                    false,
                                    res.i ? res.i : undefined,
                                )
                                .then(() => {
                                    setLoading(false);
                                    updateYears();
                                    navigate('/invoices');
                                    setLoading(false);
                                });
                        } else {
                            console.error('Failed to generate QR code');
                            await communication
                                .downloadInvoice(
                                    invoiceWithNewDataId.id,
                                    token,
                                    `${invoice.prefix ? invoice.prefix : ''}${invoice.invoice_number}`,
                                    invoice.currency !== 'RSD',
                                    false,
                                    undefined,
                                )
                                .then(() => {
                                    setLoading(false);
                                    updateYears();
                                    navigate('/invoices');
                                    setLoading(false);
                                });
                        }
                    })
                    .catch(async (error: ObjectKeys) => {
                        await communication
                            .downloadInvoice(
                                invoiceWithNewDataId.id,
                                token,
                                `${invoice.prefix ? invoice.prefix : ''}${invoice.invoice_number}`,
                                invoice.currency !== 'RSD',
                                false,
                                undefined,
                            )
                            .then(() => {
                                setLoading(false);
                                updateYears();
                                navigate('/invoices');
                                setLoading(false);
                            });
                        console.error(error);
                    });
            }
        }
    };

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => changeHappened && currentLocation.pathname !== nextLocation.pathname,
    );

    const handleTotalChange = (modifiedTotal: any): void => {
        setTotalAvance(modifiedTotal);
    };

    const returnBankCodeFromId = (bankId: string): string => {
        if (data) {
            const bank = data.find((banka: { id: string }) => banka.id === bankId);
            if (bank && bank.code) {
                return bank.code;
            } else {
                return '';
            }
        } else {
            return '';
        }
    };

    return (
        <>
            {loading && <Loader />}
            <Modal modalVisible={blocker.state === 'blocked'} closeModal={() => blocker.reset && blocker.reset()}>
                <ConfirmModal
                    message={t('warnings.youHaveUnsavedChanges').text}
                    close={() => blocker.reset && blocker.reset()}
                    action={() => blocker.proceed && blocker.proceed()}
                />
            </Modal>
            <ToastContainer />
            {warningModal && (
                <Modal modalVisible={warningModal} closeModal={() => setWarningModal(false)}>
                    <Warning
                        close={() => {
                            setWarningModal(false);
                        }}
                    >
                        <WarningClientCard clients={clientsDuplicated} />
                    </Warning>
                </Modal>
            )}
            {badSwiftOrIban && badSwiftOrIbanModal && (
                <Modal
                    modalVisible={true}
                    preventOutsideClickClose={false}
                    closeModal={() => {
                        setBadSwiftOrIbanModal(false);
                    }}
                >
                    <ErrorModal
                        t={t}
                        setOpenModal={() => {
                            navigate('/agency/bank-accounts?=foreign');
                            setBadSwiftOrIbanModal(false);
                        }}
                        errorMessage={t('pages.invoices.bad_iban_or_swift').text}
                        buttonName={t('pages.invoices.enter').text}
                    />
                </Modal>
            )}
            {openErrorModal && (
                <Modal modalVisible={openErrorModal} closeModal={() => setOpenErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            {success && (
                <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                    <Success close={() => setSuccess(false)} message={modalMessage} />
                </Modal>
            )}
            {sendModal && (
                <Sidebar close={() => setSendModal(false)}>
                    <SendModal
                        close={() => setSendModal(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        invoice={invoice}
                        t={t}
                        id={invoice.id}
                        success={() => {
                            setSendModal(false);
                            navigate('/invoices');
                            toast.success(t('pages.invoices.sentSuccess').text);
                        }}
                        loading={(e: boolean) => setLoading(e)}
                        clientEmails={clientEmails}
                        qrCode={qrCode}
                        handleAddNewInvoice={handleAddNewInvoice}
                        errorMessage={(mess: string) => {
                            setErrorMessage(mess);
                            setOpenErrorModal(true);
                        }}
                        modalVisible={sendModal}
                        totalAvance={totalAvance}
                        editInvoice={(updatedInvoice: ObjectKeys) => {
                            setInvoice(updatedInvoice);
                        }}
                    />
                </Sidebar>
            )}
            {addClientModal && (
                <Sidebar close={() => setAddClientModal(false)}>
                    <AddClientModal
                        t={t}
                        clickedClient={invoice.type === 'national' ? 'domestic' : 'foreign'}
                        userId={agency?.user_id}
                        setAddClientModal={setAddClientModal}
                        setClients={setClients}
                        tabIndex={invoice.type === 'national' ? 0 : 1}
                        setNewCreatedClient={setNewCreatedClient}
                        isDuplicate={(duplicated: Array<ObjectKeys>) => {
                            setWarningModal(true);
                            setClientsDuplicated(duplicated);
                        }}
                    />
                </Sidebar>
            )}
            {editClientModal && (
                <Sidebar close={() => setEditClientModal(false)}>
                    <EditClientModal
                        t={t}
                        clickedClient={clickedClient}
                        userId={agency?.user_id}
                        setEditClientModal={setEditClientModal}
                        setClients={setClients}
                        tabIndex={invoice.type === 'national' ? 0 : 1}
                    />
                </Sidebar>
            )}
            {deleteClientModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteClientModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={() => {
                            handleClientDelete();
                            setDeleteClientModal(false);
                        }}
                        close={() => {
                            setDeleteClientModal(false);
                        }}
                        message={`${t('pages.clients.archiveModal.title').text}\n${
                            t('pages.clients.archiveModal.description').text
                        }`}
                    />
                </Modal>
            )}
            {deleteInvoiceModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteInvoiceModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={() => {
                            setChangeHappened(false);
                            handleInvoiceDelete();
                        }}
                        close={() => {
                            setDeleteInvoiceModal(false);
                        }}
                        message={t('pages.editInvoice.deleteInvoice').text}
                    />
                </Modal>
            )}
            {previewModal && (
                <Modal
                    width="700px"
                    className="preview-modal"
                    modalVisible={true}
                    hasCloseButton={true}
                    closeModal={() => {
                        setPreviewModal(false);
                    }}
                >
                    {preferredTemplate === 'plain' && (
                        <PreviewInvoiceModalPlain
                            agency={invoice.original_data ? invoice.original_data.agency : agency}
                            invoice={invoice}
                            title="invoice"
                            type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                            note={invoice.comment}
                            qrCode={qrCode}
                            advanceTotal={totalAvance}
                        />
                    )}
                    {/* {preferredTemplate === 'Artistic' && (
                        <PreviewInvoiceModalArtistic
                            agency={invoice.original_data ? invoice.original_data.agency : agency}
                            invoice={invoice}
                            title="invoice"
                            type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                            note={invoice.comment}
                            qrCode={qrCode}
                            advanceTotal={totalAvance}
                        />
                    )} */}
                    {preferredTemplate === 'business' && (
                        <PreviewInvoiceModalBusiness
                            agency={invoice.original_data ? invoice.original_data.agency : agency}
                            invoice={invoice}
                            title="invoice"
                            type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                            note={invoice.comment}
                            qrCode={qrCode}
                            advanceTotal={totalAvance}
                        />
                    )}
                    {preferredTemplate === 'invoicePro' && (
                        <PreviewInvoiceModalInvoicePro
                            agency={invoice.original_data ? invoice.original_data.agency : agency}
                            invoice={invoice}
                            title="invoice"
                            type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                            note={invoice.comment}
                            qrCode={qrCode}
                            advanceTotal={totalAvance}
                        />
                    )}
                    {preferredTemplate === 'plain_2' && (
                        <PreviewInvoiceModalPlain2
                            agency={invoice.original_data ? invoice.original_data.agency : agency}
                            invoice={invoice}
                            title="invoice"
                            type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                            note={invoice.comment}
                            qrCode={qrCode}
                            advanceTotal={totalAvance}
                        />
                    )}
                    {preferredTemplate === 'santa' && (
                        <PreviewInvoiceModalPlainSanta
                            agency={invoice.original_data ? invoice.original_data.agency : agency}
                            invoice={invoice}
                            title="invoice"
                            type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                            note={invoice.comment}
                            qrCode={qrCode}
                            advanceTotal={totalAvance}
                        />
                    )}
                    {preferredTemplate === 'penguin' && (
                        <PreviewInvoiceModalPlainPenguin
                            agency={invoice.original_data ? invoice.original_data.agency : agency}
                            invoice={invoice}
                            title="invoice"
                            type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                            note={invoice.comment}
                            qrCode={qrCode}
                            advanceTotal={totalAvance}
                        />
                    )}
                </Modal>
            )}

            <Container>
                {isMobile ? (
                    <MobileHeader
                        t={t}
                        handleUpdateInvoice={() => {
                            handleAddNewInvoice().then((response: any) => {
                                if (!response) return;
                                if (response?.error) {
                                    setErrorMessage(response.error);
                                    setOpenErrorModal(true);
                                    return;
                                } else {
                                    navigate('/invoices');
                                }
                            });
                        }}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={() => {
                            isInvoiceValid() && setPreviewModal(true);
                        }}
                        setSendModal={() => {
                            isInvoiceValid() && setSendModal(true);
                        }}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={handleAddNewInvoice}
                        invoice={invoice}
                        handleDownloadInvoice={handleDownloadInvoice}
                        title={pathname.includes('advance') ? 'advance' : 'invoice'}
                        newInvoice={true}
                    />
                ) : (
                    <WebHeader
                        t={t}
                        handleUpdateInvoice={() => {
                            handleAddNewInvoice().then((response: any) => {
                                if (!response) return;
                                if (response?.error) {
                                    setErrorMessage(response.error);
                                    setOpenErrorModal(true);
                                    return;
                                } else {
                                    navigate('/invoices');
                                }
                            });
                        }}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={() => {
                            isInvoiceValid() && setPreviewModal(true);
                        }}
                        setSendModal={() => {
                            isInvoiceValid() && setSendModal(true);
                        }}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={handleAddNewInvoice}
                        invoice={invoice}
                        handleDownloadInvoice={handleDownloadInvoice}
                        title={pathname.includes('advance') ? 'advance' : 'invoice'}
                        newInvoice={true}
                    />
                )}
                <Body className="edit-invoice">
                    <InvoiceClients
                        t={t}
                        setClients={setClients}
                        clients={clients}
                        setAddClientModal={setAddClientModal}
                        agency={agency}
                        handleSelectedClient={handleSelectedClient}
                        addClientModal={addClientModal}
                        deleteClientModal={deleteClientModal}
                        editClientModal={editClientModal}
                        setClickedClient={setClickedClient}
                        setEditClientModal={setEditClientModal}
                        setDeleteClientModal={setDeleteClientModal}
                        invoice={invoice}
                        type={invoice?.type === 'national' ? 'domestic' : invoice?.type === 'foreign' ? 'foreign' : ''}
                        loading={(isLoading: boolean) => setLoading(isLoading)}
                    />
                    {agency && invoice && isFetched && (
                        <Invoice>
                            {invoice?.type === 'national' ? (
                                <InvoiceEdit
                                    // qrCode={qrCode}
                                    name={isAdvance ? 'advance' : 'invoice'}
                                    formData={invoice}
                                    setFormData={setInvoice}
                                    agency={invoice.original_data ? invoice.original_data.agency : agency}
                                    invoice={invoice}
                                    handleTotal={(sum: any) => {
                                        setInvoice({
                                            ...invoice,
                                            value: sum,
                                            total_for_payment: sum,
                                            total_for_payment_in_rsd: sum,
                                        });
                                    }}
                                    newInvoice={true}
                                    isMobile={isMobile}
                                    setLoading={setLoading}
                                    type={'domestic'}
                                    handleTotalAdvance={handleTotalChange}
                                    handleQrCode={(qr: string) => setQrCode(qr)}
                                />
                            ) : (
                                <InvoiceForeignEdit
                                    qrCode={qrCode}
                                    name={isAdvance ? 'advance' : 'invoice'}
                                    formData={invoice}
                                    setFormData={setInvoice}
                                    agency={invoice.original_data ? invoice.original_data.agency : agency}
                                    invoice={invoice}
                                    handleTotal={(sum: any) => {
                                        setInvoice({
                                            ...invoice,
                                            value: sum,
                                            total_for_payment: sum,
                                        });
                                    }}
                                    newInvoice={true}
                                    isMobile={isMobile}
                                    setLoading={setLoading}
                                    type={'foreign'}
                                    clientChanged={clientChanged}
                                    handleChangeHappend={(change: boolean) => {
                                        setChangeHappened(change);
                                    }}
                                />
                            )}
                        </Invoice>
                    )}
                    {isMobile ? (
                        <MobileFooter
                            handleUpdateInvoice={() => {
                                handleAddNewInvoice().then((response: any) => {
                                    if (!response) return;
                                    if (response?.error) {
                                        setErrorMessage(response.error);
                                        setOpenErrorModal(true);
                                        return;
                                    } else {
                                        navigate('/invoices');
                                    }
                                });
                            }}
                            setDeleteInvoiceModal={setDeleteInvoiceModal}
                            setPreviewModal={() => {
                                isInvoiceValid() && setPreviewModal(true);
                            }}
                            setSendModal={() => {
                                isInvoiceValid() && setSendModal(true);
                            }}
                            handleDownloadInvoice={handleDownloadInvoice}
                            invoice={invoice}
                        />
                    ) : (
                        <WebFooter
                            t={t}
                            handleUpdateInvoice={() => {
                                handleAddNewInvoice().then((response: any) => {
                                    if (!response) return;
                                    if (response?.error) {
                                        setErrorMessage(response.error);
                                        setOpenErrorModal(true);
                                        return;
                                    } else {
                                        navigate('/invoices');
                                    }
                                });
                            }}
                            setDeleteInvoiceModal={setDeleteInvoiceModal}
                            setPreviewModal={() => {
                                isInvoiceValid() && setPreviewModal(true);
                            }}
                            setSendModal={() => {
                                isInvoiceValid() && setSendModal(true);
                            }}
                            handleDownloadInvoice={handleDownloadInvoice}
                            invoice={invoice}
                        />
                    )}
                </Body>
            </Container>
        </>
    );
};
export default CopyEInvoice;
