import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../constants/breakpoints';

const useResponsive = () => {
    const isMobileXS = useMediaQuery({ query: `(max-width: ${breakpoints.mobileS - 1}px)` });
    const isMobileS = useMediaQuery({ query: `(max-width: ${breakpoints.mobileM - 1}px)` });
    const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.tablet - 1}px)` });
    const isMobileM = useMediaQuery({
        query: `(min-width: ${breakpoints.mobileM}px) and (max-width: ${breakpoints.mobileL - 1}px)`,
    });
    const isMobileL = useMediaQuery({
        query: `(min-width: ${breakpoints.mobileL}px) and (max-width: ${breakpoints.tablet - 1}px)`,
    });
    const isTablet = useMediaQuery({
        query: `(min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.laptop - 1}px)`,
    });
    const isLaptop = useMediaQuery({
        query: `(min-width: ${breakpoints.laptop}px) and (max-width: ${breakpoints.laptopL - 1}px)`,
    });
    const isLaptopL = useMediaQuery({
        query: `(min-width: ${breakpoints.laptopL}px) and (max-width: ${breakpoints.desktop - 1}px)`,
    });
    const isDesktop = useMediaQuery({
        query: `(min-width: ${breakpoints.desktop}px) and (max-width: ${breakpoints.desktopL - 1}px)`,
    });
    const isDesktopL = useMediaQuery({
        query: `(min-width: ${breakpoints.desktopL}px) and (max-width: ${breakpoints.ultraWide - 1}px)`,
    });
    const isUltraWide = useMediaQuery({ query: `(min-width: ${breakpoints.ultraWide}px)` });

    const isTabletAndDown = useMediaQuery({ query: `(max-width: ${breakpoints.laptop - 1}px)` });
    const isLaptopAndDown = useMediaQuery({ query: `(max-width: ${breakpoints.laptopL}px)` });
    const isLaptopMediumAndDown = useMediaQuery({ query: `(max-width: ${breakpoints.laptopM}px)` });
    const isDesktopMediumAndDown = useMediaQuery({ query: '(max-width: 2120px)' });
    const isDesktopLargeAndDown = useMediaQuery({ query: `(max-width: ${breakpoints.ultraWide - 1}px)` });
    const isBiggerThanMobile = useMediaQuery({ query: `(min-width: ${breakpoints.tablet}px)` });

    return {
        isMobileXS,
        isMobileS,
        isMobile,
        isMobileM,
        isMobileL,
        isTablet,
        isLaptop,
        isLaptopL,
        isDesktop,
        isDesktopL,
        isUltraWide,
        isTabletAndDown,
        isLaptopAndDown,
        isLaptopMediumAndDown,
        isDesktopMediumAndDown,
        isDesktopLargeAndDown,
        isBiggerThanMobile,
    };
};

export default useResponsive;
