import Input, { InputComponentType } from '../../../../components/input';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

interface Props {
    modalVisible: boolean;
    closeModal: Function;
    hasCloseButton?: boolean;
    size?: number | string;
    className?: string | '';
    width?: string;
    preventOutsideClickClose?: boolean;
    heading?: string;
}

const Modal: FunctionComponent<Props> = ({
    className,
    modalVisible,
    closeModal,
    size,
    width,
    hasCloseButton = false,
    preventOutsideClickClose = false,
    heading,
}) => {
    const [password, setPassword] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<boolean>(false);
    const navigate = useNavigate();
    const managementPassword = process.env.REACT_APP_BO_MANAGEMENT_PASSWORD;
    const marketingPassword = process.env.REACT_APP_BO_MARKETING_PASSWORD;
    const supportPassword = process.env.REACT_APP_BO_SUPPORT_PASSWORD;

    const handleSubmit = (): void => {
        if (!managementPassword || !marketingPassword || !supportPassword || !heading) return;
        let requiredPassword: string | undefined = '';
        if (heading === 'management') {
            requiredPassword = managementPassword;
        } else if (heading === 'marketing') {
            requiredPassword = marketingPassword;
        } else if (heading === 'support') {
            requiredPassword = supportPassword;
        }

        if (password !== requiredPassword) {
            setErrorMessage(true);
        } else {
            sessionStorage.setItem('admin-permission-role', heading);

            setErrorMessage(false);
            navigate('/admin/users');
        }
    };
    const handleClose = (): void => {
        closeModal();
        setPassword('');
        setErrorMessage(false);
    };

    const capitalizeFirstLetter = (value: string | undefined): string | undefined => {
        if (!value) return;
        const capitalizedWord = value.charAt(0).toUpperCase() + value.slice(1);

        return capitalizedWord;
    };
    return (
        <StyledModal
            className={`${className ? className : ''} ${modalVisible ? 'visible' : ''}`}
            closeModal={closeModal}
            size={size}
            width={width}
            modalVisible={modalVisible}
        >
            {hasCloseButton && <FontAwesomeIcon icon={faXmark} className="close-icon" onClick={handleClose} />}
            <div
                className="modal-backdrop"
                onClick={() => !preventOutsideClickClose && closeModal() && handleClose()}
            />
            <div className="modal-wrapper">
                <FontAwesomeIcon icon={faXmark} className="close-icon" onClick={handleClose} />
                <h2>Unesite šifru za sekciju {capitalizeFirstLetter(heading)}</h2>
                <div className="modal-content">
                    <Input
                        type={InputComponentType.Password}
                        value={password}
                        onChange={(pass: string) => setPassword(pass)}
                        onEnter={handleSubmit}
                        label="Unesite šifru"
                    />
                    {errorMessage && <p className="error-message">Pogrešna šifra!</p>}
                    <ConfirmButton onClick={handleSubmit}>Potvrdi</ConfirmButton>
                </div>
            </div>
        </StyledModal>
    );
};

const StyledModal = styled.div<Props>`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    opacity: 0;
    display: flex;
    pointer-events: none;
    justify-content: center;
    align-items: center;
    transition: 0.2s all ease-in-out;

    &.visible {
        opacity: 1;
        pointer-events: all;
    }

    .modal-backdrop {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        background-color: rgba(0, 0, 0, 0.6);
    }

    .modal-wrapper {
        background: var(--white);
        width: ${(props) => (props.width ? props.width : '495px')};
        height: 255px;
        max-width: 100%;
        margin: 0 auto;
        border-radius: 8px;
        box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        padding: 2rem;
        justify-content: space-between;

        h2 {
            margin: 0;
            font-size: 1.5rem;
            text-align: center;
            color: var(--purple);
        }

        .modal-content {
            display: flex;
            justify-content: space-between;
            height: 100%;
            padding: 20px 27px;
            flex-direction: column;
            gap: 1rem;

            .error-message {
                color: red;
            }
        }

        @keyframes slide-up {
            0% {
                opacity: 0;
                top: 0;
                transform: translateY(0);
            }
            100% {
                opacity: 1;
                transform: translateY(-50%);
                top: 50%;
            }
        }

        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            max-width: 90vw;
            margin: 0 auto;
            position: fixed;
            z-index: 200000;
            left: 0;
            right: 0;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .close-icon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        color: var(--purple);
        height: 20px;
        width: 20px;
        cursor: pointer;
        z-index: 999999;
    }
`;

const ConfirmButton = styled.button`
    width: 100%;
    padding: 1rem;
    border: none;
    border-radius: 5px;
    background-color: var(--purple);
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;

    &:hover {
        background-color: white;
        color: var(--purple);
        border: 1px solid var(--purple);
    }

    &:focus {
        outline: none;
    }
`;

export default Modal;
