import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

interface UserPermissions {
    url: string;
    roles: string[];
}

export const useBackOfficePasswordProtect = (): void => {
    const navigate = useNavigate();
    const location = useLocation();
    const role = sessionStorage.getItem('admin-permission-role');

    useEffect(() => {
        if (!role) {
            alert('You don`t have access to this page!');
            navigate('/admin/admin-dashboard');
            return;
        }

        const matchedPermission = permissionsArray.find((permission) => location.pathname.startsWith(permission.url));

        if (matchedPermission) {
            const roleIsValid = matchedPermission.roles.includes(role);

            if (!roleIsValid) {
                navigate('/admin/admin-dashboard');
                alert('You do not have permission to access this page!');
            }
        } else {
            alert('URL you`ve entered is invalid!');
        }
    }, [role, location, navigate]);
};

export const permissionsArray: UserPermissions[] = [
    { url: '/admin/admin-dashboard', roles: ['management', 'marketing', 'support', ''] },
    { url: '/admin/users', roles: ['management', 'marketing', 'support'] },
    { url: '/admin/invoices', roles: ['management', 'marketing', 'support'] },
    { url: '/admin/advances', roles: ['management', 'marketing', 'support'] },
    { url: '/admin/proforma', roles: ['management', 'marketing', 'support'] },
    { url: '/admin/transfer-orders', roles: ['management', 'marketing', 'support'] },
    { url: '/admin/ecotax', roles: ['management', 'marketing', 'support'] },
    { url: '/admin/suggestions', roles: ['management', 'marketing', 'support'] },
    { url: '/admin/tax', roles: ['management', 'marketing', 'support'] },
    { url: '/admin/requests', roles: ['management', 'marketing', 'support'] },
    { url: '/admin/notifications', roles: ['management', 'marketing'] },
    { url: '/admin/promo-codes', roles: ['management'] },
    { url: '/admin/transactions', roles: ['management'] },
    { url: '/admin/statistics', roles: ['management'] },
    { url: '/admin/benefits', roles: ['management'] },
    { url: '/admin/packages', roles: ['management'] },
    { url: '/admin/services', roles: ['management'] },
    { url: '/admin/google-analytics', roles: ['management'] },
    { url: '/admin/finance', roles: ['management', 'support'] },
];
