import { FunctionComponent, Dispatch, SetStateAction, useState, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { faBars, faBorderAll } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../../../global/colors';
import { ObjectKeys } from '../../../../types/objectKeys';
import useTranslations from '../../../../hooks/useTranslation';
import useLanguageStore from '../../../../store/language';
import useYearsStore from '../../../../store/years';
import useCurrencyStore from '../../../../store/currency';
import SelectComponent from '../../../../components/select';
import Search from '../../../../components/input/search';
import useResponsive from '../../../../hooks/responsive/useResponsive';
import {
    StyledBottomFilters,
    StyledDisplayIcons,
    StyledFiltersInner,
    StyledFiltersWrapper,
    StyledShowSelect,
    StyledStatusFilter,
    StyledTopFilters,
} from '../../common/styled-filters';
import styled from 'styled-components';

interface Props {
    setParams: Dispatch<SetStateAction<any>>;
    params: ObjectKeys;
    searchValue?: string;
    setSearchValue: Dispatch<SetStateAction<string>>;
    setDisplay: Dispatch<SetStateAction<string>>;
    selectedUserView?: string;
}

const Filters: FunctionComponent<Props> = ({
    setParams,
    params,
    searchValue,
    setSearchValue,
    setDisplay,
    selectedUserView,
}) => {
    const role = localStorage.getItem('role');
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { years } = useYearsStore();
    const { currency } = useCurrencyStore();
    const { isDesktop } = useResponsive();

    const [renderKey, setRenderKey] = useState(0);
    const [showSearch, setShowSearch] = useState(false);

    const [currencies, setCurrencies] = useState<Array<{ value: string; label: string }>>([]);
    const [yearsOptions, setYearsOptions] = useState<Array<{ value: any; label: any }>>([]);
    const [status, setStatus] = useState<Array<{ value: string; label: string }>>([]);

    const [selectedYear, setSelectedYear] = useState({
        value: '',
        label: '',
    });

    const [showItems] = useState([
        { value: '10', label: '10' },
        { value: '20', label: '20' },
        { value: '30', label: '30' },
        { value: '40', label: '40' },
    ]);

    const yearsArrayBO: Array<{ value: any; label: any }> = [];
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= 1999; year--) {
        yearsArrayBO.push({ value: year, label: year });
    }

    useEffect(() => {
        const yearsData = years?.map((year) => {
            return { value: year, label: year };
        });
        if (role === 'admin') {
            setYearsOptions([{ value: '', label: t('pages.invoices.filters.status.all').text }, ...yearsArrayBO]);
            return;
        } else {
            setYearsOptions([{ value: '', label: t('pages.invoices.filters.status.all').text }, ...yearsData]);
        }
        setRenderKey((prev) => prev + 1);
    }, [useYearsStore()]);

    useEffect(() => {
        const currencyData = currency?.map((curr) => {
            return { value: curr.currency.toUpperCase(), label: curr.currency };
        });
        const yearsData = years?.map((year) => {
            return { value: year, label: year };
        });

        setCurrencies([{ value: '', label: t('pages.invoices.filters.status.all').text }, ...currencyData]);
        if (role === 'admin') {
            setYearsOptions([{ value: '', label: t('pages.invoices.filters.status.all').text }, ...yearsArrayBO]);
            return;
        } else {
            setYearsOptions([{ value: '', label: t('pages.invoices.filters.status.all').text }, ...yearsData]);
        }
    }, [currentLang]);

    useEffect(() => {
        setStatus([
            { value: '', label: t('pages.invoices.filters.status.all').text },
            { value: 'sent', label: t('pages.invoices.filters.status.sent').text },
            { value: 'open', label: t('pages.invoices.filters.status.draft').text },
            { value: 'paid,partially', label: t('pages.invoices.filters.status.paid').text },
            { value: 'sent', label: t('pages.invoices.filters.status.unpaid').text },
            { value: 'cancelled', label: t('pages.invoices.filters.status.void').text },
        ]);
    }, [currentLang]);

    return (
        <StyledFiltersWrapper>
            <StyledTopFilters>
                <StyledFiltersInner>
                    {!isDesktop ? (
                        <SelectComponent
                            className="select-container"
                            optionList={status}
                            label={t('pages.invoices.filters.statusFilter').text}
                            handleSelectedValue={(value: string) => {
                                setParams({
                                    ...params,
                                    search: { ...params.search, status: value },
                                    page: 1,
                                    searchFields:
                                        value !== ''
                                            ? { ...params.searchFields, status: 'in' }
                                            : { ...params.searchFields, status: value },
                                });
                            }}
                            defaultSelectedOption={status[0]}
                        />
                    ) : (
                        <StyledStatusFilter className={`${params?.search?.status ? '' : 'first-active'}`}>
                            <label>status</label>
                            {status.map((s) => {
                                return (
                                    <p
                                        className={`${
                                            params?.search?.status === s?.value ? 'active' : ''
                                        } cursor-pointer`.trim()}
                                        key={nanoid()}
                                        onClick={() => {
                                            setParams({
                                                ...params,
                                                search: { ...params.search, status: s.value },
                                                page: 1,
                                                searchFields:
                                                    s.value !== ''
                                                        ? { ...params.searchFields, status: 'in' }
                                                        : { ...params.searchFields, status: s.value },
                                            });
                                        }}
                                    >
                                        {s.label}
                                    </p>
                                );
                            })}
                        </StyledStatusFilter>
                    )}
                    <SelectComponent
                        className="select-container"
                        optionList={currencies}
                        label={t('pages.invoices.filters.currency.label').text}
                        handleSelectedValue={(value: string) =>
                            setParams({
                                ...params,
                                search: { ...params.search, currency: value },
                                page: 1,
                                searchFields:
                                    value !== ''
                                        ? { ...params.searchFields, currency: '=' }
                                        : { ...params.searchFields, currency: value },
                            })
                        }
                        defaultSelectedOption={currencies[0]}
                    />
                    <SelectComponent
                        className="select-container"
                        optionList={yearsOptions}
                        label={t('pages.invoices.filters.year.label').text}
                        handleSelectedValue={(value: string) => {
                            setSelectedYear({ value: value, label: value });
                            const parsedYear = parseInt(value);
                            const date = `${parsedYear}-01-01,${parsedYear}-12-31 23:59:59`;
                            setParams({
                                ...params,
                                search:
                                    value !== ''
                                        ? { ...params.search, invoice_date: date }
                                        : { ...params.search, invoice_date: value },
                                page: 1,
                                searchFields:
                                    value !== ''
                                        ? {
                                              ...params.searchFields,
                                              invoice_date: 'between',
                                          }
                                        : {
                                              ...params.searchFields,
                                              invoice_date: value,
                                          },
                            });
                        }}
                        defaultSelectedOption={selectedYear.value ? selectedYear : yearsOptions[0]}
                        key={renderKey}
                    />
                </StyledFiltersInner>
                <StyledDisplayIcons>
                    <FontAwesomeIcon
                        icon={faBars}
                        color={colors.white}
                        className={`cursor-pointer ${selectedUserView === 'list' && 'userViewSelection'}`}
                        onClick={() => setDisplay('list')}
                    />
                    <FontAwesomeIcon
                        icon={faBorderAll}
                        color={colors.white}
                        className={`cursor-pointer ${selectedUserView === 'grid' && 'userViewSelection'}`}
                        onClick={() => setDisplay('grid')}
                    />
                </StyledDisplayIcons>
            </StyledTopFilters>
            <StyledBottomFilters>
                <StyledShowSelect>
                    <SelectComponent
                        optionList={showItems}
                        label={t('pages.invoices.filters.show.label').text}
                        handleSelectedValue={(value: string) => setParams({ ...params, limit: value })}
                        defaultSelectedOption={showItems[0]}
                    />
                    <p>{t('pages.invoices.filters.show.invoice').text}</p>
                </StyledShowSelect>
                <Search
                    placeholder={t('pages.invoices.filters.search').text}
                    value={searchValue}
                    onChange={(value: string) => {
                        setSearchValue(value);
                        setShowSearch(true);
                    }}
                    onLeaveOrEnter={(value: string) => {
                        setShowSearch(false);
                        setSearchValue(value);
                        setParams({
                            ...params,
                            search: { ...params.search, 'client.company_name': value },
                            page: 1,
                            searchFields:
                                value !== ''
                                    ? { ...params.searchFields, 'client.company_name': 'like' }
                                    : { ...params.searchFields, 'client.company_name': value },
                        });
                    }}
                    showSearchNote={showSearch}
                />
            </StyledBottomFilters>
        </StyledFiltersWrapper>
    );
};

export default Filters;
