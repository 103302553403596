import { FunctionComponent, useEffect, useState, SetStateAction, Dispatch, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCircleQuestion, faCircleExclamation, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import colors from '../../../../global/colors';
import communication from '../../../../communication';
import useLanguageStore from '../../../../store/language';
import useCurrencyStore from '../../../../store/currency';
import useTranslations from '../../../../hooks/useTranslation';
import { formatNumber } from '../../../../functions/format';
import { ObjectKeys } from '../../../../types/objectKeys';
import Input, { InputComponentType } from '../../../../components/input';
import ServiceModal from '../../common/serviceModal';
import TextArea from '../../../../components/input/textarea';
import { useLocation, useNavigate } from 'react-router-dom';
import Tooltip from '../../../../components/tooltipMain';
import { getUnitByValue } from '../../common/measurementUnits';
import useAgencyStore from '../../../../store/agency';
import { validateIbanSwift } from '../../common/ibanSwiftValidate';
import logo from '../../../../assets/pausal-logo-gray.png';

import {
    Container,
    Header,
    AddServiceCurrency,
    AddService,
    AddServiceButton,
    Agency,
    Comments,
    Row,
    Total,
    Wrapper,
    Limit,
    Footer,
    MemoSelect,
    GroupedMemoSection,
    MemoDisplaySection,
} from '../invoiceEdit/style';
import WebService from '../../common/service/webService';
import MobileService from '../../common/service/mobileService';
import SelectComponent, { SelectOption } from '../../../../components/select';
import SelectAdvanceComponent from '../../../../components/selectAdvances';
import { getAllMeasurementUnits } from '../../common/measurementUnits';
import getAdvances from '../invoiceEdit/getAdvances';
import CloseAdvanceModal from '../invoiceEdit/closeAdvanceModal';
import PayedAdvance from '../invoiceEdit/payedAdvance';
import countTotal from '../../common/countTotal';
import Modal from '../../../../components/modal';
import ErrorModal from '../../../../components/errorModal';
import { formatTwoDecimals } from '../../../../functions/format';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../../../components/sideModal';
import AddArticleModal from '../../common/addArticleModal';
import Loader from '../../../../components/loader';
import useUnitsStore from '../../../../store/measurementUnits';
import useAllBanksQuery from '../../../../react-query/useAllBanksQuery';
import Checkbox from '../../../../components/checkbox';
import useResponsive from '../../../../hooks/responsive/useResponsive';
import { useMemosQuery } from '../../../../react-query/useMemosQuery';
import SelectSearch from '../../../../components/selectSearch';

interface Props {
    invoice: ObjectKeys;
    agency: ObjectKeys;
    formData: ObjectKeys;
    setFormData: Function;
    handleTotal: Function;
    isMobile: boolean;
    newInvoice?: boolean;
    type?: string;
    name?: string;
    setLoading: Dispatch<SetStateAction<boolean>>;
    qrCode?: string;
    userId?: string;
    clientChanged?: Number;
    handleTotalAdvance?: Function;
    handleChangeHappend?: Function;
}

const InvoiceForeignEdit: FunctionComponent<Props> = ({
    invoice,
    agency,
    formData,
    setFormData,
    handleTotal,
    isMobile,
    // type,
    name,
    setLoading,
    clientChanged,
    userId,
    handleTotalAdvance,
    handleChangeHappend,
    newInvoice,
}) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const isAdvance = pathname.includes('advance');
    const isCopy = pathname.includes('copy');
    const isEdit = pathname.includes('edit');
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { units } = useUnitsStore();
    const { currency } = useCurrencyStore();

    const tooltipTradingPlace = t('pages.editInvoice.tradingPlaceTooltip').text;
    const tooltipPrefix = t('pages.editInvoice.prefix_tooltip').text;
    const tooltipLimit8milion = t('pages.editInvoice.limit8milionTooltip').text;

    const [clickedField, setClickedField] = useState('');
    const [services, setServices] = useState<ObjectKeys[]>([]);
    const [advances, setAdvances] = useState<ObjectKeys[]>([]);
    const [coreAdvances, setCoreAdvances] = useState<ObjectKeys[]>([]);
    const [currencies, setCurrencies] = useState<SelectOption[]>([]);
    const [closeAdvanceModal, setCloseAdvanceModal] = useState(false);
    const [selectedAdvances, setSelectedAdvances] = useState<ObjectKeys[]>([]);
    const [closedAdvances, setClosedAdvances] = useState<ObjectKeys[]>([]);
    const [modal, setModal] = useState(false);
    const [modalMessage, _setModalMessage] = useState('');
    const [advancedInvoice, setAdvancedInvoice] = useState<ObjectKeys>({});
    const { isTabletAndDown } = useResponsive();

    const { data } = useAllBanksQuery();

    const [selectedType, setSelectedType] = useState<'service' | 'product'>('service');

    const [measurementUnits, setMeasurementUnits] = useState<Array<{ value: string; label: string }>>([]);

    const [isAddServiceModalOpen, setIsAddServiceModalOpen] = useState(false);
    const [isAddArticleModalOpen, setIsAddArticleModalOpen] = useState(false);

    const [isServiceUpdating, setIsServiceUpdating] = useState<boolean>(false);
    const [updatingServiceId, setUpdatingServiceId] = useState<string | null>(null);
    const [updatingServiceIndex, setUpdatingServiceIndex] = useState<number | null>(null);

    const [totalWithoutRabat, setTotalWithoutRabat] = useState<number | null>(null);
    const [rabat, setRabat] = useState<number | null>(null);

    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);

    const [replaceArticle, setReplaceArticle] = useState<string | null>(null);

    const [badSwiftOrIban, setBadSwiftOrIban] = useState<boolean>(false);
    const [badSwiftOrIbanModal, setBadSwiftOrIbanModal] = useState<boolean>(false);

    const [changedYear, setChangedYear] = useState<boolean>(false);

    const agencyData = useAgencyStore((props) => props.agency);
    const currentYear = new Date().getFullYear().toString();

    const returnInvoiceYear = (invoiceDate: string | null): string => {
        if (!invoiceDate || invoiceDate.trim() === '') {
            return currentYear;
        }
        return invoiceDate.split('-')[0];
    };
    const isFirstRender = useRef(true);
    const invoiceYear = returnInvoiceYear(invoice.invoice_date);

    const [prefixRenderKey, setPrefixRenderKey] = useState<number>(0);

    const { memos } = useMemosQuery();
    const [memoForSelect, setMemoForSelect] = useState([]);

    const [params] = useState({
        page: 1,
        limit: '5000',
        search: `user_id:${localStorage.getItem('role') === 'admin' ? userId : agency?.user_id}`,
        searchFields: 'user_id:=;is_foreign:=;',
        searchJoin: 'and',
    });

    //get services and sort them by created_at date
    const getServices = async (): Promise<ObjectKeys> => {
        const res = await communication.getAllArticles(params);
        const newUnits = res?.data.data
            .map((item: ObjectKeys) => item.default_measurement_unit)
            .filter((unit: any) => !units.includes(unit));

        useUnitsStore.setState({ units: [...units, ...newUnits] });
        const servicesData = res.data.data;
        //order services by created_at date
        const orderedServices = servicesData.sort((a: { created_at: string }, b: { created_at: string }) => {
            const dateA = new Date(a.created_at).getTime();
            const dateB = new Date(b.created_at).getTime();
            return dateB - dateA;
        });

        setServices(orderedServices || []);

        return orderedServices;
    };

    //trigger services refetch
    const triggerServicesRefetch = async (): Promise<ObjectKeys> => {
        const orderedServices = await getServices();
        return orderedServices;
    };

    const calculateTotalAmountWithoutDiscounts = (items: ObjectKeys): void => {
        if (items && items.length > 0) {
            const totalResult = items.reduce(
                (accumulator: ObjectKeys, service: ObjectKeys) => {
                    const { pivot } = service;
                    if (pivot) {
                        const quantity = pivot.quantity ? Number(pivot.quantity.replace(',', '.')) : 0;
                        const pricePerUnit = pivot.price_per_unit ? Number(pivot.price_per_unit.replace(',', '.')) : 0;
                        const discount = pivot.discount ? Number(pivot.discount) : 0;
                        const amountWithoutDiscount = quantity * pricePerUnit;
                        const discountAmount = quantity * pricePerUnit * (discount / 100);

                        accumulator.totalWithoutDiscount += amountWithoutDiscount;
                        accumulator.totalDiscount += discountAmount;
                    }
                    return accumulator;
                },
                { totalWithoutDiscount: 0, totalDiscount: 0 },
            );

            setTotalWithoutRabat(totalResult.totalWithoutDiscount);
            setRabat(totalResult.totalDiscount);
            setFormData({
                ...formData,
                value_without_rabat: totalResult.totalWithoutDiscount,
            });
        } else {
            setTotalWithoutRabat(0);
            setRabat(0);
        }
    };

    const checkBankAccount = (account: string): string => {
        const bank = data?.find((banka: ObjectKeys) => banka.id === account);
        return bank ? bank.name : t('pages.invoices.bank_not_found').text;
    };

    const checkBankFromId = (id: string): string => {
        const bank = data?.find((banka: ObjectKeys) => banka.id === id);
        return bank ? bank.name : t('pages.invoices.bank_not_found').text;
    };

    const swiftIbanOptions = [
        {
            value: 'empty_account',
            label: t('pages.invoices.choose_account').text,
        },
        ...(agencyData.bankAccounts?.data.length > 0
            ? agencyData.bankAccounts.data
                  .filter((account: ObjectKeys) => account.type === 'foreign')
                  .map((account: ObjectKeys) => ({
                      value: account,
                      label: `${checkBankAccount(account.bank_id ? account.bank_id : '')}|${
                          account.currency ? account.currency : ''
                      }`,
                  }))
            : []),
        {
            value: 'add_account',
            label: t('pages.invoices.add_account').text,
        },
    ];

    const getInvoiceNumberAndPrefix = async (year: string): Promise<void> => {
        try {
            const res = await communication.getInvoiceNumberAndPrefix(year);
            if (res) {
                if (isCopy || isEdit) {
                    setFormData((prev: ObjectKeys) => ({
                        ...prev,
                        real_invoice_number: res.data.feature_invoice_number.toString(),
                        invoice_number: `${res.data.feature_invoice_number}/${year}`,
                    }));
                    setPrefixRenderKey(prefixRenderKey + 1);
                } else {
                    setFormData((prev: ObjectKeys) => ({
                        ...prev,
                        real_invoice_number: res.data.feature_invoice_number.toString(),
                        invoice_number: `${res.data.feature_invoice_number}/${year}`,
                        prefix: res.data.last_prefix,
                    }));
                    setPrefixRenderKey(prefixRenderKey + 1);
                }
            }
        } catch (e: any) {
            console.error('Error while fetching invoice number and prefix', e);
        }
    };

    useEffect(() => {
        calculateTotalAmountWithoutDiscounts(invoice.services);
    }, [invoice.value]);

    useEffect(() => {
        calculateTotalAmountWithoutDiscounts(formData.services);
    }, [formData.services?.length]);

    useEffect(() => {
        if (memos) {
            setMemoForSelect(
                memos.map((memo: ObjectKeys) => {
                    return {
                        value: memo.id,
                        label: memo.heading,
                    };
                }),
            );
        }
    }, [memos]);

    //transform service data for invoice services property
    const transformServiceForInvoice = (service: ObjectKeys): ObjectKeys | null => {
        if (service) {
            return {
                ...service,
                pivot: {
                    index: (formData.services?.length || 0) + 1,
                    measurement_unit: service.default_measurement_unit || '',
                    price_per_unit: service.default_price?.toString() || '',
                    quantity: service.default_quantity || '',
                    discount: '0',
                },
            };
        }
        return null;
    };

    //after refetching add last added service to the invoice
    const triggerRefetchAddLastAddedService = async (): Promise<void> => {
        setLoaderVisible(true);

        const orderedServices = await triggerServicesRefetch();

        if (replaceArticle) {
            setFormData((prev: ObjectKeys) => {
                const addedServices = prev.services || [];
                const newAddedService = transformServiceForInvoice(orderedServices[0] || null);

                const updatedServices = addedServices.map((service: ObjectKeys) =>
                    service.id === replaceArticle ? newAddedService : service,
                );

                return {
                    ...prev,
                    services: updatedServices,
                };
            });
            setReplaceArticle(null);
            setLoaderVisible(false);
        } else {
            setFormData((prev: ObjectKeys) => {
                const addedServices = prev.services || [];
                const newAddedService = transformServiceForInvoice(orderedServices[0] || null);
                if (newAddedService) {
                    return {
                        ...prev,
                        services: [...addedServices, newAddedService],
                    };
                } else {
                    return prev;
                }
            });
            setLoaderVisible(false);
        }
    };

    //add selected service to invoice
    const handleAddService = (service_id: string): void => {
        const selectedService = services.find((service) => service.id === service_id);

        if (selectedService) {
            setFormData((prev: ObjectKeys) => {
                const addedServices = prev.services || [];
                const newAddedService = transformServiceForInvoice(selectedService);
                setIsAddServiceModalOpen(false);

                if (newAddedService) {
                    return {
                        ...prev,
                        services: [...addedServices, newAddedService],
                    };
                } else {
                    return prev;
                }
            });
        }
    };

    //replace selected service with new one selected service to invoice
    const handleUpdateService = (index: number, service_id: string): void => {
        const selectedService = services.find((service) => service.id === service_id);

        if (selectedService) {
            setFormData((prev: ObjectKeys) => {
                const addedServices = prev.services || [];
                const newAddedService = transformServiceForInvoice(selectedService);
                setIsAddServiceModalOpen(false);

                addedServices[index] = newAddedService;

                if (newAddedService) {
                    return {
                        ...prev,
                        services: addedServices,
                    };
                } else {
                    return prev;
                }
            });
        }

        setIsServiceUpdating(false);
        setUpdatingServiceIndex(null);
        setUpdatingServiceId(null);
        setIsAddServiceModalOpen(false);
    };

    //after deleting a service remove it from the invoice
    const handleDeleteService = (service_id: string): void => {
        setFormData((prev: ObjectKeys) => {
            const addedServices = prev.services || [];
            return {
                ...prev,
                services: addedServices.filter((service: ObjectKeys) => service.id !== service_id),
            };
        });
    };

    const handleServiceClick = (service_index: number, service_id: string): void => {
        setIsServiceUpdating(true);
        setUpdatingServiceIndex(service_index);
        setUpdatingServiceId(service_id);
        setIsAddServiceModalOpen(true);
        setReplaceArticle(service_id);
    };

    const handleModifiedTotalChange = (modifiedTotal: string | number): void => {
        setFormData({ ...formData, total_for_payment: modifiedTotal });
        handleTotalAdvance && handleTotalAdvance(modifiedTotal);
    };

    const handleValidation = (inputName: string): void => {
        setClickedField(inputName);
    };

    //fetch services
    useEffect(() => {
        getServices();
        setFormData(invoice);
    }, []);

    useEffect(() => {
        const currencyData = currency?.map((curr) => {
            return { value: curr.currency.toUpperCase(), label: curr.currency };
        });
        setCurrencies([...currencyData]);
    }, [currentLang]);

    //set measurement units
    useEffect(() => {
        const unitMeasurementData = getAllMeasurementUnits(t, 'foreign');
        setMeasurementUnits(unitMeasurementData as { value: string; label: string }[]);
    }, []);

    useEffect(() => {
        setMeasurementUnits(() => {
            const updatedMeasurementUnits: { value: string; label: string }[] = [];
            units.forEach((item) => {
                const label = getUnitByValue(item, true, false);
                if (label !== null) {
                    updatedMeasurementUnits.push({ value: item, label: label });
                }
            });
            return updatedMeasurementUnits;
        });
    }, [units]);

    useEffect(() => {
        invoice.prepaid_invoices &&
            !formData.prepaid_invoice_ids &&
            setClosedAdvances(
                invoice.prepaid_invoices
                    .map((m: ObjectKeys) => {
                        return {
                            id: m.id,
                            invoice_number: m.invoice_number,
                            value: m.pivot.value,
                            prefix: m.prefix,
                        };
                    })
                    .concat(selectedAdvances),
            );
    }, [invoice]);

    useEffect(() => {
        setFormData({ ...invoice, prepaid_invoice_ids: [], prepaid_invoices: [] });
        setClosedAdvances([]);
        setSelectedAdvances([]);
        setAdvances([]);
        setCoreAdvances([]);
        getAdvances(setAdvances, setCoreAdvances, invoice.client_id, invoice.currency);
        if (invoice?.client?.data?.currency === invoice.currency) {
            setFormData({ ...invoice, currency: invoice.currency });
        }
    }, [invoice.client_id, invoice.currency]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            if (newInvoice) {
                if (!invoiceYear) return;
                getInvoiceNumberAndPrefix(invoiceYear);
            }
            return;
        }
        if (!invoiceYear) return;
        getInvoiceNumberAndPrefix(invoiceYear);
        setChangedYear(true);
    }, [invoiceYear]);

    return (
        <>
            {loaderVisible && <Loader />}
            {modal && (
                <Modal modalVisible={true} closeModal={() => setModal(false)}>
                    <ErrorModal t={t} setOpenModal={() => setModal(false)} errorMessage={modalMessage} />
                </Modal>
            )}
            {badSwiftOrIban && badSwiftOrIbanModal && (
                <Modal
                    modalVisible={true}
                    preventOutsideClickClose={false}
                    closeModal={() => {
                        setBadSwiftOrIbanModal(false);
                    }}
                >
                    <ErrorModal
                        t={t}
                        setOpenModal={() => {
                            navigate('/agency/bank-accounts?=foreign');
                            setBadSwiftOrIbanModal(false);
                        }}
                        errorMessage={t('pages.invoices.bad_iban_or_swift').text}
                        buttonName={t('pages.invoices.enter').text}
                    />
                </Modal>
            )}
            <ToastContainer />
            {formData && invoice && (
                <Wrapper>
                    {closeAdvanceModal && invoice && (
                        <CloseAdvanceModal
                            advancedInvoice={advancedInvoice}
                            t={t}
                            setCloseAdvanceModal={setCloseAdvanceModal}
                            addAdvance={(v: ObjectKeys) => {
                                // Create a copy of selectedAdvances and add the new item
                                const updatedSelectedAdvances = [...selectedAdvances, v];
                                setSelectedAdvances(updatedSelectedAdvances);
                                const oldValue = v.value.replace('.', '');
                                const newValue = oldValue.replace(',', '.');

                                // Create a copy of closedAdvances and check if an item with the same id already exists
                                const updatedClosedAdvances = closedAdvances.map((item) => {
                                    if (item.id === v.id) {
                                        return {
                                            value: newValue,
                                            id: v.id,
                                            invoice_number: v.invNo,
                                            prefix: v.prefix,
                                        };
                                    }
                                    return item;
                                });
                                // If the item doesn't exist, add it to the array
                                if (!updatedClosedAdvances.some((item) => item.id === v.id)) {
                                    updatedClosedAdvances.push({
                                        value: newValue,
                                        id: v.id,
                                        invoice_number: v.invNo,
                                        prefix: v.prefix,
                                    });
                                }
                                // Create a copy of formData and update the prepaid_invoice_ids
                                const updatedFormData = {
                                    ...formData,
                                    prepaid_invoice_ids: updatedClosedAdvances,
                                };
                                // Create a copy of advances and remove the item by id
                                const updatedAdvances = advances.filter((advance) => advance.id !== v.id);
                                // Set the updated states
                                setSelectedAdvances(updatedSelectedAdvances);
                                setClosedAdvances(updatedClosedAdvances);
                                setFormData(updatedFormData);
                                setAdvances(updatedAdvances);
                            }}
                        />
                    )}
                    {isAddServiceModalOpen && (
                        <ServiceModal
                            t={t}
                            services={services}
                            refetchServices={triggerServicesRefetch}
                            setArticleType={setSelectedType}
                            addArticleToInvoice={handleAddService}
                            updateInvoiceArticle={handleUpdateService}
                            deleteInvoiceArticle={handleDeleteService}
                            isUpdating={isServiceUpdating}
                            updatingId={updatingServiceId}
                            updatingIndex={updatingServiceIndex}
                            closeModal={() => setIsAddServiceModalOpen(false)}
                            openAddArticleModal={() => {
                                setIsAddArticleModalOpen(true);
                            }}
                        />
                    )}

                    {isAddArticleModalOpen && (
                        <Sidebar close={() => setIsAddArticleModalOpen(false)}>
                            <AddArticleModal
                                t={t}
                                setEditClientModal={setIsAddArticleModalOpen}
                                success={() => {
                                    setIsAddArticleModalOpen(false);
                                }}
                                type={selectedType}
                                measurementUnits={measurementUnits}
                                triggerServicesRefetch={triggerRefetchAddLastAddedService}
                            />
                        </Sidebar>
                    )}
                    {invoice && agency && (
                        <Container>
                            <Header>
                                <Row className="inv-no">
                                    <h4>
                                        <div>
                                            {name === 'invoice'
                                                ? 'Invoice'
                                                : name === 'proforma'
                                                ? 'Proforma'
                                                : 'Advance Invoice'}
                                        </div>
                                    </h4>
                                </Row>
                                <Row className="invoice-dates-text">
                                    <div className="prefix-number" key={prefixRenderKey}>
                                        <div>
                                            <label className="trading-place-tooltip">
                                                {t('pages.invoiceCard.infoWrapper.prefix').text}:
                                                <Tooltip
                                                    text={tooltipPrefix}
                                                    isHtml={true}
                                                    position={'top'}
                                                    label={
                                                        <FontAwesomeIcon
                                                            icon={faCircleQuestion}
                                                            fontSize={'13px'}
                                                            style={{ marginBottom: '0.05rem', marginLeft: '0.5rem' }}
                                                            color={colors.purple}
                                                        />
                                                    }
                                                />
                                            </label>
                                            <Input
                                                type={InputComponentType.eInvoiceNo}
                                                value={invoice.prefix ?? ''}
                                                onChange={(value: string) =>
                                                    setFormData({ ...formData, prefix: value })
                                                }
                                                blurHandler={() => handleValidation('trading_place')}
                                                className={'prefix'}
                                                maxLength={6}
                                            />
                                        </div>
                                        <div className="input-wrapper">
                                            <label className="trading-place-tooltip">
                                                {t('pages.invoiceCard.infoWrapper.invoice_number').text}:
                                                {changedYear ? (
                                                    <Tooltip
                                                        text={t('pages.editInvoice.number_tooltip').text}
                                                        isHtml={true}
                                                        position={'top'}
                                                        label={
                                                            <FontAwesomeIcon
                                                                icon={faCircleExclamation}
                                                                fontSize={'13px'}
                                                                style={{
                                                                    marginBottom: '0.05rem',
                                                                    marginLeft: '0.5rem',
                                                                }}
                                                                color={colors.danger}
                                                            />
                                                        }
                                                    />
                                                ) : null}
                                            </label>
                                            <Input
                                                type={InputComponentType.Number}
                                                value={invoice.real_invoice_number}
                                                onChange={(value: string) => {
                                                    const currYear = formData?.invoice_number?.split('/')[1]; // Extracting the year from the invoice_number
                                                    const updatedInvoiceNumber = `${value}/${currYear}`;

                                                    setFormData({
                                                        ...formData,
                                                        real_invoice_number: value,
                                                        invoice_number: updatedInvoiceNumber,
                                                    });
                                                }}
                                                blurHandler={() => handleValidation('trading_place')}
                                                className={'prefix'}
                                                maxLength={6}
                                            />
                                            <span className="suffix">/{returnInvoiceYear(invoice.invoice_date)}</span>
                                        </div>
                                    </div>
                                    <div style={{ position: 'relative', display: 'inline-block', width: '142px' }}>
                                        <label className="trading-place-tooltip">
                                            TRADING PLACE
                                            <Tooltip
                                                text={tooltipTradingPlace}
                                                isHtml={true}
                                                position={'custom'}
                                                customPositioning={{
                                                    bottom: '80%',
                                                    left: '50%',
                                                    transform: 'translateX(-50%)',
                                                }}
                                                label={
                                                    <FontAwesomeIcon
                                                        icon={faCircleQuestion}
                                                        fontSize={'13px'}
                                                        style={{ marginBottom: '0.05rem', marginLeft: '0.5rem' }}
                                                        color={colors.purple}
                                                    />
                                                }
                                            />
                                        </label>
                                        <Input
                                            type={InputComponentType.Text}
                                            value={invoice.trading_place ? invoice.trading_place : ''}
                                            onChange={(value: string) =>
                                                setFormData({ ...formData, trading_place: value })
                                            }
                                            blurHandler={() => handleValidation('trading_place')}
                                        />
                                    </div>
                                    <Input
                                        type={InputComponentType.Date}
                                        label={'DATED'}
                                        date={
                                            formData?.invoice_date
                                                ? moment(formData?.invoice_date).toDate()
                                                : formData?.quotation_date
                                                ? moment(formData?.quotation_date).toDate()
                                                : new Date()
                                        }
                                        onChange={(value: string) => {
                                            setFormData({
                                                ...formData,
                                                invoice_date: moment(value).format('YYYY-MM-DD').toString(),
                                                quotation_date: moment(value).format('YYYY-MM-DD').toString(),
                                            });
                                        }}
                                        maxDate={new Date()}
                                        className="date-input-class"
                                    />
                                    <Input
                                        type={InputComponentType.Date}
                                        label={'TRADING DATE'}
                                        date={
                                            formData?.trading_date
                                                ? moment(formData?.trading_date).toDate()
                                                : new Date()
                                        }
                                        onChange={(value: string) => {
                                            setFormData({
                                                ...formData,
                                                trading_date: moment(value).format('YYYY-MM-DD').toString(),
                                            });
                                        }}
                                    />
                                </Row>
                            </Header>

                            {agency && invoice && (
                                <Agency>
                                    <div>
                                        <h3>From:</h3>
                                        <h3>{agency.name}</h3>
                                        <p>{agency.full_name}</p>
                                        <p>{agency.street_address + '\xa0' + agency.street_number}</p>
                                        <p>{agency.city}</p>
                                        <p>{'VAT number:' + '\xa0' + agency.pib}</p>
                                        <p>{'ID No/MB:' + '\xa0' + agency.identification_number}</p>
                                        <p>
                                            {t('pages.editInvoice.previewModal.email').text}{' '}
                                            <a>{agency?.agency_email}</a>
                                        </p>

                                        <>
                                            <Row className="foreign-accounts">
                                                <SelectComponent
                                                    optionList={swiftIbanOptions}
                                                    defaultSelectedOption={
                                                        invoice && invoice.bank_id
                                                            ? {
                                                                  value: checkBankFromId(invoice.bank_id),
                                                                  label: `${checkBankFromId(invoice.bank_id)} ${
                                                                      invoice.currency
                                                                  }`,
                                                              }
                                                            : swiftIbanOptions[0]
                                                    }
                                                    handleSelectedValue={(value: any) => {
                                                        if (value === 'add_account') {
                                                            navigate('/agency/bank-accounts?=foreign');
                                                        } else if (value === 'empty_account') {
                                                            setFormData({
                                                                ...formData,
                                                                iban: '',
                                                                swift: '',
                                                                bank_account_id: '',
                                                            });
                                                        } else {
                                                            if (
                                                                !validateIbanSwift(
                                                                    value.iban,
                                                                    value.swift,
                                                                    value.id,
                                                                    value.bank.data.code === '000',
                                                                    agency,
                                                                    swiftIbanOptions,
                                                                    data,
                                                                )
                                                            ) {
                                                                setBadSwiftOrIban(true);
                                                                setBadSwiftOrIbanModal(true);
                                                                handleChangeHappend && handleChangeHappend(false);
                                                                // return;
                                                            }
                                                            setFormData({
                                                                ...formData,
                                                                iban: value.iban,
                                                                swift: value.swift,
                                                                bank_account_id: value.id,
                                                                bank_id: value.bank.data.id,
                                                            });
                                                        }
                                                    }}
                                                />
                                            </Row>
                                            <p>
                                                {'IBAN: '}
                                                {invoice?.iban ? invoice?.iban : '/'}
                                            </p>
                                            <p>
                                                {'SWIFT: '}
                                                {invoice?.swift ? invoice?.swift : '/'}
                                            </p>
                                        </>
                                    </div>
                                    <div>
                                        <h3>Bill to:</h3>
                                        <p>
                                            {invoice?.client?.data?.company_name
                                                ? invoice?.client?.data?.company_name
                                                : ''}
                                        </p>
                                        <p>
                                            {'Address: ' +
                                                (invoice.client?.data?.address ? invoice.client?.data?.address : '')}
                                        </p>
                                        <p>
                                            {'City: ' + (invoice.client?.data?.city ? invoice.client?.data?.city : '')}
                                        </p>
                                        <p>
                                            {'Country: ' +
                                                (invoice?.client?.data?.country ? invoice?.client?.data?.country : '')}
                                        </p>
                                        <p>
                                            {'VAT/EIB:' +
                                                '\xa0' +
                                                (invoice.client?.data?.pib
                                                    ? invoice.client?.data?.pib
                                                    : invoice.client?.data?.jmbg
                                                    ? invoice.client?.data?.jmbg
                                                    : '')}
                                        </p>
                                        <p>
                                            {'ID no/MB: ' +
                                                (invoice?.client?.data?.identification_number
                                                    ? invoice?.client?.data?.identification_number
                                                    : '')}
                                        </p>
                                        <>
                                            {advances.length > 0 && !isAdvance && (
                                                <Row style={{ alignItems: 'center' }}>
                                                    {t('pages.advanceInvoices.closeAdvance').text}
                                                    <SelectAdvanceComponent
                                                        optionList={advances.map((advance) => ({
                                                            value: advance,
                                                            label: (
                                                                <div className="label-container">
                                                                    <span className="left-value">
                                                                        {`${
                                                                            advance.prefix ? advance.prefix + ' ' : ''
                                                                        }${advance.invoice_number}`}
                                                                    </span>
                                                                    <span className="right-value">
                                                                        {formatTwoDecimals(
                                                                            advance.remaining_value.toString(),
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            ),
                                                        }))}
                                                        value={null}
                                                        placeholder={t('pages.advanceInvoices.chooseAdvance').text}
                                                        handleSelectedValue={(value: ObjectKeys) => {
                                                            setCloseAdvanceModal(true);
                                                            setAdvancedInvoice(value);
                                                            advances.filter((a) => a.value !== value);
                                                        }}
                                                        width="250px"
                                                    />
                                                </Row>
                                            )}
                                        </>
                                    </div>
                                </Agency>
                            )}
                            {!isMobile ? (
                                <Row className="services">
                                    <p className="type">Item</p>
                                    <p className="unit">Unit</p>
                                    <p className="price">Quantity</p>
                                    <p className="price">Unit price</p>
                                    <p className="price">Discount %</p>
                                    <p className="total">Total</p>
                                </Row>
                            ) : (
                                <h3 className="services-title">
                                    {t('pages.editInvoice.addServiceModal.services').text}
                                </h3>
                            )}
                            {invoice?.services && invoice?.services?.length > 0 ? (
                                invoice?.services.map((service: ObjectKeys, index: number) =>
                                    isMobile ? (
                                        <MobileService
                                            key={`${service.id} ${index}`}
                                            t={t}
                                            index={index}
                                            handleTotal={handleTotal}
                                            invoice={invoice}
                                            services={services}
                                            setFormData={setFormData}
                                            units={measurementUnits}
                                            service={service}
                                            handleServiceClick={() => handleServiceClick(index, service.id)}
                                        />
                                    ) : (
                                        <WebService
                                            key={`${service.id} ${index}`}
                                            index={index}
                                            handleTotal={handleTotal}
                                            invoice={invoice}
                                            services={services}
                                            setFormData={setFormData}
                                            units={measurementUnits}
                                            t={t}
                                            service={service}
                                            handleServiceClick={() => handleServiceClick(index, service.id)}
                                        />
                                    ),
                                )
                            ) : (
                                <>
                                    <AddServiceButton onClick={() => setIsAddServiceModalOpen(true)}>
                                        <div className="plus">
                                            <FontAwesomeIcon icon={faPlus} />
                                        </div>
                                        <p>Add item</p>
                                    </AddServiceButton>
                                </>
                            )}
                            <AddServiceCurrency>
                                {invoice?.services && invoice?.services?.length > 0 ? (
                                    <AddService onClick={() => setIsAddServiceModalOpen(true)}>
                                        <FontAwesomeIcon icon={faPlus} />
                                        <p>Add item</p>
                                    </AddService>
                                ) : null}
                                {currencies && (
                                    <SelectComponent
                                        className="currency-select"
                                        label="Currency"
                                        handleSelectedValue={(value: string) => {
                                            setFormData({ ...invoice, currency: value });
                                        }}
                                        optionList={currencies}
                                        defaultSelectedOption={{ value: invoice.currency, label: invoice.currency }}
                                        value={
                                            formData.currency
                                                ? formData.currency
                                                : invoice?.client?.data?.currency &&
                                                  currencies.find((v) => v.value === invoice.client.data.currency)
                                        }
                                    />
                                )}
                            </AddServiceCurrency>
                            <Total>
                                {invoice && totalWithoutRabat ? (
                                    <>
                                        <p>AMOUNT DUE ({invoice.currency})</p>
                                        <h3>{formatTwoDecimals(totalWithoutRabat.toString())}</h3>
                                    </>
                                ) : null}
                                {invoice && totalWithoutRabat && rabat !== null ? (
                                    <>
                                        <p>Discount ({invoice.currency})</p>
                                        <h3>{formatTwoDecimals(rabat.toString())}</h3>
                                    </>
                                ) : null}
                                {selectedAdvances.length === 0 || invoice?.prepaid_invoices?.length === 0 ? (
                                    <>
                                        <p>AMOUNT DUE ({invoice.currency})</p>
                                        <h3>{formatNumber(countTotal(invoice.services), 2, 3, '.', ',')}</h3>
                                    </>
                                ) : null}
                            </Total>
                            {(selectedAdvances.length > 0 || invoice?.prepaid_invoices?.length > 0) && (
                                <Total>
                                    <PayedAdvance
                                        onModifiedTotalChange={handleModifiedTotalChange}
                                        total={countTotal(invoice.services)}
                                        closedAdvances={closedAdvances}
                                        t={t}
                                        currency={invoice.currency}
                                        onDelete={(advance: ObjectKeys) => {
                                            // Find the index of the advance in coreAdvances that matches the id
                                            const indexToAdd = coreAdvances.findIndex((adv) => adv.id === advance.id);
                                            // If a matching advance is found, add it to the advances array
                                            if (indexToAdd !== -1) {
                                                // Create a new array with the item added to advances
                                                const updatedAdvances = [...advances, coreAdvances[indexToAdd]];
                                                setAdvances(updatedAdvances);
                                                const updatedSelectedAdvances = selectedAdvances.filter(
                                                    (adv) => adv.id !== advance.id,
                                                );
                                                setSelectedAdvances(updatedSelectedAdvances);
                                            }
                                            const advancesForDelete = closedAdvances;
                                            setClosedAdvances(
                                                advancesForDelete.filter(
                                                    (a: ObjectKeys) =>
                                                        a.invoice_number !== advance.invoice_number ||
                                                        a.value !== advance.value,
                                                ),
                                            );

                                            //setFormData
                                            setFormData({
                                                ...formData,
                                                prepaid_invoice_ids: advancesForDelete.filter(
                                                    (a: ObjectKeys) =>
                                                        a.invoice_number !== advance.invoice_number ||
                                                        a.value !== advance.value,
                                                ),
                                            });
                                        }}
                                    />
                                </Total>
                            )}
                            <Row>
                                <Comments>
                                    <p>Note:</p>
                                    <TextArea
                                        value={formData.comment ? formData.comment : ''}
                                        onChange={(value: string) => setFormData({ ...formData, comment: value })}
                                        maxLength={1000}
                                    />
                                </Comments>
                                <Comments>
                                    <p style={{ wordBreak: 'break-all' }}>
                                        Identification number:
                                        {agency.signature}
                                    </p>
                                    <p>Not in the VAT system</p>
                                    <p>
                                        VAT not calculated on the invoice according to article 33 of Law on value added
                                        tax.
                                    </p>
                                    <p>Document is valid without stamp and signature</p>
                                    <p>Place: {agency.city}</p>
                                </Comments>
                            </Row>
                            <Limit>
                                <div className="tooltipNote">
                                    <p>{t('pages.editInvoice.previewModal.eightMillionLimit').text}</p>
                                    <Checkbox
                                        defaultChecked={formData.limit8_million_exclude}
                                        onChange={(e: MouseEvent) => {
                                            setFormData({
                                                ...formData,
                                                limit8_million_exclude: e,
                                            });
                                        }}
                                        className={'limit-checkbox'}
                                    />
                                    <Tooltip
                                        text={tooltipLimit8milion}
                                        isHtml={true}
                                        position={`${isTabletAndDown ? 'left' : 'top'}`}
                                        label={
                                            <FontAwesomeIcon
                                                icon={faCircleQuestion}
                                                fontSize={'13px'}
                                                style={{ marginLeft: '0.5rem' }}
                                                color={colors.purple}
                                            />
                                        }
                                    />
                                </div>
                            </Limit>

                            <GroupedMemoSection>
                                <MemoDisplaySection>
                                    <div>
                                        {formData?.memorandums?.length > 0 && (
                                            <div className="selected-memos">
                                                {formData.memorandums.map((memo: string) => {
                                                    const selectedMemo = memos.find((m: ObjectKeys) => m.id === memo);
                                                    return (
                                                        <div key={memo} className="selected-memo">
                                                            <span>{selectedMemo?.heading}</span>
                                                            <FontAwesomeIcon
                                                                icon={faTrashCan}
                                                                style={{ color: colors.danger }}
                                                                onClick={() => {
                                                                    setFormData((prevFormData: ObjectKeys) => {
                                                                        return {
                                                                            ...prevFormData,
                                                                            memorandums:
                                                                                prevFormData.memorandums?.filter(
                                                                                    (m: string) => m !== memo,
                                                                                ),
                                                                        };
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </MemoDisplaySection>
                                <MemoSelect>
                                    <div className="select-memos">
                                        <h3>
                                            {memoForSelect.length > 0
                                                ? t('pages.editInvoice.previewModal.addedMemorandums').text
                                                : t('pages.editInvoice.previewModal.createFirstMemoNote').text}
                                        </h3>
                                        {memoForSelect.length > 0 ? (
                                            <>
                                                <SelectSearch
                                                    optionList={memoForSelect}
                                                    placeholder={t('pages.memos.chooseMemo').text}
                                                    handleSelectedValue={(option: { label: string; value: string }) => {
                                                        setFormData((prevFormData: ObjectKeys) => {
                                                            const existingMemorandums = prevFormData.memorandums || []; // Ensure it's an array
                                                            const updatedMemorandums = existingMemorandums?.includes(
                                                                option.value,
                                                            )
                                                                ? existingMemorandums // If already exists, keep it as is
                                                                : [...existingMemorandums, option.value]; // Add new value

                                                            return {
                                                                ...prevFormData,
                                                                memorandums: updatedMemorandums,
                                                            };
                                                        });
                                                    }}
                                                    maxHeight={170}
                                                    disabled={formData?.memorandums?.length >= 3}
                                                />
                                                {formData?.memorandums?.length >= 3 ? (
                                                    <p className="validation-message">
                                                        {t('pages.editInvoice.previewModal.createMemoLimitation').text}
                                                    </p>
                                                ) : null}
                                            </>
                                        ) : (
                                            <p>{t('pages.editInvoice.previewModal.noCreatedMemos').text}</p>
                                        )}
                                    </div>
                                </MemoSelect>
                            </GroupedMemoSection>

                            <Footer>
                                <p>
                                    {t('pages.editInvoice.previewModal.footerInvoice').text}
                                    <a href="http://www.pausal.rs" target="_blank" rel="noreferrer">
                                        pausal.rs
                                    </a>
                                </p>
                                <img src={logo} height={'15px'} />
                            </Footer>
                        </Container>
                    )}
                </Wrapper>
            )}
        </>
    );
};

export default InvoiceForeignEdit;
